<template>
  <div>
    <digital-output v-if="subsystemTreeModalData.source === SOURCES.SUBSYSTEM_TREE_DO" />
    <virtual-alarm v-if="subsystemTreeModalData.source === SOURCES.SUBSYSTEM_TREE_VA" />
  </div>
</template>

<script>
import store from '@/store';
import { SOURCES } from "@/config/layoutConfig";
import DigitalOutput from "./DigitalOutput.vue";
import VirtualAlarm from "./VirtualAlarm.vue";

export default {
  components: { DigitalOutput, VirtualAlarm },
  data() {
    return {
      SOURCES,
    };
  },
  computed: {
    subsystemTreeModalData() {
      return store.state.subsystem_tree.subsystemTreeModal;
    },
  },
};
</script>

<style></style>
