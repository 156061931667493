<template>
  <draggable
    class="side-bar"
    v-model="items"
    handle=".handle"
    group="dashboard"
    @start="onStart"
    @end="onEnd"
  >
    <template v-for="item in items">
      <template
        v-if="
          item.componentName &&
          item.componentName === 'alarms-widget' &&
          shouldShowWidget(item)
        "
      >
        <component
          :is="item.componentName"
          :title="resolveWidgetTitle(item.title)"
          :filters="item.filters"
          :id="item.id"
          :key="item.id"
          :collapsible="item.collapsible"
          :isOpen="item.isOpen"
          location="sideBar"
          @widget-collapsed="onWidgetCollapse"
        />
      </template>

      <template
        v-else-if="
          item.componentName &&
          item.componentName === 'tenants-widget' &&
          shouldShowWidget(item)
        "
      >
        <component
          :is="item.componentName"
          :title="resolveWidgetTitle(item.componentName)"
          :id="item.id"
          :key="`tenants-widget-${item.id}`"
          :collapsible="item.collapsible"
          :isOpen="item.isOpen"
          location="sideBar"
          @widget-collapsed="onWidgetCollapse"
        />
      </template>

      <template v-else-if="item.componentName && shouldShowWidget(item)">
        <component
          :is="item.componentName"
          :title="resolveWidgetTitle(item.componentName)"
          :id="item.id"
          :key="item.id"
          :collapsible="item.collapsible"
          :isOpen="item.isOpen"
          location="bottomBar"
          @widget-collapsed="onWidgetCollapse"
        />
      </template>
    </template>
  </draggable>
</template>

<script>
import store from "@/store";
import draggable from "vuedraggable";
import AlarmsWidget from "./widgets/AlarmsWidget";
import TenantsWidget from "./widgets/TenantsWidget";
import LayoutsWidget from "./widgets/LayoutsWidget";
import WidgetsWidget from "./widgets/WidgetsWidget";
import ViewsWidget from "./widgets/ViewsWidget";
import ScriptsWidget from "./widgets/ScriptsWidget";
import VideoWallWidget from "./widgets/VideoWallWidget.vue";
import PagesWidget from "./widgets/PagesWidget";
import UnitGroupsWidget from "./widgets/UnitGroupsWidget";
import UserChartsWidget from "./widgets/UserChartsWidget";
import ChatWidget from "./widgets/ChatWidget";

export default {
  components: {
    draggable,
    AlarmsWidget,
    TenantsWidget,
    LayoutsWidget,
    WidgetsWidget,
    ViewsWidget,
    ScriptsWidget,
    VideoWallWidget,
    PagesWidget,
    UnitGroupsWidget,
    UserChartsWidget,
    ChatWidget,
  },
  data() {
    return {};
  },
  computed: {
    items: {
      get() {
        return store.state.grid.sideBarItems;
      },
      set(value) {
        store.dispatch("grid/setSideBarItems", value);
      },
    },
    user() {
      return store.getters["user/getUser"];
    }
  },
  methods: {
    onStart() {
      store.commit("grid/setSidebarStartDragging", true);
    },
    onEnd() {
      store.commit("grid/setSidebarStartDragging", false);
      store.dispatch("updateUserConfig");
    },
    onWidgetCollapse(data) {
      store.dispatch("grid/updateWidgetStatus", data);
    },
    shouldShowWidget(item) {
      switch (item.componentName) {
        case "alarms-widget":
          if (!this.user.is_master_admin && this.$can('show_right_sidebar', 'Alarm'))
            return false;
          if (item.title == 'Alarmas en espera')
            return this.$can('waiting_alarms', 'Workspace');
          else
            return this.$can('attending_alarms', 'Workspace');

        case "tenants-widget":
          return this.$can('facilities', 'Workspace');

        case "layouts-widget":
          return this.$can('layouts', 'Workspace');

        case "views-widget":
          return this.$can('views', 'Workspace');

        case "widgets-widget":
          return this.$can('widgets', 'Workspace');

        case "video-wall-widget":
          return this.$can('read', 'VideoWall');

        case "scripts-widget":
          return this.$can('commands', 'Workspace');

        case "pages-widget":
          return this.$can('web_pages', 'Workspace');

        case "unit-groups-widget":
          return this.$can('facilities_group', 'Workspace');

        case "user-charts-widget":
          return this.$can('charts', 'Workspace');

        case "chat-widget":
          return this.$can('chat', 'Workspace');

        default:
          return false;
      }
    },
    resolveWidgetTitle(title) {
      switch (title) {
        case "Alarmas en espera":
          return this.$t("grid.widgets.alarms.incoming_alarms.title");

        case "Alarmas en atención":
          return this.$t("grid.widgets.alarms.alarms_being_attended.title");

        case "tenants-widget":
          return this.$t("grid.widgets.units.title");

        case "layouts-widget":
          return this.$t("grid.widgets.layouts.title");

        case "views-widget":
          return this.$t("grid.widgets.views.title");

        case "widgets-widget":
          return this.$t("grid.widgets.widgets.title");

        case "video-wall-widget":
          return this.$t("grid.widgets.videowall.title");

        case "scripts-widget":
          return this.$t("grid.widgets.scripts.title");

        case "pages-widget":
          return this.$tc("pages.title", 2);

        case "unit-groups-widget":
          return this.$t("unit_groups.title");

        case "user-charts-widget":
          return this.$t("grid.widgets.charts.title");

        case "chat-widget":
          return "Chat";

        default:
          return title;
      }
    }
  },
};
</script>
