<template>
  <div class="grid-tabs">
    <!-- === Video Wall Tab -->
    <b-modal v-model="modalShow">
      <template #modal-header>
        <h5>{{ $t("video_wall.video_wall_users") }}</h5>
      </template>
      <b-table
        class="mb-0 alarms-widget__data-table"
        :items="userVideoWall"
        :fields="fields"
        primary-key="id"
        :busy="busy"
        show-empty
        small
        :empty-text="$t('grid.widgets.alarms.table.no_data')"
        responsive
      >
        <template #cell(viewname)="row">
          <b-overlay :show="disableButton" rounded="sm">
            <b-container class="bv-example-row">
              <b-button
                variant="outline-primary"
                @click="saveTabVideoWall(row)"
                class="btn-sm"
              >
                <feather-icon
                  icon="AirplayIcon"
                  size="16"
                  class="align-msiddle text-body"
                />
              </b-button>
            </b-container>
          </b-overlay>
        </template>
      </b-table>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="modalShow = false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <!-- Video Wall Tab === -->

    <b-tabs ref="tabs" content-class="mt-0" nav-class="mb-0">
      <b-tab
        v-for="(tab, tabId) in tabs"
        :button-id="`tab_${tabId}`"
        :key="tabId"
        @click="setActiveTab(tabId)"
        :active="tabId === activeTabId"
        title-link-class="d-flex"
        :style="tab && tab.color ? `background: ${tab.color}` : ``"
        :title-link-attributes="{
          style: tab && tab.color ? `background: ${tab.color}; color: white;` : ``,
        }"
      >
        <template #title>
          <span
            v-if="!(editTab && tabId === activeTabId)"
            @contextmenu.prevent="(event) => openContextMenu(event, tabId)"
          >
            {{ resolveTabName(tab) }}
          </span>
          <div v-else class="input-tab-name-placeholder"></div>
          <span class="d-block cursor-pointer btn-remove-tab" @click="removeTab(tabId)">
            <feather-icon
              class="my-auto"
              icon="XIcon"
              size="14"
              v-show="!isUserVideoWall"
            />
          </span>
        </template>
        <keep-alive>
          <main-layout
            :tabId="tabId"
            :key="`tab-${tabId}`"
            v-show="tabId === activeTabId"
          />
        </keep-alive>
      </b-tab>

      <template #tabs-end>
        <button
          class="btn btn-sm btn-icon btn-add-tab"
          v-if="tabsQty < $store.state.app.maxTabs"
          @click="addNewTab()"
          v-show="!isUserVideoWall"
        >
          <template v-if="tabsQty === 0">
            <span class="fs-2 mr-25">{{ $t("grid.tabs.new_tab") }}</span>
            <feather-icon icon="PlusIcon" size="14" />
          </template>
          <feather-icon icon="PlusIcon" size="14" v-else />
        </button>
        <button class="btn btn-sm btn-icon btn-fullscreen" @click="goFullScreen">
          <feather-icon icon="MinimizeIcon" size="24" v-if="fullScreenEnabled" />
          <feather-icon icon="MaximizeIcon" size="24" v-else />
        </button>
      </template>
    </b-tabs>

    <!-- Tab Input Name -->
    <input
      type="text"
      name="tab_name"
      id="input_tab_name"
      :placeholder="`e.g. ${$tc('alarm.title', 1)} 1`"
      v-show="editTab"
      @keyup.enter="editTabName($event, activeTabId)"
    />

    <vue-context ref="menu" class="option-menu">
      <li>
        <a href="#" @click.prevent="saveTab" class="flex items-center text-sm">
          <span v-if="activeTab && activeTab.type === 'view'">
            {{ $t("grid.tabs.context_menu.update_view") }}
          </span>
          <span v-else> {{ $t("grid.tabs.context_menu.save_as_view") }} </span>
        </a>
      </li>
      <li>
        <a href="#" @click.prevent="showEditName" class="flex items-center text-sm">
          <span>{{ $t("grid.tabs.context_menu.update_name") }}</span>
        </a>
      </li>
      <li v-if="$can('update', 'VideoWall')">
        <a href="#" @click="modalShow = !modalShow" class="flex items-center text-sm">
          <span>{{ $t("video_wall.assign_a_video_wall") }}</span>
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import store from "@/store";
import MainLayout from "./layouts/MainLayout.vue";
import { BTabs, BTab, BButton } from "bootstrap-vue";
import VueContext from "vue-context";
import axios from "@axios";

export default {
  components: {
    BTabs,
    BTab,
    MainLayout,
    VueContext,
    BButton,
  },

  data() {
    return {
      editTab: false,
      modalShow: false,
      busy: false,
      fields: [
        {
          key: "name",
          label: this.$t("commands.gridWidget.table.rows.1"),
          minSize: 300,
        },

        {
          key: "viewname",
          label: "",
          minSize: 300,
        },
      ],
      disableButton: false,
    };
  },
  mounted() {
    document.addEventListener("fullscreenchange", () =>
      store.commit("grid/setFullScreen", !!document.fullscreenElement)
    );
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", () =>
      store.commit("grid/setFullScreen", !!document.fullscreenElement)
    );
  },
  computed: {
    userVideoWall() {
      return store.getters["video_wall/getItems"];
    },
    user() {
      return store.getters["user/getUser"];
    },
    isUserVideoWall() {
      return this.user.video_wall === 1;
    },
    tabs() {
      return store.state.grid.tabs;
    },
    activeTabId() {
      return store.state.grid.activeTab;
    },
    activeTab() {
      return this.tabs[this.activeTabId];
    },
    tabsQty() {
      return this.tabs ? Object.keys(this.tabs).length : 0;
    },
    fullScreenEnabled() {
      return store.state.grid.fullScreenEnabled;
    },
    sidebarWidth() {
      return store.state.grid.sidebarWidth;
    },
    isToolbarsCollapsed() {
      return store.state.psimConfig.isToolbarsCollapsed;
    },
  },
  methods: {
    updateView(row) {
      console.log(row.item.id);
    },
    view(data) {
      console.log(data.tabs);
    },
    resolveTabName(tab) {
      if (!tab) return;

      if (tab.name) {
        return tab.name;
      }

      return `Tab ${tab.order}`;
    },
    addNewTab() {
      store.dispatch("grid/addNewTab");
      this.selectLastTab();
    },
    removeTab(tabId) {
      store.dispatch("grid/removeTab", tabId);
      this.editTab = false;
      this.selectLastTab();
    },
    setActiveTab(tabId) {
      this.editTab = false;
      store.dispatch("grid/setActiveTab", tabId);
      this.$nextTick(() => {
        if (this.activeTab) {
          this.inputPosition(tabId, this.activeTab.name);
        }
      });
    },
    selectLastTab() {
      if (this.tabs) {
        this.setActiveTab(Object.keys(this.tabs)[Object.keys(this.tabs).length - 1]);
      }
    },
    goFullScreen() {
      if (!this.fullScreenEnabled) {
        this.$refs.tabs.$el.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    openContextMenu(event, tabId) {
      this.setActiveTab(tabId);
      this.$refs.menu.open(event);
    },
    saveTab() {
      const activeTab = this.activeTab;
      this.setActiveTab(this.activeTabId);
      if (activeTab.slots.length > 0) {
        activeTab.id = this.activeTabId;
        activeTab.type = "view";
        const data = {
          tabId: activeTab.id,
          name: activeTab.name ? activeTab.name : "Tab 1",
          data: activeTab,
        };
        store.dispatch("grid/createOrUpdateView", { ...data });

        store.dispatch("grid/updateTabType", {
          id: activeTab.id,
          type: "view",
        });
      }
    },
    saveTabVideoWall(row) {
      const activeTab = JSON.parse(JSON.stringify(this.activeTab));
      activeTab.id = this.activeTabId;

      const data = {
        id_user: row.item.id,
        tab: {
          tabId: this.activeTabId,
          name: activeTab.name ? activeTab.name : "Tab 1",
          data: activeTab,
        },
      };
      axios.post("v1/video-wall/push-tab-to-user", { ...data });
      setTimeout(() => {
        this.modalShow = false;
      }, 200);
    },
    showEditName() {
      this.editTab = !this.editTab;
    },
    editTabName(event, tabId) {
      const tabName = event.target.value;
      const data = {
        id: tabId,
        name: tabName,
      };
      store.dispatch("grid/editTabName", { data });
      this.saveTab();
      this.editTab = false;
    },
    inputPosition(tabId, tabNameValue) {
      const tabName = document.querySelector(`#tab_${tabId}`);
      const input = document.getElementById("input_tab_name");

      if (tabName) {
        const tabNameRect = tabName.getBoundingClientRect();
        const offsetTop = 2;
        const top = 0 + offsetTop;
        const offsetLeft = 10;
        const left = (this.isToolbarsCollapsed ? 0 : this.sidebarWidth) - offsetLeft;

        const relativePos = {};

        relativePos.top = top;
        relativePos.right = tabNameRect.right - left;
        relativePos.left = tabNameRect.left - left;

        input.style.top = `${relativePos.top}px`;
        input.style.left = `${relativePos.left}px`;
        input.value = tabNameValue;
        setTimeout(() => input.focus(), 1000);
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
@import "~vue-context/src/sass/vue-context";
</style>
