<template>
  <b-modal
    id="subsystem_action_modal"
    title="Alarma"
    @show="onShow"
    @hidden="resetDataModal"
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
  >
    <p class="w-100 lead border-bottom pb-50">
      <span>{{ $t("alarm.subsystem_action_modal.send_alarm") }}</span>
    </p>
    <div class="row">
      <div class="col-md-12 mb-2">
        <span v-if="subsystemTreeModalData.data.notify_c5_cdmx">
          {{ $t("alarm.subsystem_action_modal.notify_c5_cdmx") }}
        </span>
        <span v-else-if="subsystemTreeModalData.data.notify_c5_jal">
          {{ $t("alarm.subsystem_action_modal.notify_c5_jal") }}
        </span>
        <span v-else-if="subsystemTreeModalData.data.notify_c5_edomex">
          {{ $t("alarm.subsystem_action_modal.notify_c5_edomex") }}
        </span>
        <span v-else> {{ $t("alarm.subsystem_action_modal.create_vitual_alarm") }} </span>
      </div>

      <div class="col-md-12">
        <validation-observer ref="refFormObserver">
          <validation-provider name="comments" rules="required" v-slot="{ errors }">
            <b-form-group :label="$t('alarm.subsystem_action_modal.comments')" label-for="comments">
              <b-form-textarea
                id="comments"
                name="comments"e
                placeholder=""
                rows="3"
                max-rows="6"
                v-model="comments"
              >
              </b-form-textarea>
              <span class="d-block text-secondary mt-25" v-if="errors.length > 0">
                {{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <b-form-group
            :label="$t('alarm.subsystem_action_modal.level_alarm')"
            label-for="alarm_level"
            v-if="actionType.attending && alarmLevelOptions.length"
          >
            <b-form-select
              v-model="alarmLevelSelected"
              :options="alarmLevelOptions"
            ></b-form-select>
          </b-form-group>
        </validation-observer>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="onCancel()" :disabled="loading" class="text-capitalize">
        {{ $t("actions.cancel") }}
      </b-button>
      <b-button variant="primary" @click="submit()" :disabled="loading" class="text-capitalize">
        {{ $t("actions.send") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BSpinner,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import axios from "@axios";

export default {
  components: {
    BSpinner,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      comments: "",
      loading: false,
      actionType: {},
      alarmLevelSelected: null,
      alarmLevelOptions: [],
    };
  },
  computed: {
    subsystemTreeModalData() {
      return store.state.subsystem_tree.subsystemTreeModal;
    },
  },
  methods: {
    onShow() {
      this.actionType = {
        attending: 1,
        attended: 0,
        canceled: 0,
      };

      const url = `v1/services/alarms/alarm-levels?id_tenant=${this.subsystemTreeModalData.data.id_tenant}`;

      axios.get(url).then((resp) => {
        if (resp.data.data.length) {
          this.alarmLevelOptions.push({
            value: null,
            text: "Normal",
          });
          resp.data.data.forEach((item) =>
            this.alarmLevelOptions.push({ value: item.level, text: item.description })
          );
          this.alarmLevelSelected = null;
        }
      });
    },
    async submit() {
      const validation = await this.$refs.refFormObserver.validate();

      if (validation) {
        const data = {
          attending: 1,
          attended: 0,
          canceled: 0,
          ...this.subsystemTreeModalData.data,
        };

        data["attending_comments"] = this.comments;
        if (this.alarmLevelSelected) {
          data["id_alarm_level"] = this.alarmLevelSelected;
        }

        this.loading = true;

        axios
          .post(`v1/alarm`, data)
          .then(() => {
            this.showMessage("Alarma creada correctamente");
            this.closeModal();
          })
          .catch((error) => {
            this.showMessage(`Ocurrió un error al crear la alarma`, "danger");
            console.log("submit", error);
          })
          .finally(() => (this.loading = false));
      }
    },
    showMessage(msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Alarma virtual",
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    resetDataModal() {
      store.dispatch("subsystem_tree/resetSubsystemTreeModal");
      this.comments = "";
      this.alarmLevelOptions = [];
      this.alarmLevelSelected = null;
    },
    closeModal() {
      this.resetDataModal();
      this.$bvModal.hide("subsystem_action_modal");
    },
    onCancel() {
      this.closeModal();
    },
  },
};
</script>

<style></style>
