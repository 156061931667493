<template>
  <div class="layouts widget bg-white border-bottom ">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">Widgets</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="24"
            />
          </template>
          <span class="handle cursor-move" v-if="isConfigModeOn">
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        :visible="false"
        v-model="isCollapseOpen"
        @input="onUpdateStatus"
      >
        <div class="widgets-container d-flex flex-wrap justify-content-around p-2">
          <div
            class="p-2 border cursor-pointer mb-2"
            @click="setSelectedWidget('timeline')"
            draggable
            @dragstart="onDragStart('timeline')"
            @dragend="onDragEnd"
            v-if="$can('timeline', 'Widget')"
          >
            <feather-icon icon="ClockIcon" size="16" class="mr-25" />
            Timeline
          </div>
          <div
            class="p-2 border cursor-pointer mb-2"
            @click="setSelectedWidget('map')"
            draggable
            @dragstart="onDragStart('map')"
            @dragend="onDragEnd"
            v-if="$can('map', 'Widget')"
          >
            <feather-icon icon="MapIcon" size="16" class="mr-25" />
            {{ $t("grid.widgets_section.map.title") }}
          </div>
          <div
            class="p-2 border cursor-pointer mb-2 text-capitalize"
            @click="setSelectedWidget('page')"
            draggable
            @dragstart="onDragStart('page')"
            @dragend="onDragEnd"
            v-if="$can('url', 'Widget')"
          >
            <feather-icon icon="CodeIcon" size="16" class="mr-25" />
            {{ $t("grid.frame.url") }}
          </div>
          <div
            class="p-2 border cursor-pointer mb-2 text-capitalize"
            @click="setSelectedWidget('charts')"
            draggable
            @dragstart="onDragStart('charts')"
            @dragend="onDragEnd"
            v-if="$can('chart', 'Widget')"
          >
            <feather-icon icon="BarChart2Icon" size="16" class="mr-25" />
            {{ $t("grid.widgets_section.chart.title") }}
          </div>
          <div
            class="p-2 border cursor-pointer mb-2 text-capitalize"
            @click="setSelectedWidget('weather')"
            draggable
            @dragstart="onDragStart('weather')"
            @dragend="onDragEnd"
            v-if="$can('weather', 'Widget')"
          >
            <feather-icon icon="SunIcon" size="16" class="mr-25" />
            {{ $t("grid.widgets_section.weather.title") }}
          </div>

          <div
            class="p-2 border cursor-pointer mb-2 text-capitalize"
            @click="setSelectedWidget('face_detection')"
            draggable
            @dragstart="onDragStart('face_detection')"
            @dragend="onDragEnd"
            v-if="$can('face_detection', 'Widget')"
          >
            <feather-icon icon="SmileIcon" size="16" class="mr-25" />
              {{ $t("grid.widgets_section.face_detection.title") }}
          </div>

          <div
            class="p-2 border cursor-pointer mb-2 text-capitalize"
            @click="setSelectedWidget('waze')"
            draggable
            @dragstart="onDragStart('waze')"
            @dragend="onDragEnd"
            v-if="$can('waze', 'Widget')"
          >
          <svg version="1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 122.71 122.88"
            style="enable-background:new 0 0 122.71 122.88; width: 20px;"
            class="mr-25"
            xml:space="preserve">
            <g>
              <path class="st0" fill="#FFFFFF" d="M55.14,104.21c4.22,0,8.44,0.19,12.66-0.09c3.84-0.19,7.88-0.56,11.63-1.5c29.82-7.31,45.76-40.23,32.72-68.07 C104.27,17.76,90.77,8.19,72.3,6.22c-14.16-1.5-26.82,2.72-37.51,12.28c-10.5,9.47-15.94,21.28-16.31,35.44 c-0.09,3.28,0,6.66,0,9.94C18.38,71.02,14.35,76.55,7.5,78.7c-0.09,0-0.28,0.19-0.38,0.19c2.63,6.94,13.31,17.16,19.97,19.69 C35.45,87.14,52.32,91.18,55.14,104.21L55.14,104.21z"/>
              <path d="M54.95,110.49c-1.03,4.69-3.56,8.16-7.69,10.31c-5.25,2.72-10.6,2.63-15.57-0.56c-5.16-3.28-7.41-8.25-7.03-14.35 c0.09-1.03-0.19-1.41-1.03-1.88c-9.1-4.78-16.31-11.44-21.28-20.44c-0.94-1.78-1.69-3.66-2.16-5.63c-0.66-2.72,0.38-4.03,3.19-4.31 c3.38-0.38,6.38-1.69,7.88-4.88c0.66-1.41,1.03-3.09,1.03-4.69c0.19-4.03,0-8.06,0.19-12.1c1.03-15.57,7.5-28.5,19.32-38.63 C42.67,3.97,55.42-0.43,69.76,0.03c25.04,0.94,46.51,18.57,51.57,43.23c4.59,22.32-2.34,40.98-20.07,55.51 c-1.03,0.84-2.16,1.69-3.38,2.44c-0.66,0.47-0.84,0.84-0.56,1.59c2.34,7.13-0.94,15-7.5,18.38c-8.91,4.41-19.22-0.09-21.94-9.66 c-0.09-0.38-0.56-0.84-0.84-0.84C63.11,110.4,59.07,110.49,54.95,110.49L54.95,110.49z M55.14,104.21c4.22,0,8.44,0.19,12.66-0.09 c3.84-0.19,7.88-0.56,11.63-1.5c29.82-7.31,45.76-40.23,32.72-68.07C104.27,17.76,90.77,8.19,72.3,6.22 c-14.16-1.5-26.82,2.72-37.51,12.28c-10.5,9.47-15.94,21.28-16.31,35.44c-0.09,3.28,0,6.66,0,9.94 C18.38,71.02,14.35,76.55,7.5,78.7c-0.09,0-0.28,0.19-0.38,0.19c2.63,6.94,13.31,17.16,19.97,19.69 C35.45,87.14,52.32,91.18,55.14,104.21L55.14,104.21z"/>
              <path d="M74.92,79.74c-11.07-0.56-18.38-4.97-23.07-13.78c-1.13-2.16-0.09-4.31,2.06-4.78c1.31-0.28,2.53,0.66,3.47,2.16 c1.22,1.88,2.44,3.75,4.03,5.25c8.81,8.34,23.25,5.72,28.79-5.06c0.66-1.31,1.5-2.34,3.09-2.34c2.34,0.09,3.66,2.44,2.63,4.59 c-2.91,5.91-7.5,10.22-13.69,12.28C79.51,78.99,76.7,79.36,74.92,79.74L74.92,79.74z"/>
              <path d="M55.32,48.98c-3.38,0-6.09-2.72-6.09-6.09s2.72-6.09,6.09-6.09s6.09,2.72,6.09,6.09C61.42,46.17,58.7,48.98,55.32,48.98 L55.32,48.98z"/>
              <path d="M98.27,42.79c0,3.38-2.72,6.09-6,6.19c-3.38,0-6.09-2.63-6.09-6.09c0-3.38,2.63-6.09,6-6.19 C95.46,36.7,98.17,39.42,98.27,42.79L98.27,42.79z"/>
            </g>
          </svg>
              Waze
          </div>

          <div
            class="p-2 border cursor-pointer mb-2 text-capitalize"
            @click="setSelectedWidget('detection_event')"
            draggable
            @dragstart="onDragStart('detection_event')"
            @dragend="onDragEnd"
            v-if="$can('detection_event', 'Widget')"
          >
            <feather-icon icon="ActivityIcon" size="16" class="mr-25" />
              {{ $t("grid.widgets_section.detection_event.title") }}
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import { SLOT_TYPES } from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import store from "@/store";

export default {
  mixins: [layoutMixin],
  components: {
    BCollapse,
  },
  components: {
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
    selectedLayout: {
      type: String | Number,
      default: null,
    },
  },
  data() {
    return {
      isCollapseOpen: true,
    };
  },
  mounted() {
    this.isCollapseOpen = this.isOpen;
  },
  computed: {
    currentTab() {
      return store.getters["grid/currentTab"] ? store.getters["grid/currentTab"] : {};
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return Object.keys(this.tabs).length;
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    userLayouts() {
      return store.state.layouts.userLayouts.map((l) => ({
        id: l.id,
        name: l.name,
        grid: l.data,
      }));
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    setSelectedWidget(widgetId) {
      switch (widgetId) {
        case "timeline":
          this.setSlotData(SLOT_TYPES.TIMELINE, "Timeline", { data: null });
          break;

        case "map":
          this.setSlotData(SLOT_TYPES.UNITS_MAP, `Mapa de la instalación`, null);
          break;

        case "page":
          this.setSlotData(SLOT_TYPES.FRAME, `Page`, {
            url: null,
            load: false,
          });
          break;

        case "charts":
          this.setSlotData(SLOT_TYPES.CHARTS_SDK, `Charts SDK`, { data: null });
          break;

        case "page":
          this.setSlotData(SLOT_TYPES.FRAME, `Page`, {
            url: null,
            load: false,
            save: false,
            id: null,
            description: null,
          });
          break;

        case "weather":
          this.setSlotData(SLOT_TYPES.WEATHER, `Weather`, { data: null });
          break;

        case "face_detection":
          this.setSlotData(SLOT_TYPES.FACE_DETECTION, `Face detection`, { data: null });
          break;

        case "waze":
          this.setSlotData(SLOT_TYPES.WAZE, `Waze`, { data: null });
          break;

        case "detection_event":
          this.setSlotData(SLOT_TYPES.DETECTION_EVENT, `Detection Event`, { data: null });
          break;

      }
    },
    onDragStart(widgetId) {
      this.setSelectedWidget(widgetId);
    },
    onDragEnd() {
      this.resetSlotSelection();
    },
    async showTimeline() {
      const timelineSlot = this.buildSlot(
        "timeline",
        5,
        { data: null },
        SLOT_TYPES.TIMELINE
      );
      const tabName = "Timeline";
      const tab = await store.dispatch("grid/addNewTab", {
        tabName,
        layout: "Timeline Extended",
        type: "timeline",
      });

      if (tab.status) {
        store.dispatch("grid/setActiveTab", tab.id);
        this.placeSlots([timelineSlot]);
        store.dispatch("grid/updateUserConfig");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-layout {
  .border {
    background-color: #708599;
  }
}
</style>
