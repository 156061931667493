var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user.video_wall !== 1)?_c('a',{class:[
      'btn-toolbars-collapse bg-secondary text-white',
      { 'toolbars-shown': _vm.isToolbarsCollapsed } ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.updateToolbarsStatus.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"ChevronLeftIcon"}})],1):_vm._e(),(_vm.$can('show_right_sidebar', 'Alarm') && _vm.user.video_wall !== 1)?_c('a',{class:[
      'btn-toolbars-collapse-right bg-secondary text-white',
      { 'toolbars-shown': _vm.isToolbarsCollapsedRight } ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.updateToolbarsStatusRight.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"ChevronRightIcon"}})],1):_vm._e(),_c('div',{staticClass:"dashboard-container",class:[
      {
        'hidden-toolbars': _vm.isToolbarsCollapsed,
        'hidden-toolbars-right': _vm.isToolbarsCollapsedRight,
      } ]},[(_vm.user.video_wall === 0)?_c('side-bar',{style:(("width: " + _vm.sidebarWidth + "px;")),attrs:{"id":"side-bar"}}):_vm._e(),_c('div',{staticClass:"container-center",style:(("width: " + _vm.containerCenterWith + ";"))},[_c('div',{class:[{ 'selection-dnd': _vm.slotDataWillOpenATab }],attrs:{"id":"dashboard"},on:{"dragover":_vm.onDragOver,"drop":_vm.onDrop}},[_c('div',{staticStyle:{"position":"relative"}},[_c('tabs-component')],1)]),(_vm.user.video_wall === 0)?_c('bottom-bar',{attrs:{"id":"bottom-bar"}}):_vm._e()],1),(_vm.$can('show_right_sidebar', 'Alarm') && _vm.user.video_wall === 0)?_c('side-bar-right',{style:(("width: " + _vm.sidebarRightWidth + "px;")),attrs:{"id":"side-bar-right","width":_vm.sidebarRightWidth}}):_vm._e()],1),_vm._l((_vm.floatVideos),function(video){return _c('floating-container',{key:video.uuid,attrs:{"cameraId":video.id,"uuid":video.uuid,"cameraType":video.type}})}),_c('layout-modal'),_c('alarm-action-modal'),_c('scripts-modal'),_c('subsystem-action-modal'),_c('keyboard-events'),_c('image-modal')],2)}
var staticRenderFns = []

export { render, staticRenderFns }