<template>
  <div class="plane">
    <div class="plane-container" style="">
      <div class="mt-2 text-center">
        <p class="font-large-1 mb-0">{{ data.name }}</p>
      </div>
      <img :src="data.image_url" :alt="data.name" @load="onImgLoad" />
      <div ref="canvas" class="canvas">
        <device v-for="(device, index) in data.devices" :key="index" :device="device" :pos="pos" />
      </div>
    </div>
  </div>
</template>

<script>
import Device from "./Device.vue";
export default {
  components: { Device },
  props: {
    data: Object,
    layoutInfo: Object,
    pos: String | Number,
  },
  methods: {
    onImgLoad(evt) {
      this.$refs.canvas.style.width = `${evt.target.offsetWidth}px`;
      this.$refs.canvas.style.height = `${evt.target.offsetHeight}px`;
      this.$refs.canvas.style.marginTop = `-${evt.target.offsetHeight}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.plane {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  .plane-container {
    padding: 5% 10px;
    width: 100%;
    height: 100%;
  }
  .canvas {
    position: relative;
  }
}
</style>
