export default {
  id: 1,
  name: 'default',
  grid: [
    {"x":0,"y":0,"w":6,"h":4,"minW":3,"minH":2,"id":"6a82c481-1777-4335-8661-0f656e1b4142","content":"6 x 4","name":null,"type":null,"pos":1,"data":null},
    {"x":6,"y":0,"w":6,"h":4,"minW":3,"minH":2,"id":"e1538f98-4b4d-408d-8b21-bed99cae4bb1","content":"6 x 4","name":null,"type":null,"pos":2,"data":null},
    {"x":0,"y":4,"w":6,"h":4,"minW":3,"minH":2,"id":"8b72a7e7-7aaa-44de-ae2c-3c9c6dedf915","content":"6 x 4","name":null,"type":null,"pos":3,"data":null},
    {"x":6,"y":4,"w":6,"h":4,"minW":3,"minH":2,"id":"2a374afa-a641-4049-8e1b-dfdff16ca93c","content":"6 x 4","name":null,"type":null,"pos":4,"data":null}
  ]
};
