<template>
    <div class="waze-slot">  
      <iframe
        :src="url"
        class="frame"
      ></iframe>
    </div>
  </template>
  
  <script>
  import layoutMixin from "@/mixins/layoutMixin";
  import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  
  export default {
    mixins: [layoutMixin],
    components: {
      BFormInput,
      BButton,
      BFormGroup,
    },
    props: {
      data: {
        type: Object,
      },
    },
    data() {
        return {
            url: 'https://embed.waze.com/es/iframe?zoom=15&lat=19.4327121&lon=-99.1930721',
        };
    },
    directives: {
      Ripple,
    },
    mounted() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                let location = this.$store.getters["user/getPreferences"].lang == 'pt' ? 'pt-BR': this.$store.getters["user/getPreferences"].lang;
                this.url = `https://embed.waze.com/${location}/iframe?zoom=15&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
              },
              () => {
                console.error('set current location');
              }
            );
        }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .waze-slot {
    width: 100%;
    height: 100%;
  }
  
  .frame {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
  }
  </style>
  