<template>
  <b-modal
    id="timeline_filters_modal"
    hide-header
    no-close-on-backdrop
    centered
    size="lg"
    scrollable
    content-class="overflow-hidden"
    @shown="onShown"
  >
    <div>
      <p class="h3">{{ $t("grid.timeline.objectsModal.title") }}</p>
      <table class="table">
        <tr>
          <th>{{ $t("grid.timeline.objectsModal.field3.label") }}</th>
          <td>
            <input
              id="date_selector_from"
              class="form-control form-control-sm"
              readonly="readonly"
              v-model="selectedValues.from"
            />
          </td>
        </tr>

        <tr>
          <th>{{ $t("grid.timeline.objectsModal.field4.label") }}</th>
          <td>
            <input
              id="date_selector_to"
              class="form-control form-control-sm"
              readonly="readonly"
              v-model="selectedValues.to"
            />
          </td>
        </tr>
      </table>
      <!-- meta-filters-component -->
      <meta-filters-component
        v-model="selectedValues.objectData"
        :ext-filters="extFilters"
      />
    </div>
    <template #modal-footer="{ close }">
      <div class="">
        <b-button variant="primary" size="sm" class="float-left mr-1" @click="close()">
          {{ $t("close") }}
        </b-button>
        <b-button variant="primary" size="sm" class="float-right" @click="search(close)">
          {{ $t("search") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import LayoutApi from "@/libs/LayoutApi";
import { EventBus } from "@/libs/event-bus";
import MetaFiltersComponent from "@/components/MetaFiltersComponent";
import { formatFilters, toQueryString } from "@/components/MetaFiltersComponent/helpers";
import vSelect from "vue-select";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import store from "@/store";
import moment from "moment";

import "flatpickr/dist/flatpickr.css";

const layoutApi = new LayoutApi(store);

export default {
  components: {
    vSelect,
    MetaFiltersComponent,
  },
  props: {
    config: Object,
  },
  data() {
    const tabId = layoutApi.getCurrentTabId();

    return {
      cameras: [],
      dateSelectorFrom: null,
      dateSelectorTo: null,
      extFilters: {
        customFields: null,
      },
      selectedValues: {
        cameras: [],
        from: null,
        to: null,
        objectData: null,
      },
      tabId,
    };
  },
  mounted() {
    EventBus.on(`timeline:${this.tabId}:ping-cam`, this.setCameras);
  },
  beforeDestroy() {
    EventBus.off(`timeline:${this.tabId}:ping-cam`, this.setCameras);
  },
  computed: {},
  methods: {
    getCameras() {
      this.cameras = [];
      EventBus.emit(`timeline:${this.tabId}:ping`);
    },
    setCameras(idCameraProxy) {
      if (!this.cameras.find((idCamera) => idCamera === idCameraProxy)) {
        this.cameras.push(idCameraProxy);
      }
    },
    initializeDateTimePickers() {
      const config = {
        minDate: moment()
          .subtract(this.config.minDays ? this.config.minDays : 15, "days")
          .toDate(),
        maxDate: moment().toDate(),
        altInput: true,
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
        locale: this.$i18n.locale === "en" ? null : Spanish,
      };

      const dateSelectorFrom = "#date_selector_from";
      this.dateSelectorFrom = flatpickr(dateSelectorFrom, { ...config });

      const dateSelectorTo = "#date_selector_to";
      this.dateSelectorTo = flatpickr(dateSelectorTo, { ...config });
    },
    onShown() {
      this.initializeDateTimePickers();
      this.getCameras();
      this.extFilters.customFields = {
        "cameras[]": this.cameras,
      };
    },
    search(close) {
      const cameras = this.cameras;
      const selectedValues = this.selectedValues;
      let filters = {};

      filters["cameras[]"] = cameras;

      if (!selectedValues.from && !selectedValues.to) {
        filters.timestamp_gte = moment().subtract(72, "hours").valueOf();
      }

      if (selectedValues.from) {
        filters.timestamp_gte =  moment(selectedValues.from).valueOf();
      }

      if (selectedValues.to) {
        filters.timestamp_lte = moment(selectedValues.to).valueOf();
      }

      const metadataFilters = formatFilters(this.selectedValues.objectData);
      filters = { ...filters, ...metadataFilters };

      this.$emit("search", toQueryString(filters));

      close();
    },
  },
};
</script>

<style></style>
