<template>
  <div>
    <toastification-loading-content ref="loadingToast" />
    <!-- Table Container Card -->
    <b-card :class="{ 'm-2': showFilters, 'm-0': !showFilters }" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(unit.description)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <div style="position: relative;
                        width: 250px;">
            <img class="" :src="`${snapshotUrl}${data.item.id}?access_token=${token}`"
              style="width: 250px; border-radius: 16px;"/>
            <div style="z-index: 1;
              position: absolute;
              width: -webkit-fill-available;
              color: white;
              background: rgba(0,0,0,.5);
              padding: 5px;
              top: 0;
              right: 0;
              left: 0;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            ">
              <b-media vertical-align="center">
                <template #aside>
                  <div :style="`background: ${data.item.online ? 'green': 'red'};
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 1rem;
                            text-align: center;`">
                  </div>
                </template>
                <small style="font-weight: bolder; font-size: 14px;">{{ data.item.camera.name }}</small>
              </b-media>
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import Pagination from "@/components/Pagination.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from 'axios';

export default {
  components: {
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationLoadingContent,
  },
  props: {
    filters: {
      type: Object | Array,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const useListRef = useList();

    if (props.showFilters) useListRef.initFilters()

    //start pagination
    const update = (data) => {
      useListRef.currentPage.value = data;
    };
    //end pagination

    const snapshotUrl = `${process.env.VUE_APP_BASE_URL}/v1/services/snapshot/`


    const { t } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };
    const exportExcel = () => {
      useListRef.action.value = "export";
      buttonExport.value = true;

      useListRef.fetchList();
    };
    const buttonExport = ref(false);
    // end export

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          useListRef.searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("camera.title");
    // end breadcrumbs

    const toast = useToast();

    const updateStatusCamera = (id) => {
      store
        .dispatch("camera_proxy/updateStatus", id)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          refetchData();
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    useListRef.applyChartFilters(props.filters);

    return {
      ...useListRef,
      update,
      snapshotUrl,
      exportExcel,
      buttonExport,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      updateStatusCamera,

      token: localStorage.accessToken,
    };
  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.token-td {
  max-width: 250px;
}

td[role="cell"] {
  background-color: transparent !important;
}
</style>
