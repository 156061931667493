<template>
  <b-modal
    id="layout_modal"
    title="Diseño"
    ok-only
    @show="onShow"
    @hide="applyChanges"
    @hidden="resetDataModal"
    hide-header
    no-close-on-esc
    size="lg"
  >
    <div class="row">
      <div class="col-md-6 d-flex h-100">
        <div v-if="dataModalReady">
          <p class="lead">{{ $t("grid.modals.update_layout.cameras") }}</p>
          <ul class="list-unstyled pl-2"
            v-if="!loadingCameras">
            <li v-for="(camera, index) in cameras" :key="index">
              <span
                v-if="$can('live_video', 'Video')" 
                class="d-block cursor-pointer"
                @click="onSelectCamera(camera, SLOT_TYPES.CAM_LIVE)"
              >
                {{ camera.name }}
              </span>
              <span
                v-if="$can('record_video', 'Video')" 
                class="d-block cursor-pointer"
                @click="onSelectCamera(camera, SLOT_TYPES.CAM_PA)"
              >
                {{ camera.name }} ({{ $t("grid.modals.update_layout.pre_alarm") }})
              </span>
            </li>
          </ul>

          <b-spinner
            v-if="loadingCameras"
            label="Spinning"
            variant="primary"
            class="ml-2 my-2"
          ></b-spinner>

          <hr />
          <p class="lead">{{ $t("grid.modals.update_layout.info") }}</p>
          <a href="#" class="pl-2" @click.prevent="onSelectInfo">
            <span v-if="isAlarm">{{ $t("grid.modals.update_layout.alarm_info") }}</span>
            <span v-else>{{ $t("grid.modals.update_layout.unit_info") }}</span>
          </a>

          <!-- Plane -->
          <template v-if="isAlarm && dataModal.data.obj.unit.planes_count > 0">
            <hr />
            <p class="lead">{{ $t("grid.modals.update_layout.plane") }}</p>
            <a href="#" class="d-block pl-2" @click.prevent="onSelectPlane">
              {{ $t("grid.modals.update_layout.unit_plane") }}
            </a>
          </template>

          <!-- Maps -->
          <template v-if="isAlarm && dataModal.data.obj.coordinates">
            <hr />
            <p class="lead">{{ $t("grid.modals.update_layout.map") }}</p>
            <a href="#" class="d-block pl-2" @click.prevent="onSelectMap">
              {{ $t("grid.modals.update_layout.alarm_location") }}
            </a>
            <a href="#" class="d-block pl-2" @click.prevent="onSelectUnitMap">
              {{ $t("grid.modals.update_layout.unit_location") }}
            </a>
          </template>

          <!-- Action Plans -->
          <template v-if="actionPlans.length">
            <hr />
            <p class="lead">{{ $t("action_plan.title") }}</p>
            <ul class="list-unstyled pl-2">
              <li v-for="(actPlan, index) in actionPlans" :key="index">
                <span class="d-block cursor-pointer" @click="onSelectActionPlan(actPlan)">
                  {{ actPlan.name }}
                </span>
              </li>
            </ul>
          </template>
          <b-spinner
            v-if="loading"
            label="Spinning"
            variant="primary"
            class="ml-2 my-2"
          ></b-spinner>

          <template v-if="!isAlarm">
            <hr />
            <p class="lead">{{ $t("grid.modals.update_layout.map") }}</p>
            <a href="#" class="d-block pl-2" @click.prevent="onSelectUnitMap">
              {{ $t("grid.modals.update_layout.unit_location") }}
            </a>
          </template>

          <template v-if="dataModal.data.obj.locations_count > 0">
            <hr />
            <p class="lead">{{ $t("grid.modals.update_layout.gps") }}</p>
            <a href="#" class="d-block pl-2" @click.prevent="onSelectGps">
              {{ $t("grid.modals.update_layout.locations") }}
            </a>
          </template>
        </div>
        <div
          class="d-flex justify-content-center align-items-center text-center w-100"
          style="min-height: 200px"
          v-else
        >
          <b-spinner label="Spinning" variant="primary"></b-spinner>
        </div>
      </div>
      <div class="col-md-6">
        <preview-layout />
        <div class="text-center p-2" v-if="slotSelection">
          <p class="lead mb-0">{{ $t("grid.modals.update_layout.select_slot") }}</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import store from "@/store";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import PreviewLayout from "../layouts/PreviewLayout.vue";
import axios from "@axios";

export default {
  mixins: [layoutMixin],
  components: {
    BSpinner,
    PreviewLayout,
  },
  data() {
    return {
      SLOT_TYPES,
      SOURCES,
      actionPlans: [],
      loading: true,
      loadingCameras: true,
      cameras: []
    };
  },
  computed: {
    dataModal() {
      return store.state.grid.layoutModal;
    },
    dataModalReady() {
      return Object.keys(store.state.grid.layoutModal.data).length > 0;
    },
    isAlarm() {
      return store.state.grid.layoutModal.source === this.SOURCES.ALARM;
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return Object.keys(this.tabs).length;
    },
    slotSelection() {
      return store.state.grid.slotSelection;
    },
  },
  methods: {
    onShow() {
      if (this.tabsQty === 0) {
        store.dispatch("grid/addNewTab");
        store.dispatch("grid/setActiveTab", Object.keys(this.tabs)[0]);
      }

      this.fetchActionPlans().then(({ data }) => {
        this.actionPlans = data.data.data;
        this.loading = false;
      });

      this.fetchUnitData().then(({ data }) => {
        this.cameras = data.data.cameras.filter(camera => camera.device_type == "camera");
        this.loadingCameras = false;
      });

      store.dispatch("grid/setCurrentLayoutToTmp");
    },
    onSelectCamera(camera, type = "cam") {
      const alarm = this.dataModal.data.obj;
      const data = { camera, ts: alarm.created };
      const cameraName =
        type === this.SLOT_TYPES.CAM_LIVE
          ? camera.name || `CAM_${camera.camera_id}`
          : `${camera.name} (Pre-Alarma)` || `CAM_PA_${camera.camera_id}`;
      const camType =
        type === this.SLOT_TYPES.CAM_LIVE
          ? this.SLOT_TYPES.CAM_LIVE
          : this.SLOT_TYPES.CAM_PA;

      this.setSlotData(camType, cameraName, data);
    },
    onSelectInfo() {
      if (this.dataModal.source === this.SOURCES.ALARM) {
        this.setSlotData(
          this.SLOT_TYPES.INFO,
          `Alarma: ${this.dataModal.data.obj.alarm_type}`,
          this.dataModal.data.obj,
          this.SOURCES.ALARM
        );
      } else {
        this.setSlotData(
          this.SLOT_TYPES.INFO,
          `Instalación: ${this.dataModal.data.obj.description}`,
          this.dataModal.data.obj,
          this.SOURCES.UNIT
        );
      }
    },
    onSelectPlane() {
      this.setSlotData(
        this.SLOT_TYPES.PLANE,
        `Plano: ${this.dataModal.data.obj.unit.description}`,
        { planes_url: this.dataModal.data.obj.unit_planes }
      );
    },
    onSelectMap() {
      this.setSlotData(
        this.SLOT_TYPES.MAP,
        `Mapa: ${this.dataModal.data.obj.alarm_type}`,
        { data: this.dataModal.data.obj }
      );
    },
    onSelectGps() {
      this.setSlotData(
        this.SLOT_TYPES.GPS,
        `Mapa: ${this.dataModal.data.obj.alarm_type}`,
        { data: this.dataModal.data.obj }
      );
    },
    onSelectUnitMap() {
      if (this.isAlarm) {
        this.setSlotData(this.SLOT_TYPES.UNITS_MAP, `Mapa de la instalación`, {
          unit_id: this.dataModal.data.obj.unit.id,
        });
      } else {
        this.setSlotData(
          this.SLOT_TYPES.UNITS_MAP,
          `Mapa de la instalación`,
          { unit_id: this.dataModal.data.obj.id },
          this.SOURCES.UNIT
        );
      }
    },
    onSelectActionPlan(actPlan) {
      const data = { ...this.dataModal.data.obj, id_action_plan: actPlan.id };

      this.setSlotData(
        this.SLOT_TYPES.ACTION_PLAN,
        `${actPlan.name}`,
        { ...data },
        this.SOURCES.UNIT
      );
    },
    fetchActionPlans() {
      const alarm = this.dataModal.data.obj;

      return axios.get("/v1/action_plan/find-action-plan", {
        params: {
          id_alarm: alarm.id,
          alarm_type: alarm.type,
          id_tenant: alarm.tenant.id,
        },
      });
    },
    fetchUnitData() {
      const alarm = this.dataModal.data.obj;

      return axios.get(`/v1/unit/${alarm.unit.id}`);
    },
    applyChanges() {
      store.dispatch("grid/updateLayoutSlots");
    },
    resetDataModal() {
      store.dispatch("grid/emptyTmpLayoutItems");
      store.dispatch("grid/resetLayoutModal");
      this.actionPlans = [];
    },
  },
};
</script>

<style></style>
