<template>
  <div
    :class="[
      `grid-layout__item gl-w-${widget.w} gl-x-${widget.x}`,
      { 'slot-selection': slotSelection },
    ]"
    @click="onSelectSlot(widget.pos, widget)"
    @dblclick="unSetSlot(widget)"
  >
    <div class="grid-layout__item--content">
      <a
        href="#"
        class="remove-icon"
        v-if="slotItem.type"
        @click.prevent="unSetSlot(widget)"
      >
        <feather-icon icon="XIcon" size="14"/>
      </a>
      <p v-b-tooltip="slotItem.name">
        {{ slotItem.name }}
      </p>
    </div>
  </div>
</template>

<script>
import {VBTooltip} from "bootstrap-vue";
import store from "@/store";
import CamSlot from "./slots/CamSlot.vue";
import UnitsMapSlot from "./slots/UnitsMapSlot.vue";
import InfoSlot from "./slots/InfoSlot";

export default {
  components: {
    CamSlot,
    UnitsMapSlot,
    InfoSlot,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    tabId: {
      type: String | Number,
    },
    index: {
      type: Number,
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
    slots: {
      type: Array,
    },
  },
  computed: {
    slotItem() {
      return this.getSlotIfExists(this.slots[this.index]);
    },
    slotSelection() {
      return store.state.grid.slotSelection;
    },
    slotData() {
      return store.state.grid.slotData;
    },
    currentTab() {
      return store.getters["grid/currentTab"];
    },
    currentTabSlots() {
      return store.getters["grid/tmpLayoutItems"];
    },
  },
  methods: {
    onSelectSlot(pos) {
      if (this.slotData && this.slotData.data) {
        const slot = this.findItem(this.slots, pos, "pos");
        let updatedSlot = null;

        slot.type = this.slotData.type;
        slot.name = this.slotData.name;
        slot.data = this.slotData.data;
        updatedSlot = Object.assign({}, slot);

        store.dispatch("grid/resetSlotSelection");

        if (updatedSlot) {
          store.dispatch("grid/addTmpLayoutItem", updatedSlot);
        }
      }
    },
    unSetSlot(slot) {
      this.removeItemFromSlot(slot);
    },
    removeItemFromSlot(_slot) {
      const slot = this.findItem(this.slots, _slot.pos, "pos");
      let slotPos = 0;

      slot.type = null;
      slot.name = null;
      slot.data = {};
      slotPos = _slot.pos;

      if (slotPos > 0) {
        this.clearSlot(_slot.pos);
      }
    },
    clearSlot(pos) {
      store.dispatch("grid/removeTmpLayoutItem", pos);
    },
    findItem(data, id, prop = "id") {
      return data.find((item) => {
        if (item[prop] == id) return item;
      });
    },
    getSlotIfExists(_slot) {
      let slot = this.currentTabSlots.find((s) => _slot.pos === s.pos);
      return slot ? slot : _slot;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-layout__item {
  padding: 3px;
  cursor: pointer;
  border: none;

  &.slot-selection {
    &:hover {
      .grid-layout__item--content {
        background-color: #90d7ff;
      }
    }
  }

  .grid-layout__item--content {
    padding: 5px;

    .remove-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      color: red;
    }

    p {
      margin: 0;
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
</style>
