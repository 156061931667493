<template>
  <carousel
    :v-bind="imagesLoaded"
    style="width: 350px"
    :per-page="1"
    :adjustableHeight="false"
    paginationPosition="bottom-overlay"
    :paginationEnabled="false"
    :centerMode="true"
    paginationActiveColor="#1C3D5E"
    :navigationEnabled="true"
    @page-change="pageChange"
    class="carrousel-alarm-table"
  >
    <div v-if="!imagesLoaded.length">No images available</div>
    <slide
      v-for="(image, key) in imagesLoaded"
      :key="`slide-${key}`"
      style="width: 350px; min-height: 150px; position: relative"
      @slide-click="slideClick(image)"
      v-b-modal.image_modal
    >
      <div style="position: absolute; width: 100%; height: 100%; z-index: 3" v-if="image">
        <div
          style="
            color: white;
            width: 100%;
            background-color: rgba(0, 0, 0, 0) !important;
            text-shadow: 1px 2px #000;
            padding: 0.5rem;
            top: 0;
            background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 0.5) 95%);
          "
        >
          <span>{{ detEvent.data.camera_name }}</span>
          <span style="float: right">{{ dateParser.parseDate(detEvent.created_at) }}</span>
        </div>

        <div
          style="
            position: absolute;
            color: white;
            width: 100%;
            background-color: rgba(0, 0, 0, 0) !important;
            text-shadow: 1px 2px #000;
            padding: 0.5rem;
            bottom: 0;
            background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
          "
        >
          <span style="bottom: 0">
            {{ $t(`grid.slots.info.detection_events.${detEvent.event_type}.title`) }}
          </span>
        </div>
      </div>
      <img style="width: 350px" :src="image" v-if="image" />
      <b-spinner
        v-else
        label="Spinning"
        variant="primary"
        style="margin-left: calc(50% - 10px); margin-top: 70px; width: 3rem; height: 3rem"
      ></b-spinner>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import VueImageZoomer from "vue-image-zoomer";
import store from "@/store";
import dateParser from "@/libs/date-parser";

export default {
  name: "Carrousel",
  components: {
    Carousel,
    Slide,
    VueImageZoomer,
  },
  props: {
    idAlarm: Number,
    detEvent: Object,
  },
  data() {
    return {
      imagesLoaded: [],
      dateParser
    };
  },
  methods: {
    pageChange(pageNumber) {},
    async slideClick(imageUrl) {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    },
  },
  mounted() {
    this.imagesLoaded = this.detEvent.data.images ? this.detEvent.data.images : [];
  },
};
</script>

<style>
.carrousel-alarm-table .VueCarousel-navigation-prev {
  left: 40px;
}

.carrousel-alarm-table .VueCarousel-navigation-next {
  right: 40px;
}
</style>
