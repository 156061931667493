<template>
  <div class="planes-main-container">
    <div class="floors">
      <a
        v-for="(plane, index) in planes"
        :key="index"
        href="#"
        :class="[{ 'text-secondary': currentFloor == index + 1 }]"
        @click.prevent="currentFloor = index + 1"
      >
        {{ plane.name }}
      </a>
    </div>
    <div ref="planes_main">
      <template v-for="(plane, index) in planes">
        <plane
          :key="index"
          :data="plane"
          :layout-info="layoutInfo"
          :pos="pos"
          v-if="index + 1 === currentFloor"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Panzoom from "@panzoom/panzoom";
import axios from "@axios";
import Plane from "./Plane.vue";

export default {
  components: { Plane },
  props: {
    data: {
      type: Object,
      required: true,
    },
    pos: String | Number
  },
  data() {
    return {
      planes: [],
      layoutInfo: {},
      currentFloor: 1,
      panzoom: null,
    };
  },
  async mounted() {
    const { data } = await axios.get(this.data.data.planes_url);
    this.layoutInfo = {
      with: this.$el.offsetWidth,
      height: this.$el.offsetHeight,
    };
    this.planes = data.data;

    this.panzoom = Panzoom(this.$refs.planes_main, { maxScale: 5 });

    this.panzoom.zoom(0.9, { animate: true });

    this.$refs.planes_main.addEventListener("wheel", this.panzoom.zoomWithWheel);
  },
  beforeDestroy() {
    this.$refs.planes_main.removeEventListener("wheel", this.panzoom.zoomWithWheel);
  },
  computed: {
    source() {
      return this.data.data.in_app_source;
    },
  }
};
</script>

<style lang="scss" scoped>
.planes-main-container {
  height: 100%;
  background-color: #fff;
  .floors {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    min-width: 100px;
    a {
      padding: 0 1rem;
      border-right: 1.6px solid #e9e7e7;
      &:last-child {
        margin-right: 0;
        border: none;
      }
    }
  }
}
</style>
