<template>
  <div class="face-detection-container">
    <div class="waiting-detection" v-if="faceDetection == null">
      <p class="mb-0 mx-auto text-center font-medium-5">
        <strong>
          {{ $t("grid.slots.info.fr.waiting_detection") + "..." }}
        </strong>
      </p>
    </div>
    <div style="background-image: url('oxxo/welcome.png')" class="container-wallpaper d-none">
      <p class="text-welcome text-center mt-2">
        {{ $t("grid.slots.info.fr.welcome") }}
      </p>
      <div class="container-names"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faceDetection: null,
    };
  },
  mounted() {
    window.Echo.connector.socket.on("subscription_error", (channel, data) => {
      console.log(channel, data);
    });

    if (!window.Echo.connector.socket.connected) window.Echo.connect();
    window.Echo.private(`face-detection`).listen(".create", (data) => {
      this.faceDetection = data;
    });
  },
  watch: {
    faceDetection(newPerson, oldPerson) {
      document.querySelector(".container-wallpaper").classList.remove("d-none");
      const container = document.querySelector(".container-names");

      if (oldPerson != null) {
        if (newPerson.name != oldPerson.name) {
          if (container.getElementsByTagName("p").length <= 3) {
            container.insertAdjacentHTML("afterbegin", this.names(newPerson));

          } else {
            container.removeChild(container.getElementsByTagName("p")[3]);
            container.insertAdjacentHTML("afterbegin", this.names(newPerson));

          }
        }
      } else {
        container.innerHTML += this.names(newPerson);
      }
    },
  },
  methods: {
    names(data) {
      return `<p style="background: #181861; color: white; padding: 5px 0px 5px 10px; margin-left: 5px; font-size:1.5vw">
      <img style="height:25px; padding-right: 5px;" src="oxxo/list.png">${data.name}
      </p>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.face-detection-container {
  position: relative;
  height: 100%;
  overflow: scroll;
}
.waiting-detection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-wallpaper {
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text-welcome {
  position: absolute;
  background: #5d5d60;
  color: white;
  width: 50%;
  padding: 5px 0px 5px 0px;
  font-size:2.5vw
}
.container-names {
  top:20%;
  width: 50%;
  position: absolute;
}
</style>
