<template>
  <draggable
    :class="[
      'bottom-bar',
      { 'bbar-visible': shouldBeVisible },
      { dragging: sidebarStartDragging },
      { 'one-item-left': items.length < 2 },
    ]"
    v-model="items"
    handle=".handle"
    :group="groupOpts"
    @end="onEnd"
  >
    <template v-for="item in items">
      <template v-if="item.componentName && item.componentName === 'alarms-widget' && shouldShowWidget(item)">
        <component
          :is="item.componentName"
          :title="resolveWidgetTitle(item.title)"
          :filters="item.filters"
          :id="item.id"
          :key="item.id"
          :collapsible="item.collapsible"
          :isOpen="item.isOpen"
          location="bottomBar"
          @widget-collapsed="onWidgetCollapse"
        />
      </template>

      <template v-else-if="item.componentName && item.componentName === 'tenants-widget' && shouldShowWidget(item)">
        <component
          :is="item.componentName"
          :title="resolveWidgetTitle(item.componentName)"
          :id="item.id"
          :key="item.id"
          :collapsible="item.collapsible"
          :isOpen="item.isOpen"
          location="bottomBar"
          @widget-collapsed="onWidgetCollapse"
        />
      </template>

      <template v-else-if="item.componentName && shouldShowWidget(item)">
        <component
          :is="item.componentName"
          :title="resolveWidgetTitle(item.componentName)"
          :id="item.id"
          :key="item.id"
          :collapsible="item.collapsible"
          :isOpen="item.isOpen"
          location="bottomBar"
          @widget-collapsed="onWidgetCollapse"
        />
      </template>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import AlarmsWidget from "./widgets/AlarmsWidget";
import TenantsWidget from "./widgets/TenantsWidget";
import LayoutsWidget from "./widgets/LayoutsWidget";
import WidgetsWidget from "./widgets/WidgetsWidget";
import ScriptsWidget from "./widgets/ScriptsWidget";
import PagesWidget from "./widgets/PagesWidget";
import UnitGroupsWidget from "./widgets/UnitGroupsWidget";
import VideoWallWidget from "./widgets/VideoWallWidget.vue";
import store from "@/store";

export default {
  components: {
    draggable,
    AlarmsWidget,
    TenantsWidget,
    LayoutsWidget,
    WidgetsWidget,
    ScriptsWidget,
    VideoWallWidget,
    PagesWidget,
    UnitGroupsWidget,
  },
  data() {
    return {
      groupOpts: {
        name: "dashboard",
        put: this.onPutItem,
      },
    };
  },
  computed: {
    items: {
      get() {
        return store.state.grid.bottomBarItems;
      },
      set(value) {
        store.dispatch("grid/setBottomBarItems", value);
      },
    },
    sidebarStartDragging() {
      return store.state.grid.sidebarStartDragging;
    },
    shouldBeVisible() {
      return this.items.length > 0 || this.sidebarStartDragging;
    },
  },
  methods: {
    onPutItem(to, from) {
      return !(this.items.length > 1);
    },
    onEnd() {
      store.dispatch("updateUserConfig");
    },
    onWidgetCollapse(data) {
      store.dispatch("grid/updateWidgetStatus", data);
    },
    shouldShowWidget(item) {
      switch (item.componentName) {
        case "alarms-widget":
          if (item.title == 'Alarmas en espera')
            return this.$can('waiting_alarms', 'Workspace');
          else
            return this.$can('attending_alarms', 'Workspace');

        case "tenants-widget":
          return this.$can('facilities', 'Workspace');

        case "layouts-widget":
          return this.$can('layouts', 'Workspace');

        case "views-widget":
          return this.$can('views', 'Workspace');

        case "widgets-widget":
          return this.$can('widgets', 'Workspace');

        case "video-wall-widget":
          return this.$can('video_wall', 'Workspace');

        case "scripts-widget":
          return this.$can('commands', 'Workspace');

        case "pages-widget":
          return this.$can('web_pages', 'Workspace');

        case "unit-groups-widget":
          return this.$can('facilities_group', 'Workspace');

        case "user-charts-widget":
          return this.$can('charts', 'Workspace');

        default:
          return false;
      }
    },
    resolveWidgetTitle(title) {
      switch (title) {
        case "Alarmas en espera":
          return this.$t("grid.widgets.alarms.incoming_alarms.title");

        case "Alarmas en atención":
          return this.$t("grid.widgets.alarms.alarms_being_attended.title");

        case "tenants-widget":
          return this.$t("grid.widgets.units.title");

        case "layouts-widget":
          return this.$t("grid.widgets.layouts.title");

        case "views-widget":
          return this.$t("grid.widgets.views.title");

        case "widgets-widget":
          return this.$t("grid.widgets.widgets.title");

        case "video-wall-widget":
          return this.$t("grid.widgets.videowall.title");

        case "scripts-widget":
          return this.$t("grid.widgets.scripts.title");

        case "pages-widget":
          return this.$tc("pages.title", 2);

        case "unit-groups-widget":
          return this.$t("unit_groups.title");

        default:
          return title;
      }
    },
  },
};
</script>

<style lang="scss">
.bottom-bar {
  display: none;
  flex-flow: row;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  border-top: 3px solid rgb(218, 218, 218);
  background-color: rgba(#fff, 0.8);

  &.one-item-left {
    justify-content: start;
  }

  &.bbar-visible {
    display: flex;
  }

  .widget {
    width: 50%;
    height: auto;
    overflow: auto;
    transition: all, 0.2s;
  }
}
</style>
