export default {
  id: 1,
  name: 'default',
  grid: [
    {"w":8,"h":5,"minW":2,"minH":1,"id":"4693e74d-1670-4638-a3f6-987ae4133ce0","x":0,"y":0,"content":"8 x 5","name":null,"type":null,"pos":1,"data":null},
    {"w":4,"h":2,"minW":2,"minH":1,"id":"e6b83cd0-c7d8-4370-8926-7d20ab1cc7e5","x":8,"y":0,"content":"4 x 2","name":null,"type":null,"pos":2,"data":null},
    {"w":4,"h":3,"minW":2,"minH":1,"id":"b387c3d9-b760-46f0-b25d-21e0224adb72","x":8,"y":2,"content":"4 x 3","name":null,"type":null,"pos":3,"data":null},
    {"w":4,"h":3,"minW":2,"minH":1,"id":"4b72ea63-8f5c-4d3f-82a1-bbe8e5584e64","x":0,"y":5,"content":"4 x 3","name":null,"type":null,"pos":4,"data":null},
    {"w":4,"h":3,"minW":2,"minH":1,"id":"48b88521-3eed-49f2-b7f1-1d50078f9d4c","x":4,"y":5,"content":"4 x 3","name":null,"type":null,"pos":5,"data":null},
    {"w":4,"h":3,"minW":2,"minH":1,"id":"b7dab53c-b96d-4d8f-8dc9-37bc031a5650","x":8,"y":5,"content":"4 x 3","name":null,"type":null,"pos":6,"data":null}
  ]
};
