var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"mb-0 alarms-widget__data-table",attrs:{"items":_vm.items,"fields":_vm.visibleFields,"primary-key":"id","busy":_vm.busy,"show-empty":"","small":"","empty-text":_vm.$t('grid.widgets.alarms.table.no_data'),"responsive":"","tbody-tr-class":_vm.rowClass},on:{"row-dblclicked":_vm.onRowClick},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(data.label)))])]}},{key:"cell(alarm_acronym)",fn:function(row){return [_c('span',{staticClass:"px-2 badge cursor-pointer",style:({ backgroundColor: row.item.alarm_level.color }),attrs:{"id":("alarm-" + (row.item.id))}},[_vm._v(" "+_vm._s(row.item.alarm_acronym)+" ")]),_c('b-tooltip',{attrs:{"target":("alarm-" + (row.item.id)),"title":row.item.alarm_type,"triggers":"hover","variant":"info"}},[_vm._v(" "+_vm._s(row.item.alarm_type)+" ")])]}},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"d-flex align-items-center"},[(row.item.alarm_acronym === 'SD')?_c('feather-icon',{attrs:{"id":("SD-" + (row.item.id)),"icon":"UserIcon","size":"18"},on:{"mouseover":function($event){return _vm.configSD(row.item)}}}):_vm._e(),(row.item.alarm_acronym === 'SD')?_c('b-popover',{attrs:{"target":("SD-" + (row.item.id)),"placement":"right","triggers":"hover","boundary":"window"}},[_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.image_modal",modifiers:{"image_modal":true}}],staticStyle:{"width":"350px"},attrs:{"src":_vm.imgUrl},on:{"click":function($event){return _vm.openImageModal(_vm.imgUrl)}}})]):_vm._e(),(row.item.sip_call.length)?_c('feather-icon',{class:[
            {
              'on-call':
                row.item.sip_call[0].state == 'waiting' ||
                row.item.sip_call[0].state == 'answered'
                  ? true
                  : false,
            } ],attrs:{"id":("sip-call-button-" + (row.item.id)),"icon":"PhoneCallIcon","stroke":row.item.sip_call[0].state == 'waiting'
              ? 'red'
              : row.item.sip_call[0].state == 'answered' ||
                row.item.sip_call[0].state == 'transfer'
              ? 'orange'
              : 'gray',"size":"18"}}):_vm._e(),(row.item.sip_call.length)?_c('b-popover',{attrs:{"target":("sip-call-button-" + (row.item.id)),"placement":"right","triggers":"hover","boundary":"window"}},[_vm._v(" "+_vm._s(row.item.sip_call[0].destAddr ? row.item.sip_call[0].unit_user == undefined ? ("Llamada contestada por:" + (row.item.sip_call[0].remotePartyNumber)) : ("Llamada contestada por:" + (row.item.sip_call[0].unit_user)) : row.item.sip_call[0].state)+" ")]):_vm._e(),(row.item.alarm_acronym === 'FR')?_c('feather-icon',{attrs:{"id":("fr-button-" + (row.item.id)),"icon":"EyeIcon","size":"18"}}):_vm._e(),(row.item.alarm_acronym === 'FR')?_c('b-popover',{attrs:{"target":("fr-button-" + (row.item.id)),"placement":"right","triggers":"hover","boundary":"window"}},[_c('carrousel',{attrs:{"frData":row.item.facial_recognition,"firstImage":_vm.loadedImages}})],1):_vm._e(),(row.item.alarm_acronym === 'LPR' && row.item.lpr_data)?_c('feather-icon',{attrs:{"id":("lpr-button-" + (row.item.id)),"icon":"TruckIcon","size":"18"}}):_vm._e(),(row.item.alarm_acronym === 'LPR' && row.item.lpr_data)?_c('b-popover',{attrs:{"target":("lpr-button-" + (row.item.id)),"placement":"right","triggers":"hover","boundary":"window"}},[_c('carrousel-lpr',{attrs:{"idAlarm":row.item.id,"lprData":row.item.lpr_data,"lprMatches":row.item.lpr_data.total_matches}})],1):_vm._e(),(
            row.item.detection_event_data &&
            row.item.detection_event_data.provider != 'luna' &&
            !_vm.excludedDetectionEventAlerts.includes(row.item.alarm_acronym)
          )?_c('detection-event-type',{attrs:{"alarmData":row.item}}):_vm._e(),(row.item.analytic_record ? true : false)?_c('feather-icon',{attrs:{"id":("ar-button-" + (row.item.id)),"icon":"FilmIcon","size":"18"}}):_vm._e(),(row.item.analytic_record ? true : false)?_c('b-popover',{attrs:{"target":("ar-button-" + (row.item.id)),"placement":"right","triggers":"hover","boundary":"window"}},[_c('carrousel-ar',{attrs:{"arData":row.item.analytic_record,"arMatches":row.item.analytic_record_count}})],1):_vm._e()],1),_c('b-dropdown',{ref:"dropdown",staticClass:"m-0 p-0",attrs:{"text":"Ver","size":"sm","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon","size":"18"}})]},proxy:true}],null,true)},[(
            // (filters.waiting || filters.filter === 'waiting') &&
            _vm.$can('update_attention', 'Alarm')
          )?[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onAlarmClickAction(row, 'confirmed')}}},[_c('span',{staticClass:"text-success"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckCircleIcon","size":"13"}}),_vm._v(" "+_vm._s(_vm.$t("grid.widgets.alarms.menu.confirm"))+" ")],1)])]:_vm._e(),(
            // (filters.attending || filters.filter === 'attending') &&
            _vm.$can('update_attend', 'Alarm')
          )?[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onAlarmClickAction(row, 'attended')}}},[_c('span',{staticClass:"text-success"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckCircleIcon","size":"13"}}),_vm._v(" "+_vm._s(_vm.$t("grid.widgets.alarms.menu.attended"))+" ")],1)])]:_vm._e(),(_vm.$can('update_cancel', 'Alarm'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onAlarmClickAction(row, 'canceled')}}},[_c('span',{staticClass:"text-danger"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XCircleIcon","size":"13"}}),_vm._v(" "+_vm._s(_vm.$t("grid.widgets.alarms.menu.cancel"))+" ")],1)]):_vm._e()],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }