<template>
  <div>
    <a
      href="#"
      :class="[
        'btn-toolbars-collapse bg-secondary text-white',
        { 'toolbars-shown': isToolbarsCollapsed },
      ]"
      @click.prevent="updateToolbarsStatus"
      v-if="user.video_wall !== 1"
    >
      <feather-icon size="20" icon="ChevronLeftIcon" />
    </a>
    <a
      href="#"
      :class="[
        'btn-toolbars-collapse-right bg-secondary text-white',
        { 'toolbars-shown': isToolbarsCollapsedRight },
      ]"
      @click.prevent="updateToolbarsStatusRight"
      v-if="$can('show_right_sidebar', 'Alarm') && user.video_wall !== 1"
    >
      <feather-icon size="20" icon="ChevronRightIcon" />
    </a>
    <div
      class="dashboard-container"
      :class="[
        {
          'hidden-toolbars': isToolbarsCollapsed,
          'hidden-toolbars-right': isToolbarsCollapsedRight,
        },
      ]"
    >
      <side-bar
        v-if="user.video_wall === 0"
        id="side-bar"
        :style="`width: ${sidebarWidth}px;`"
      />
      <div class="container-center" :style="`width: ${containerCenterWith};`">
        <div
          id="dashboard"
          @dragover="onDragOver"
          @drop="onDrop"
          :class="[{ 'selection-dnd': slotDataWillOpenATab }]"
        >
          <div style="position: relative">
            <!-- Tabs -->
            <tabs-component />
          </div>
        </div>
        <bottom-bar v-if="user.video_wall === 0" id="bottom-bar" />
      </div>
      <side-bar-right
        v-if="$can('show_right_sidebar', 'Alarm') && user.video_wall === 0"
        id="side-bar-right"
        :width="sidebarRightWidth"
        :style="`width: ${sidebarRightWidth}px;`"
      />
    </div>
    <floating-container
      v-for="video in floatVideos"
      :key="video.uuid"
      :cameraId="video.id"
      :uuid="video.uuid"
      :cameraType="video.type"
    />
    <layout-modal />
    <alarm-action-modal />
    <scripts-modal />
    <subsystem-action-modal />
    <keyboard-events />
    <image-modal />
  </div>
</template>

<script>
import store from "@/store";
import BottomBar from "./BottomBar.vue";
import SideBar from "./SideBar.vue";
import SideBarRight from "./SideBarRight.vue";
import TabsComponent from "./TabsComponent.vue";
import LayoutModal from "./widgets/LayoutModal.vue";
import AlarmActionModal from "./widgets/AlarmActionModal.vue";
import SubsystemActionModal from "./widgets/SubsystemActionModal";
import ScriptsModal from "./widgets/ScriptsModal";
import KeyboardEvents from "./KeyboardEvents.vue";
import interact from "interactjs";
import LayoutApi from "@/libs/LayoutApi";
import FloatingContainer from "./widgets/FloatingContainerWidget.vue";
import ImageModal from "./widgets/ImageModal.vue";
import ConfigVideowall from "@/libs/ConfigVideowall";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";

const configVideowall = new ConfigVideowall(store);

export default {
  components: {
    SideBar,
    SideBarRight,
    BottomBar,
    TabsComponent,
    LayoutModal,
    AlarmActionModal,
    SubsystemActionModal,
    KeyboardEvents,
    SubsystemActionModal,
    ScriptsModal,
    FloatingContainer,
    ImageModal,
  },
  data() {
    return {
      isDraggable: true,
      isResizable: true,
    };
  },
  mounted() {
    interact("#side-bar").resizable({
      edges: { right: true },
      listeners: {
        move: this.updateSidebarWidth,
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        interact.modifiers.restrictSize({
          min: { width: 300 },
          max: { width: 480 },
        }),
      ],

      inertia: true,
    });

    interact("#side-bar-right").resizable({
      edges: { left: true },
      listeners: {
        move: this.updateSidebarRightWidth,
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        interact.modifiers.restrictSize({
          min: { width: 300 },
          max: { width: 800 },
        }),
      ],

      inertia: true,
    });

    store.dispatch("log_incident/getAll");

    setTimeout(() => {
      store.dispatch("grid/setAlarmsWS");
      store.dispatch("grid/setShowUserViewWS");
      store.dispatch("grid/setActionPlanWS");
      store.dispatch("grid/setUserSetUp");
    }, 10000);

    if (store.state.user.user.device_interphone) {
      store.dispatch("sip/subscribeToCalls", { user_id: store.state.user.user.id });
      setTimeout(() => {
        store.dispatch("sip/subscribeToCalls", { user_id: store.state.user.user.id });
      }, 10000);
    }

    if (this.user.video_wall == 1) {
      this.handleVideoWallConnections();
    }
  },
  beforeDestroy() {
    if (this.user.video_wall == 1) {
      window.Echo.leave(`video-wall-users`);
    }
  },
  computed: {
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return this.tabs ? Object.keys(this.tabs).length : 0;
    },
    slotData() {
      return store.state.grid.slotData;
    },
    isToolbarsCollapsed() {
      return store.state.psimConfig.isToolbarsCollapsed;
    },
    isToolbarsCollapsedRight() {
      return store.state.psimConfig.isToolbarsCollapsedRight;
    },
    sidebarWidth() {
      if (this.user.video_wall === 1) {
        return 0;
      } else {
        return store.state.grid.sidebarWidth;
      }
    },
    sidebarRightWidth() {
      if (this.user.video_wall === 1) {
        return 0;
      } else {
        return store.state.grid.sidebarRightWidth;
      }
    },
    containerCenterWith() {
      if (this.user.video_wall === 1) {
        return `100vw`;
      } else {
        let pixels = 0;
        if (!this.isToolbarsCollapsed) {
          pixels += store.state.grid.sidebarWidth;
        }
        if (!this.isToolbarsCollapsedRight) {
          pixels += store.state.grid.sidebarRightWidth;
        }
        return `calc(100vw - ${pixels}px)`;
      }
    },
    user() {
      return store.getters["user/getUser"];
    },
    floatVideos() {
      return store.getters["grid/getFloatVideos"];
    },
    slotDataWillOpenATab() {
      if (this.tabsQty > 0) {
        return false;
      }
      return store.getters["grid/slotDataWillOpenATab"];
    },
  },
  methods: {
    updateToolbarsStatus() {
      store.commit(
        "psimConfig/UPDATE_TOOLBARS_COLLAPSED",
        !store.state.psimConfig.isToolbarsCollapsed
      );

      this.sidebarWidth;
    },
    updateToolbarsStatusRight() {
      store.commit(
        "psimConfig/UPDATE_TOOLBARS_COLLAPSED_RIGHT",
        !store.state.psimConfig.isToolbarsCollapsedRight
      );
    },
    updateSidebarWidth(event) {
      store.commit("grid/updateSidebarWidth", event.rect.width);
      store.dispatch("grid/updateUserConfig");
    },
    updateSidebarRightWidth(event) {
      store.commit("grid/updateSidebarRightWidth", event.rect.width);
      store.dispatch("grid/updateUserConfig");
    },
    async handleVideoWallConnections() {
      const layoutApi = new LayoutApi(store);
      await store.dispatch("layouts/getUserLayouts");
      await store.dispatch("video_wall/updateVideoWallUserStatus", {
        id_user: this.user.id,
        status: "online",
      });

      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log("video wall ws:", channel, data);
      });

      if (!window.Echo.connector.socket.connected) window.Echo.connect();

      window.Echo.join("video-wall-users");

      // Video wall websocket
      window.Echo.private(`video-wall-users-${this.user.id}`).listen(
        ".videoWall",
        (data) => {
          try {
            const tab = data.message.tab;
            const slots = tab.data.slots;
            const idLayout = tab.data.layout;

            configVideowall.setTab(slots, idLayout);
          } catch (error) {
            console.log(`Websocket video-wall-users-${this.user.id}`, error);
          }
        }
      );
    },
    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e) {
      e.preventDefault();

      if (this.slotData && this.slotData.source === SOURCES.SUBSYSTEM_TREE_DND) {
        store.dispatch(this.slotData.type, {
          data: this.slotData.data,
          source: SOURCES.SUBSYSTEM_TREE,
        });
      }
    },
  },
};
</script>

<style>
.without-footer {
  padding-bottom: 0 !important;
}
</style>
