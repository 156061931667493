import LayoutApi from "@/libs/LayoutApi";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";

export default function (store) {
  const user = store.getters["user/getUser"];

  window.Echo.connector.socket.on("subscription_error", (channel, data) => {
    console.log("websockets:setRoundCommandStatus", channel, data);
  });

  if (!window.Echo.connector.socket.connected) window.Echo.connect();

  window.Echo.private(`camera-round-${user.id}`).listen(".update", async (wsData) => {

    const data = wsData.message;
    const layoutId = data.id_layout;
    const loop = data.loop;
    const layoutApi = new LayoutApi(store);
    const layout = layoutApi.getLayoutDataById(layoutId);
    const newTab = await layoutApi.addNewTab('Ronda', layoutId);

    layoutApi.setActiveTab(newTab.id);

    if (!newTab.status) return;

    let slotPos = 1;
    let index = 1;
    let paused = false;

    const cameras = data.cameras;

    // First camera ------
    const cam = cameras[0];
    const slotInfo = {
      name: `cam_${cam.id}`,
      position: slotPos++,
      data: { camera: cam },
      slotType: SLOT_TYPES.CAM_LIVE
    };

    layoutApi.setSlot(
      slotInfo.name,
      slotInfo.position,
      slotInfo.data,
      slotInfo.slotType
    );
    // -------------------

    store.dispatch('round_command/setRoundCommandStatus', true);
    store.dispatch('round_command/setTabId', newTab.id);

    const intervalId = setInterval(async () => {

      paused = layoutApi.getCurrentTabId() !== newTab.id;
      store.dispatch('round_command/setPaused', paused);

      if (!layoutApi.checkIfTabExists(newTab.id)) {
        clearInterval(intervalId);
        store.dispatch('round_command/reset');
      }

      if (paused) return;

      if (index < cameras.length) {

        if (slotPos > layout.grid.length) slotPos = 1;

        const ignoredSlot = parseInt(store.getters['round_command/getIgnoredSlot']);
        const cam = cameras[index];

        const slotInfo = {
          name: `cam_${cam.id}`,
          position: slotPos,
          data: { camera: cam },
          slotType: SLOT_TYPES.CAM_LIVE
        };

        if (ignoredSlot !== slotPos) {
          slotInfo.position = slotPos;
        } else {
          slotInfo.position = ++slotPos;
        }

        layoutApi.setSlot(
          slotInfo.name,
          slotInfo.position,
          slotInfo.data,
          slotInfo.slotType
        );

        slotPos++;
        index++;

        if (loop && index >= cameras.length) {
          index = 0;
          slotPos = 1;
        };

      } else {
        clearInterval(intervalId);
        store.dispatch('round_command/reset');
      }
    }, data.delay * 1000);

  });
}
