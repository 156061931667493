<template>
  <div class="timeline">
    <Timeline
      ref="timeline"
      :camera="cameraData"
      :slot-width="slotWidth"
      :tab-info="tabInfo"
      :force-update-current-time="forceUpdateCurrentTime"
      :synced-cameras-status.sync="syncedCamerasStatus"
      @sync-cameras="onSyncCameras"
      @last-request="onLastRequest"
      :source="source"
      :filters="filters"
    />
  </div>
</template>

<script>
// TODO: Drop this component and let only Timeline component

import Timeline from "./components/Timeline.vue";
import store from "@/store";

export default {
  components: {
    Timeline,
  },
  props: {
    accessToken: {
      type: String,
      required: false,
    },
    cameraData: {
      type: Object,
    },
    groupId: {
      type: String,
    },
    slotWidth: {
      type: Number | String,
    },
    forceUpdateCurrentTime: {
      type: Number,
      required: false,
    },
    tabInfo: {
      type: Object,
    },
    syncedCamerasStatus: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'grid'
    },
    filters: {
      type: Object,
      default: null
    }
  },

  created() {
    this.initApp();
  },

  computed: {
    camera() {
      return store.state.timeline.camera;
    },
    token() {
      return store.state.timeline.token;
    },
  },

  methods: {
    async initApp() {
      store.commit("timeline/setCamera", this.cameraData);
      if (this.groupId) {
        store.commit("timeline/setGroupId", this.groupId);
      }
    },
    onSyncCameras(action) {
      this.$emit("sync-cameras", action);
    },
    onLastRequest(evt) {
      this.$emit("last-request", evt);
    },
    fetchAndAddAlarms(camera_proxy_id) {
      this.$refs.timeline.fetchAndAddAlarms(camera_proxy_id);
    },
  },
};
</script>
