import axios from "@axios";
import LayoutApi from "./LayoutApi";

export default class {
  constructor(store) {
    this.layoutApi = new LayoutApi(store);
  }

  setTab(slots, idLayout) {
    const currentTabId = this.layoutApi.getCurrentTabId();
    this.layoutApi.removeTab(currentTabId);
    axios
      .get(`v1/layout/${idLayout}`)
      .then((response) => {
        const layout = response.data.data.data;
        slots.forEach((objA) => {
          const indexB = layout.findIndex((objB) => objB.pos === objA.pos);
          if (indexB !== -1) {
            layout[indexB] = objA;
          }
        });

        this.insertLayout(layout);
      })
      .catch((error) => {
        console.log("v1/video-wall/view-by-user-id", error);
      });
  }

  async insertLayout(data) {
    const newTab = await this.layoutApi.addNewTab("", null, {
      id: "id_100001", // <-- Puede ser cualquier cosa
      layout: "standalone", // <-- Esto se debe quedar así
      name: "Layout", // <-- El nombre de la tab
      slots: data, // <-- Aquí va el json con los slots, en la tabla de layouts el campo se llama 'data'
      type: "video_wall", // <-- Esto se queda así
    });
    this.layoutApi.setActiveTab(newTab.id);
  }
}
