<template>
    <video ref="video" id="video-player" :src="src" preload="auto" controls muted></video>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        src: {
            type: String,
        },
        firstVideo: {
            type: Boolean,
            default: false,
        },
        timelineCurrentTime: {
            type: String | Object,
        },
        recording: {
            type: Object,
        },
    },
    data() {
        return {
            duration: 0,
            currentTime: 0,
            percentage: 0,
            remainTime: 0,
        };
    },
    mounted() {
        this.attachVideoEvents();
    },
    methods: {
        initFirstVideo() {
            this.$refs.video.currentTime = this.duration - 5 * 60;
            this.$refs.video.play();
        },
        configFirstVideo() {
            const videoDuration = this.duration;
            this.$refs.video.currentTime = videoDuration - 5000;
        },
        attachVideoEvents() {
            this.$refs.video.addEventListener('loadstart', () => this.loadStart());
            this.$refs.video.addEventListener('loadedmetadata', () => this.loadedMetaData());
            this.$refs.video.addEventListener('timeupdate', () => this.onTimeUpdate());
            this.$refs.video.addEventListener('canplay', () => this.canPlay());
            this.$refs.video.addEventListener('ended', () => this.onVideoEnds());
        },
        detachVideoEvents() {
            this.$refs.video.addEventListener('loadstart', () => this.loadStart());
            this.$refs.video.removeEventListener('loadedmetadata', () => this.loadedMetaData());
            this.$refs.video.removeEventListener('timeupdate', () => this.onTimeUpdate());
            this.$refs.video.removeEventListener('canplay', () => this.canPlay());
            this.$refs.video.removeEventListener('ended', () => this.onVideoEnds());
        },
        loadStart() {
            this.$emit('loadstart');
        },
        loadedMetaData() {
            if (this.$refs.video) {
                this.duration = this.$refs.video.duration;
                if (this.firstVideo) {
                    this.initFirstVideo();
                } else {
                    this.setCurrentTime();
                }
            }

            this.$emit('loadedmetadata');
        },
        onTimeUpdate() {
            if (this.$refs.video) {
                this.currentTime = this.$refs.video.currentTime;
                this.percentage = Math.floor((this.currentTime * 100) / this.duration);
                this.remainTime = this.$refs.video.duration - this.$refs.video.currentTime;

                const recordingStart = this.recording.start;
                const recordingEnd = this.recording.end;
                const remainTime =
                    recordingStart + (this.currentTime / this.duration) * (recordingEnd - recordingStart);

                if (!Number.isNaN(remainTime)) {
                    this.$emit('timeupdate', moment(remainTime).format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        },
        canPlay() {
            this.$emit('canplay');
        },
        onVideoEnds() {},
        setCurrentTime() {
            if (this.duration > 0 && this.recording.start && this.recording.end) {
                try {
                    const recordingStart = this.recording.start;
                    const recordingEnd = this.recording.end;
                    const percentage =
                        (this.timelineCurrentTime.valueOf() - recordingStart) / (recordingEnd - recordingStart);

                    if (percentage >= 0) {
                        const videoCurentTime = this.duration * percentage;
                        this.$refs.video.currentTime = videoCurentTime;
                    }
                } catch (error) {
                    console.log('=== Video::setCurrentTime ===');
                    console.log(error);
                }
            }
        },
        play() {
            if (this.$refs.video.src.indexOf('null') === -1) {
                this.$refs.video.play();
            }
        },
        pause() {
            this.$refs.video.pause();
        },
    },
};
</script>

<style></style>
