<template>
  <div class="info-slot-container">
    <alarms-table
      v-if="data && data.model === 'Alarm' && filters"
      :filters="filters"
      :show-filters="false"
      :key="key"
    />

    <cameras-table
      v-if="data && data.model === 'Camera' && filters"
      :filters="filters"
      :show-filters="false"
      :key="key"
    />

    <tenants-table
      v-if="data && data.model === 'Tenant' && filters"
      :filters="filters"
      :show-filters="false"
      :key="key"
    />

    <analytics-table
      v-if="data && data.model === 'AnalyticRecord' && filters"
      :filters="filters"
      :show-filters="false"
      :key="key"
      :isLoadedChart="true"
    />

    <detection-event-table
      v-if="data && data.model === 'DetectionEvent' && filters"
      :filters="filters"
      :show-filters="false"
      :key="key"
      :isLoadedChart="true"
    />

    <cameras-proxy-table
      v-if="data && data.model === 'CamerasProxy' && filters"
      :filters="filters"
      :show-filters="false"
      :key="key"
    />
  </div>
</template>

<script>
import AlarmsTable from "@/views/alarm/Index.vue";
import CamerasTable from "@/views/camera_manager/camera/Index.vue";
import TenantsTable from "@/views/tenant/Index.vue";
import AnalyticsTable from "@/views/intelexvision/analytic_record/Index.vue";
import DetectionEventTable from "@/views/detection_event/Index.vue";
import CamerasProxyTable from "@/views/camera/Index.vue";
import { EventBus } from "@/libs/event-bus";

export default {
  components: {
    AlarmsTable,
    CamerasTable,
    TenantsTable,
    AnalyticsTable,
    DetectionEventTable,
    CamerasProxyTable,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      key: 1,
      filters: null,
    };
  },
  mounted() {
    this.filters = this.data.filters;
    EventBus.on(`chart_${this.data.chart_id}:filters-updated`, this.onFiltersUpdated);
  },
  beforeDestroy() {
    EventBus.off(`chart_${this.data.chart_id}:filters-updated`);
  },
  methods: {
    onFiltersUpdated(evt) {
      this.filters = evt.filters;
      this.key = evt.ts;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-slot-container {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
