<template>
  <div
    class="alarms-widget widget bg-white overflow-x-hidden border-bottom"
    style="position: relative"
  >
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">
            {{ title }}
            <template v-if="meta.total"> ({{ meta.total }}) </template>
          </p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
              size="24"
            />
          </template>
          <span
            class="handle cursor-move"
            v-if="isConfigModeOn"
            :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
          >
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
        <!-- ============== Search bar ============== -->
        <div :class="['search-bar', { expanded: searchBarEnabled }]">
          <div
            ref="searchBarContainer"
            class="search-bar-container d-flex align-items-center"
          >
            <feather-icon
              icon="SearchIcon"
              class="mx-25 cursor-pointer"
              size="24"
              @click="toggleSearchBar"
            />
            <input
              type="text"
              v-model="searchQuery"
              class="form-control"
              :placeholder="$t('grid.widgets.alarms.search.placeholder')"
              @input="onTypeSearch"
            />
            <feather-icon
              icon="XIcon"
              class="mx-25 cursor-pointer"
              size="18"
              @click="toggleSearchBar"
            />
          </div>
        </div>
        <!-- ======================================== -->
      </div>
    </div>
    <b-collapse
      :id="`main-accordion-${id}`"
      :accordion="`main-accordion-${id}`"
      role="tabpanel"
      v-model="isCollapseOpen"
      @input="onUpdateStatus"
    >
      <!-- ============== Data table ============== -->

      <table-component
        :items="items"
        :location="location"
        :filters="filters"
        :busy="isBusy"
      />

      <!-- ======================================== -->

      <div class="d-flex justify-content-end align-content-center mt-0">
        <div class="d-flex align-items-center mr-1 pr-1 border-right">
          <span>{{ $t("grid.widgets.alarms.pagination.showing") }}</span>
          <select class="mx-50" @input="onChangeRecordesPerPage">
            <option v-for="rpp in recordsPerPage" :key="rpp" :value="rpp">
              {{ rpp }}
            </option>
          </select>
          <span>{{ $t("grid.widgets.alarms.pagination.records") }}</span>
        </div>

        <div
          class="pagination-count d-flex align-items-center mr-25"
          v-if="this.sidebarWidth > 480"
        >
          {{ $t("grid.widgets.alarms.pagination.showing") }}
          {{ items.length }} {{ $t("grid.widgets.alarms.pagination.of") }}
          {{ meta.total }} ({{ $t("grid.widgets.alarms.pagination.page") }}
          {{ meta.current_page }}
          {{ $t("grid.widgets.alarms.pagination.of") }} {{ meta.last_page }})
        </div>
        <div class="pagination d-flex justify-content-end">
          <a
            href="#"
            :class="[
              'btn btn-sm btn-secondary px-50 rounded-0',
              { disabled: !pagination.prev },
            ]"
            @click="clickPaginationBtn('prev')"
          >
            <feather-icon icon="ChevronLeftIcon" size="16" />
          </a>
          <a
            href="#"
            :class="[
              'btn btn-sm btn-secondary px-50 rounded-0',
              { disabled: !pagination.next },
            ]"
            @click="clickPaginationBtn('next')"
          >
            <feather-icon icon="ChevronRightIcon" size="16" />
          </a>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import store from "@/store";
import { debounce } from "lodash";
import TableComponent from "./TableComponent.vue";
import axios from "axios";

export default {
  components: {
    BCollapse,
    TableComponent,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      required: true,
      type: Number | String,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    parentCollapsed: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      recordsPerPage: [5, 10, 25, 50, 100],
      defaultFilters: {
        max: 5,
      },
      extraFilters: {
        q: null,
      },
      pagination: {
        first: null,
        last: null,
        next: null,
        prev: null,
      },
      meta: {},
      isBusy: false,
      dataModal: {
        id: this.title,
        data: {},
      },
      isCollapseOpen: false,
      searchQuery: "",
      searchBarEnabled: false,
      source: axios.CancelToken.source(),
    };
  },
  created() {
    store.dispatch("grid/loadVisited");
  },
  mounted() {
    this.fetchData();
    this.isCollapseOpen = this.isOpen;
    this.$refs.searchBarContainer.style.width = `${this.$el.offsetWidth - 20}px`;
  },
  computed: {
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
    sidebarWidth() {
      if (this.location === "sideBarRigth") {
        return store.state.grid.sidebarRightWidth;
      }

      return store.state.grid.sidebarWidth;
    },
  },
  watch: {
    "$store.state.grid.alarmsTS"() {
      this.fetchData();
    },
    sidebarWidth() {
      this.$refs.searchBarContainer.style.width = `${this.$el.offsetWidth - 20}px`;
    },
    parentCollapsed() {
      if (this.location === "sideBarRigth") {
        this.$refs.searchBarContainer.style.width = `${this.$el.offsetWidth - 20}px`;
      }
    }
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    fetchData(url) {
      this.source.cancel();
      const params = url
        ? {}
        : {
            ...this.defaultFilters,
            ...this.filters,
            ...this.extraFilters,
          };
      this.source = axios.CancelToken.source();
      this.isBusy = true;
      return axios
        .get(url ? url : `${process.env.VUE_APP_MS_ALARMS_URL}`, {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Host": process.env.VUE_APP_BASE_URL,
          },
          cancelToken: this.source.token,
        })
        .then((result) => {
          this.items = result.data.data;
          this.meta = result.data.meta;
          this.pagination = result.data.links;
          // this.formatPaginationUrls();
          this.isBusy = false;
        });
    },
    // formatPaginationUrls() {
    //   for (const key in this.pagination) {
    //     if (this.pagination[key]) {
    //       this.pagination[key] = this.pagination[key].replace(
    //         this.$http.defaults.baseURL,
    //         ""
    //       );
    //     }
    //   }
    // },
    clickPaginationBtn(direction) {
      if (direction === "prev") {
        this.fetchData(this.pagination.prev);
      } else {
        this.fetchData(this.pagination.next);
      }
    },
    onChangeRecordesPerPage(val) {
      const value = val.target.value;
      this.defaultFilters.max = value;
      this.fetchData();
    },
    toggleSearchBar() {
      if (!this.searchBarEnabled) {
        this.$refs.searchBarContainer.querySelector("input").focus();
        this.searchBarEnabled = true;
      } else {
        this.searchBarEnabled = false;
        this.resetSearchBar();
      }
      this.isCollapseOpen = true;
    },
    resetSearchBar() {
      this.extraFilters.q = null;
      if (this.searchQuery !== "") {
        this.fetchData();
      }
      this.searchQuery = "";
    },
    onTypeSearch: debounce(function () {
      this.extraFilters.q = this.searchQuery;
      this.fetchData();
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.alarms-widget .table.table-sm td {
  padding: 0 0.5rem !important;
  max-height: 70px;
}
</style>
