<template>
  <div class="chat-slot h-100">
    <!-- Main Area -->
    <section class="chat-app-window h-100">

      <!-- Start Chat Logo -->
      <div v-if="chat.messages.lenght" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">
          {{$t("chat.start_conversation")}} 
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
              </div>

              <div v-if="chat.roomId" class="d-flex align-items-center">
                <b-avatar icon="people-fill" size="35" class="mr-1 cursor-pointer badge-minimal" badge
                  badge-variant='success' variant="primary"
                  @click.native="shallShowActiveChatContactSidebar = true" />
                <h6 class="mb-0">
                  {{ `${chat.name}` }}
                </h6>
              </div>
              <div v-if="!chat.roomId" class="d-flex align-items-center">
                <b-avatar size="35" :src="chat.image" class="mr-1 cursor-pointer badge-minimal" badge
                  :badge-variant="chat.online ? 'success' : 'danger'" variant="primary"
                  @click.native="shallShowActiveChatContactSidebar = true" />
                <h6 class="mb-0">
                  {{ `${chat.name} ${chat.first_name} ${chat.second_name}` }}
                </h6>
              </div>
            </div>

            <!-- Contact Actions -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon icon="PhoneCallIcon" size="17" class="cursor-pointer d-sm-block d-none mr-1" />
              <feather-icon icon="VideoIcon" size="17" class="cursor-pointer d-sm-block d-none mr-1" />
              <feather-icon icon="SearchIcon" size="17" class="cursor-pointer d-sm-block d-none mr-50" />
              <div class="dropdown">
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div> -->
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <chat-log :chat-data.sync="chat" :key="`chat-log-${chat.id}-${uuid}`" />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input v-model="chatInputMessage" placeholder="Enter your message" />
          </b-input-group>
          <b-button variant="primary" type="submit">
            {{$t("chat.send")}} 
          </b-button>
        </b-form>
      </div>
    </section>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from './ChatLog.vue'
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import axios from '@axios'
import { v4 as uuidv4 } from 'uuid'
import { EventBus } from "@/libs/event-bus";

export default {
  mixins: [layoutMixin],
  components: {
    BFormInput,
    BButton,
    BFormGroup,
    VuePerfectScrollbar,
    ChatLog,
  },
  props: {
    chat: {
      type: Object,
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    let uuid = ref(uuidv4())

    props.chat.messages = []

    const chatInputMessage = ref('')

    if(props.chat.roomId){
      axios.get(`/v1/services/chat/chat-group/getGroupMessages/${props.chat.roomId}`)
      .then(({ data: response }) => {

        props.chat.messages = response.data.reverse();
        uuid.value = uuidv4()
        nextTick(() => { scrollToBottomInChatLog() })
      });
    }
    else{
      axios.get(`/v1/services/chat/chat/${props.chat.id}`)
      .then(({ data: response }) => {
        props.chat.messages = response.data.reverse();
        uuid.value = uuidv4()
        nextTick(() => { scrollToBottomInChatLog() })
      });
    }

    const sendMessage = () => {
      if (!chatInputMessage.value) return

      let payload = {
        user_id: props.chat.id,
        message: chatInputMessage.value,
      }

      if(props.chat.roomId){
        payload.group_id = props.chat.roomId;
      }

      axios.post("/v1/services/chat/chat", payload)
        .then(response => {
          // // Reset send message input value
          chatInputMessage.value = ''

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })
    }

    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    if(props.chat.roomId){
      EventBus.on(`new-message-${props.chat.roomId}`, (data) => {
        props.chat.messages.push(data)
        uuid.value = uuidv4()
        nextTick(() => { scrollToBottomInChatLog() })
      });
    }
    else{
      EventBus.on(`new-message-${props.chat.room_id}`, (data) => {
        props.chat.messages.push(data)
        uuid.value = uuidv4()
        nextTick(() => { scrollToBottomInChatLog() })
      });
    }

    onUnmounted(() => {
      EventBus.off(`new-message-${props.chat.room_id}`);
    })

    return {
      perfectScrollbarSettings,
      sendMessage,
      chatInputMessage,
      uuid,
      refChatLogPS,
    }
  }
};
</script>