<template>
  <div>
    <feather-icon
      :id="`det-event-button-${alarmData.id}`"
      icon="EyeIcon"
      size="18"
    />
    <b-popover
      :target="`det-event-button-${alarmData.id}`"
      placement="right"
      triggers="hover"
      boundary="window"
    >
      <carrousel-detection-event
        :idAlarm="alarmData.id"
        :detEvent="alarmData.detection_event_data"
      />
    </b-popover>
  </div>
</template>

<script>
import { VBPopover } from "bootstrap-vue";
import CarrouselDetectionEvent from "./CarrouselDetectionEvent.vue";

export default {
  props: {
    alarmData: Object,
  },
  components: {
    CarrouselDetectionEvent,
  },
  directives: {
    "b-popover": VBPopover,
  },
};
</script>

<style></style>
