<template>
  <div class="pages widget bg-white border-bottom">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center text-capitalize">{{ $tc("pages.title", 2) }}</p>
          <template v-if="collapsible">
            <feather-icon
                v-if="!isConfigModeOn"
                :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="24"
            />
          </template>
          <span class="handle cursor-move" v-if="isConfigModeOn">
              <feather-icon icon="MenuIcon" size="24"/>
            </span>
        </div>
      </div>
      <b-collapse
          :id="`main-accordion-${id}`"
          :accordion="`main-accordion-${id}`"
          role="tabpanel"
          :visible="false"
          v-model="isCollapseOpen"
          @input="onUpdateStatus"
      >
        <div class="widgets-container d-flex flex-wrap justify-content-around">
          <b-table
              class="mb-0 alarms-widget__data-table"
              :items="items"
              :fields="fields"
              primary-key="id"
              :busy="busy"
              show-empty
              small
              :empty-text="$t('grid.widgets.alarms.table.no_data')"
              responsive
          >
            <template #head(description)="data">
              <span> {{ $t(data.label) }}</span>
            </template>
            <template #cell(actions)="row">
              <a href="#" @click.prevent="loadLink(row)">
                <feather-icon icon="LinkIcon" size="18"/>
              </a>
            </template>
          </b-table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {BCollapse, VBToggle, BTable} from "bootstrap-vue";
import {SLOT_TYPES} from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import store from "@/store";

export default {
  mixins: [layoutMixin],
  components: {
    BCollapse,
    BTable,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
    selectedLayout: {
      type: String | Number,
      default: null,
    },
  },
  data() {
    return {
      isCollapseOpen: true,
      busy: false,
      fields: [
        {
          key: "description",
          label: "pages.description",
          minSize: 300,
        },
        {key: "actions", label: "", minSize: 300},
      ],
    };
  },
  mounted() {
    this.isCollapseOpen = this.isOpen;
    store.dispatch("pages/getAll");
  },
  computed: {
    currentTab() {
      return store.getters["grid/currentTab"] ? store.getters["grid/currentTab"] : {};
    },
    items() {
      return store.getters["pages/getItems"];
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return Object.keys(this.tabs).length;
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
  
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    async loadLink(row) {
      this.setSlotData(
          SLOT_TYPES.FRAME,
          `Page`,
          {
            url: row.item.url,
            load: false,
            save: false,
            id: row.item.id,
            description: row.item.description
          },
      );
    },
  },
};
</script>