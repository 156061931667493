<template>
  <div>
    <grid-view class="grid-view position-relative" />
  </div>
</template>

<script>
import GridView from "@/views/components/GridView";
import store from "@/store";

import setRoundCommand from "@/websockets/setRoundCommand";
import useAppConfig from '@core/app-config/useAppConfig';

export default {
  components: {
    GridView,
  },
  created() {
    setTimeout(() => {
      setRoundCommand(store);
    }, 2000)

  },
  mounted() {
    const { skin } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") {
      document.body.classList.add("dark-layout");
    }
  },
  setup() {
    const {skin } = useAppConfig();

    return {
      skin,
    };
  },
};
</script>