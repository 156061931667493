<template>
  <div>
    <div :id="`charts-sdk-${id}`"></div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import OmniviewChartsSdk from "@omnicloud/charts-sdk";
import layoutMixin from "@/mixins/layoutMixin.js";
import { SLOT_TYPES } from "@/config/layoutConfig";
import { EventBus } from "@/libs/event-bus";
import LayoutApi from "@/libs/LayoutApi.js";
import store from "@/store";

const layoutApi = new LayoutApi(store);

export default {
  mixins: [layoutMixin],
  props: {
    data: Object,
    tabInfo: Object,
  },
  data() {
    const id = uuidv4();

    return {
      id,
      chartId: null,
      el: null,
      chartsSdk: null,
    };
  },
  mounted() {
    this.id = this.data.chartData?.id || this.id;
    this.chartId = `charts-sdk-${this.id}`;
    this.$nextTick(() => {
      this.chartsSdk = new OmniviewChartsSdk(`#${this.chartId}`, {
        token: window.localStorage.accessToken,
        apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
        eventMsUrl: `${process.env.VUE_APP_MS_EVENTS_URL}`,
        chartId: this.data ? this.data.chartId : null,
        chartData: this.data && this.data.chartData ? this.data.chartData : null,
        gmapsKey: process.env.VUE_APP_GMAPS_KEY,
        locale: this.$i18n.locale,
        darkMode: this.skin == "dark" ? true : false,
      });
      this.chartsSdk.init();

      this.el = document.querySelector(`.${this.chartId}`);
      this.el.addEventListener("share-data", this.onShareData);
      this.el.addEventListener("filters-updated", this.onFiltersUpdated);
      this.el.addEventListener("action-performed", this.onActionPerformed);
      this.el.addEventListener("chart-config-updated", this.onChartConfigUpdated);

      EventBus.on(`toogle-dark-mode`, (params) => {
        if (params == "dark") {
          this.chartsSdk.setDarkMode();
        } else {
          this.chartsSdk.setLightMode();
        }
      });
    });
  },
  beforeDestroy() {
    if (this.el) {
      this.el.removeEventListener("share-data", this.onShareData);
      this.el.removeEventListener("filters-updated", this.onFiltersUpdated);
      this.el.removeEventListener("action-performed", this.onActionPerformed);
      this.el.removeEventListener("chart-config-updated", this.onChartConfigUpdated);
    }

    if (this.chartsSdk) this.chartsSdk.destroy();
  },
  computed: {
    skin() {
      return store.state.appConfig.layout.skin;
    },
  },
  methods: {
    onShareData(evt) {
      setTimeout(() => {
        this.setSlotData(SLOT_TYPES.CHARTS_SDK_DATA, "CHART SDK DATA", {
          chart_id: this.id,
          ...evt.detail.data,
        });
      }, 200);
    },
    onFiltersUpdated(evt) {
      EventBus.emit(`chart_${this.id}:filters-updated`, {
        ts: new Date().getTime(),
        ...evt.detail.data,
      });
    },
    onActionPerformed(evt) {
      EventBus.emit(`charts:action-performed`, {
        ...evt.detail,
      });
    },
    onChartConfigUpdated(evt) {
      const data = Object.assign({ ...evt.detail.data }, { id: this.id });
      layoutApi.updateSlotData(this.tabInfo.pos, SLOT_TYPES.CHARTS_SDK, {
        chartData: data,
      });
      store.dispatch("grid/updateUserConfig");
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-sdk-container {
  max-height: 100%;
  overflow: auto;
}
</style>
