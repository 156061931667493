<template>
  <div class="timeline-slot">
    <Timeline
      ref="timeline"
      :camera-data="cameraData"
      :key="`${tabInfo.id}-${tmkey}`"
      :tab-info="tabInfo"
      :group-id="tabInfo.id"
      :slot-width="slotWidth"
      :force-update-current-time="forceUpdateCurrentTime"
      :synced-cameras-status.sync="syncedCamerasStatus"
      @sync-cameras="onSyncCameras"
      @last-request="onLastRequest"
      :source="source"
      :filters="filtersData"
    />
  </div>
</template>

<script>
import { EventBus } from "@/libs/event-bus";
import store from "@/store";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import Timeline from "@/views/components/Timeline";
import vSelect from "vue-select";
import { debounce } from "lodash";
import moment from "moment";
import Config from "@/views/components/Timeline/Config";
import LayoutApi from "@/libs/LayoutApi";

const layoutApi = new LayoutApi(store);

export default {
  components: { Timeline, vSelect },
  props: {
    tabInfo: Object,
    source: {
      type: String,
      default: "grid",
    },
    filters: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cameraData: {},
      currentMainCameraId: null,
      camerasToSync: [],
      syncedCameras: [],
      syncedCamerasStatus: false,
      resizeOb: null,
      slotRect: null,
      lastRequest: null,
      forceUpdateCurrentTime: 0,
      tmkey: 1,
      filtersData: null,
      slotData: null,
    };
  },
  mounted() {
    this.slotData = layoutApi.getSlotData(this.tabInfo.pos);

    this.resizeOb = new ResizeObserver((entries) => {
      this.slotRect = entries[0].contentRect;
    });
    this.resizeOb.observe(this.$el);

    EventBus.on(`timeline:${this.tabInfo.id}:ready`, this.cameraReady);
    EventBus.on(
      `timeline:${this.tabInfo.id}:sidebar:set_main_camera`,
      this.cameraAlarmSidebar
    );

    EventBus.on(`timeline:${this.tabInfo.id}:set_main_camera`, this.setMainCamera);
    EventBus.on(
      `timeline:${this.tabInfo.id}:set_secondary_camera`,
      this.setSecondaryCamera
    );
    EventBus.on(`timeline:${this.tabInfo.id}:reset_camera`, (data) =>
      this.resetTimelineModeOnCamera(data.cameraId)
    );

    EventBus.on(`timeline:${this.tabInfo.id}:camera_ready`, () =>
      this.setTimelineFromSubsystemTree()
    );

    if (this.slotData && this.slotData.data.in_app_source === SOURCES.ALARM_VIEW) {
      setTimeout(() => this.syncCameras(), 500);
    }

    if (this.slotData && !["vxg", 'camera_manager'].includes(this.slotData.data.camera.camera_type)) {
      this.setTimelineFromSubsystemTree();
    }

    if (this.filters) {
      this.filtersData = this.filters;
    }
  },
  beforeDestroy() {
    // this.removeAllCamerasFromSyncedCameras();
    // this.disableTimelineModeOnCamera(this.currentMainCameraId);

    this.availableCameras.forEach((cameraData) => {
      const delayedTime =
        cameraData.data && cameraData.data.delayed_record_minutes
          ? cameraData.data.delayed_record_minutes
          : "00:05:00";

      EventBus.emit(`timeline:${this.tabInfo.id}:${cameraData.data.camera_id}:disabled`, {
        cameraId: cameraData.data.camera_id,
        start: moment()
          .subtract(moment.duration(delayedTime))
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    });

    this.resizeOb.unobserve(this.$el);

    EventBus.off(`timeline:${this.tabInfo.id}:ready`, this.cameraReady);

    EventBus.off(
      `timeline:${this.tabInfo.id}:sidebar:set_main_camera`,
      this.cameraAlarmSidebar
    );

    EventBus.off(`timeline:${this.tabInfo.id}:set_main_camera`, this.setMainCamera);

    EventBus.off(
      `timeline:${this.tabInfo.id}:set_secondary_camera`,
      this.setSecondaryCamera
    );

    EventBus.off(`timeline:${this.tabInfo.id}:reset_camera`, (data) =>
      this.resetTimelineModeOnCamera(data.cameraId)
    );

    EventBus.off(`timeline:${this.tabInfo.id}:camera_ready`, () =>
      this.setTimelineFromSubsystemTree()
    );
  },
  watch: {
    syncedCameras(val) {
      this.syncedCamerasStatus = !(val.length === 0);
    },
  },
  computed: {
    slotWidth() {
      return this.slotRect ? this.slotRect.width : 0;
    },
    tabSlots() {
      return this.tabInfo && store.state.grid.tabs[this.tabInfo.id]
        ? store.state.grid.tabs[this.tabInfo.id].slots
        : [];
    },
    currentTab() {
      return this.tabInfo ? store.state.grid.tabs[this.tabInfo.id] : "";
    },
    availableCameras() {
      const slots = this.tabSlots.filter((slot) =>
        [SLOT_TYPES.CAM_LIVE, SLOT_TYPES.CAM_RECORDED, SLOT_TYPES.CAM_PA].includes(
          slot.type
        )
      );

      return slots.map((s) => ({
        pos: s.pos,
        name: s.name,
        data: s.data.camera,
      }));
    },
  },
  methods: {
    syncCameras() {
      const callback = (camera, index) => {
        const data = {
          cameraId: camera.data.camera_id,
          camera_proxy_id: camera.data.camera_proxy_id,
        };

        this.$nextTick(() =>
          index === 0 ? this.setMainCamera(data) : this.setSecondaryCamera(data)
        );
      };

      this.processArray(this.availableCameras, 1000, callback);
      this.syncedCamerasStatus = true;
    },

    unsyncCameras() {
      const callback = (camera) => {
        this.$nextTick(() => this.resetTimelineModeOnCamera(camera.data.camera_id));
      };

      this.processArray(this.availableCameras, 800, callback);
      this.syncedCamerasStatus = false;
    },

    processArray(array, delay, callback) {
      let i = 0;

      function next() {
        if (i < array.length) {
          callback(array[i], i, array);
          i++;
          setTimeout(next, delay);
        }
      }

      next();
    },

    activateTimelineModeOnCamera: debounce(function (cameraId, mainCamera = false) {
      const camera = this.availableCameras.find((c) => c.data.camera_id === cameraId);

      if (!camera) return;

      const cameraData = camera.data;

      if (mainCamera) this.cameraData = cameraData;

      const cameraType = cameraData.camera_type;
      const config = Config.create(cameraType);

      const start = this.lastRequest
        ? this.lastRequest.start
        : moment()
            .subtract(config.lastMinDateRequested, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");

      const end = this.lastRequest
        ? this.lastRequest.end
        : moment().subtract(20, "seconds").format("YYYY-MM-DD HH:mm:ss");

      EventBus.emit(`timeline:${this.tabInfo.id}:${cameraId}:activated`, {
        cameraId,
        start,
        end,
        mainCamera,
      });

      // TODO: Improve this code
      // setTimeout(() => {
      //   EventBus.emit(`timeline:${this.tabInfo.id}:set_current_time`, {
      //     currentTime: moment(end).subtract(300, "seconds").valueOf(),
      //   });
      // }, 3000);
    }, 500),

    disableTimelineModeOnCamera: debounce(function (cameraId) {
      if (!cameraId) return;

      EventBus.emit(`timeline:${this.tabInfo.id}:${cameraId}:disabled`, {
        cameraId: cameraId,
      });
    }, 200),

    resetTimelineModeOnCamera: debounce(function (cameraId, removeFromArray = true) {
      if (!cameraId) return;

      EventBus.emit(`timeline:${this.tabInfo.id}:${cameraId}:reset`, {
        cameraId: cameraId,
        mainCamera: this.currentMainCameraId === cameraId,
      });

      if (this.currentMainCameraId === cameraId) {
        this.currentMainCameraId = false;
        this.cameraData = null;
      }

      if (removeFromArray) {
        this.removeCameraFromSyncedCameras(cameraId);
      }
    }, 200),

    setMainCamera(data) {
      if (this.currentMainCameraId !== data.cameraId) {
        if (this.currentMainCameraId) {
          this.switchMainCamera(data.cameraId);
        } else {
          this.syncedCameras.push({ type: "main", cameraId: data.cameraId });
          this.currentMainCameraId = data.cameraId;
          this.activateTimelineModeOnCamera(data.cameraId, true);
        }
      }
    },

    switchMainCamera(cameraId) {
      this.cameraData = null;
      this.tmkey += 1;
      const queue = [];

      queue.push(cameraId);
      queue.push(parseInt(JSON.stringify(this.currentMainCameraId)));

      this.processArray(queue, 1000, (id, index) => {
        if (index === 0) {
          const camera = this.syncedCameras.find((c) => c.cameraId === id);
          camera.type = "main";
          this.activateTimelineModeOnCamera(id, true);
          this.currentMainCameraId = id;
        } else {
          const camera = this.syncedCameras.find((c) => c.cameraId === id);
          camera.type = "secondary";
          this.activateTimelineModeOnCamera(id);
        }
      });
    },

    setSecondaryCamera(data) {
      const camera = this.syncedCameras.find((c) => c.cameraId === data.cameraId);
      if (!camera) {
        this.syncedCameras.push({ type: "secondary", cameraId: data.cameraId });
      }
      this.activateTimelineModeOnCamera(data.cameraId);
      this.$refs.timeline.fetchAndAddAlarms(data.camera_proxy_id);
    },

    removeAllCamerasFromSyncedCameras() {
      this.syncedCameras.forEach((c) => {
        if (!this.currentMainCameraId) {
          this.disableTimelineModeOnCamera(c.cameraId);
        }
        this.removeCameraFromSyncedCameras(c.cameraId);
      });
    },

    removeCameraFromSyncedCameras(cameraId) {
      if (!cameraId) return;
      this.syncedCameras = this.syncedCameras.filter((c) => c.cameraId !== cameraId);
    },

    onLastRequest(evt) {
      this.lastRequest = evt;
    },

    cameraReady(evt) {
      this.forceUpdateCurrentTime = new Date().getTime();
    },

    setTimelineFromSubsystemTree() {
      if (this.slotData && this.slotData.data.filters) {
        this.filtersData = this.slotData.data.filters;
        this.syncCameras();
      }
    },

    onSyncCameras(action) {
      if (action === "sync-cameras") {
        this.syncCameras();
      } else {
        this.unsyncCameras();
        this.tmkey += 1;
      }
    },

    cameraAlarmSidebar(evt) {
      this.cameraData = evt.camera;

      this.currentMainCameraId = this.cameraData.cameraId;

      const cameraType = this.cameraData.camera_type;
      const config = Config.create(cameraType);
      const { start, end } = [null, null];

      EventBus.emit(
        `timeline:${evt.tabInfo.value.id}:${this.cameraData.cameraId}:activated`,
        {
          cameraId: this.cameraData.cameraId,
          start,
          end,
          mainCamera: true,
        }
      );

      // TODO: Improve this code
      setTimeout(() => {
        EventBus.emit(`timeline:${evt.tabInfo.id}:set_current_time`, {
          currentTime: moment(end).subtract(300, "seconds").valueOf(),
        });
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline .wrapper .toolbar {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
}
</style>
