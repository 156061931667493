<template>
  <div class="alarm-map">
    <GmapMap
      v-if="data.data.coordinates"
      :center="data.data.coordinates"
      :zoom="20"
      map-type-id="roadmap"
      ref="map"
    >
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      />
      <GmapMarker
        :icon="icon"
        :position="data.data.coordinates"
        :clickable="true"
        @click="toggleInfoWindow(data)"
      />
    </GmapMap>
    <p class="text-center" v-else>Map not available</p>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      icon: {
        url: "https://sbo.omniview.cloud/img/mapiconscollection-markers/apartment-3-green.png",
      },
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  methods: {
    toggleInfoWindow({ data }) {
      this.infoWindowPos = data.coordinates;
      this.infoOptions.content = this.infoWindowTemplate(data.unit);

      if (this.currentMidx == data.id) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = data.id;
      }
    },
    infoWindowTemplate(data) {
      return `
            <div style="width: 300px;">
                <h2 style="text-align: center">${data.description}</h2>
                <div>
                    <table style="text-align: left;">
                        <tr>
                            <th>Empresa</th>
                            <td>${data.company}</td>
                        </tr>
                        <tr>
                            <th>Dirección 1</th>
                            <td>${data.address_1}</td>
                        </tr>
                        <tr>
                            <th>Dirección 2</th>
                            <td>${data.address_2}</td>
                        </tr>
                        <tr>
                            <th>Número exterior</th>
                            <td>${data.exterior_number}</td>
                        </tr>
                        <tr>
                            <th>Colonia</th>
                            <td>${data.suburb}</td>
                        </tr>
                        <tr>
                            <th>Municipo</th>
                            <td>${data.municipality ? data.municipality.name : " - "}</td>
                        </tr>
                        <tr>
                            <th>C.P.</th>
                            <td>${data.postal_code}</td>
                        </tr>
                        <tr>
                            <th>País</th>
                            <td>${data.country ? data.country.name : " - "}</td>
                        </tr>
                        <tr>
                            <th>Estado</th>
                            <td>${data.region ? data.region.name : " - "}</td>
                        </tr>
                    </table>
                </div>
            <div>
        `;
    },
  },
};
</script>

<style lang="scss" scoped>
.alarm-map {
  width: 100%;
  height: 100%;
  .vue-map-container {
    width: 100%;
    height: 100%;
  }
}
</style>
