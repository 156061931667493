export default {
  id: 1,
  name: 'default',
  grid: [
    {"w":4,"h":4,"minW":3,"minH":2,"id":"444e7e66-f0a4-4ba3-b290-4d3af4a3347c","x":0,"y":0,"content":"4 x 4","name":null,"type":null,"pos":1,"data":null},
    {"w":4,"h":4,"minW":3,"minH":2,"id":"f0673221-7b2f-40a9-894e-9962a1719435","x":4,"y":0,"content":"4 x 4","name":null,"type":null,"pos":2,"data":null},
    {"w":4,"h":4,"minW":3,"minH":2,"id":"03eff0d1-66e1-4730-b5c4-6b10c268b483","x":8,"y":0,"content":"4 x 4","name":null,"type":null,"pos":3,"data":null},
    {"w":4,"h":4,"minW":3,"minH":2,"id":"9c05f0f1-ae94-4824-8da2-d4e6c0976fbc","x":0,"y":4,"content":"4 x 4","name":null,"type":null,"pos":4,"data":null},
    {"w":4,"h":4,"minW":3,"minH":2,"id":"125214e7-8d52-44a8-b6c2-cdd518b59067","x":4,"y":4,"content":"4 x 4","name":null,"type":null,"pos":5,"data":null},
    {"w":4,"h":4,"minW":3,"minH":2,"id":"71a8b78a-cd8d-4e8a-a7ef-fed4038ffae4","x":8,"y":4,"content":"4 x 4","name":null,"type":null,"pos":6,"data":null}
  ]
};
