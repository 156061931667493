<template>
  <div class="device" :style="devicePosition">
    <div :id="`device-info-${device.device.camera_proxy_id}`" class="icon-devices" @click="isVisible" @mouseover="deviceInfo(device)"> 

      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="10" height="10" 
        :fill="statusColor" 
        class="bi bi-circle-fill" 
        viewBox="0 0 16 16" 
        style="position:absolute;">
        <circle cx="8" cy="8" r="8"/>
      </svg>

      <img
        :src="setImage(device.device)"
        width="100%"
        :id="`device-${device.device.camera_proxy_id}`"
      />
    </div>

    <b-popover
      v-if="!isHoverVisible"
      :title="device.device.name"
      :target="`device-info-${device.device.camera_proxy_id}`"
      placement="auto"
      triggers="hover"
    >
      <device-info
        v-if="this.dataDevice != null"
        :device="this.dataDevice"
      />
    </b-popover>

    <b-popover
      :title="device.device.name"
      :target="`device-${device.device.camera_proxy_id}`"
      placement="auto"
    >
      <ul 
        v-if="device.device.camera_type == 'router'"
        class="p-0"
      >
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="routerOptionClick('activate-digital-output', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
          </svg>
          Activar salida digital
        </li>
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="routerOptionClick('disable-digital-output', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-pause-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
          </svg>
          Desactivar salida digital
        </li>
      </ul>

      <ul 
        v-if="device.device.device_type == 'camera'"
        class="p-0"
      >
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="cameraOptionClick('live-video', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-play-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
          </svg>
          Video en vivo
        </li>
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="cameraOptionClick('recorded-video', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-film" viewBox="0 0 16 16">
            <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z"/>
          </svg>
          Video grabado
        </li>
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="cameraOptionClick('info', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          Información
        </li>
        <li
          v-if="device.device.camera_type == 'camera_manager'"
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="cameraOptionClick('activate-digital-output', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
          </svg>
          Activar salida digital
        </li>
        <li
          v-if="device.device.camera_type == 'camera_manager'"
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="cameraOptionClick('disable-digital-output', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-pause-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
          </svg>
          Desactivar salida digital
        </li>

      </ul>

      <ul 
        v-if="device.device.camera_type == 'interphone'"
        class="p-0"
      >
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="interphoneOptionClick('interphone-call', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-telephone-outbound" viewBox="0 0 16 16">
            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
          </svg>
          Make a Call
        </li>
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="interphoneOptionClick('interphone-info', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          Información
        </li>
      </ul>

      <ul 
        v-if="device.device.camera_type == 'density_device'"
        class="p-0"
      >
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="densityOptionClick('info-actuator', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          Actuator Info
        </li>
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="densityOptionClick('density-test-shoot', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-square" viewBox="0 0 16 16">
            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          </svg>
          Run test shot
        </li>
        <li
          class="p-0 list-unstyled cursor-pointer cursor-hover"
          @click="densityOptionClick('density-shoot', device)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" fill="#6e6b7b" class="bi bi-triangle" viewBox="0 0 16 16">
            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
          </svg>
          Run shot
        </li>
      </ul>
    </b-popover>

  </div>
</template>

<script>
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import { VBTooltip } from "bootstrap-vue";
import { EventBus } from "@/libs/event-bus";
import axios from '@axios';
import DeviceInfo from "./DeviceInfo.vue";
import setDeviceImage from '../../../../../plane/useList.js';

export default {
  components: { DeviceInfo },
  mixins: [layoutMixin],
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    device: Object,
    color: {
      type: String,
      default: "#ff0000",
    },
    pos: String | Number,
  },
  data() {
    return {
      SLOT_TYPES,
      SOURCES,
      isHoverVisible : false,
      dataDevice: null
    };
  },
  computed: {
    devicePosition() {
      return `
        width: ${this.device.position.width}px;
        height: ${this.device.position.height}px;
        transform: translate(${this.device.position.x}px, ${this.device.position.y}px) rotate(${this.device.position.degree}deg);
      `;
    },
    statusColor() {
      const camera = this.device.device;
      if(camera.active){
        return "#3aa40c";
      }
      return camera.camera_proxy_status == 1 ? "#3aa40c" : "#cc0000";
    },
  },
  methods: {
    setImage(device){
      return setDeviceImage(device)
    },
    isVisible(){
      this.isHoverVisible = !this.isHoverVisible
    },
    async deviceInfo({ device }){

      const baseUrl = `${process.env.VUE_APP_BASE_URL}`

      if(device.device_type == "camera" || device.device_type == "sensor"){
        const urlCamera = baseUrl + "/v1/proxy_camera/" + device.id_camera_proxy;
        let infoInterphone = await axios.get(urlCamera);
        this.dataDevice = infoInterphone.data.data
      }

      switch (device.camera_type) {
        case "router":
          const urlRouter = baseUrl + "/v1/router/" + device.id;
          let infoRouter = await axios.get(urlRouter);
          this.dataDevice = { camera : infoRouter.data.data }
          this.dataDevice.camera.camera_type = 'router' 
          break;

        case "interphone":
          const urlInterphone = baseUrl + "/v1/proxy_camera/" + device.id_camera_proxy;
          let infoInterphone = await axios.get(urlInterphone);
          this.dataDevice = infoInterphone.data.data
          break;
        
        case "density_device":
          const urlActuators = baseUrl + "/v1/proxy_camera/" + device.id_camera_proxy;
          let infoActuators = await axios.get(urlActuators);
          this.dataDevice = infoActuators.data.data
          break;
      }

    },
    async cameraOptionClick(type, { device }) {
      const name = device.name || `CAM_${device.camera_id}`;
      switch (type) {
        case "live-video":
          this.configSlotData(
            this.SLOT_TYPES.CAM_LIVE, 
            name, 
            device
          );
          break;

        case "recorded-video":
          this.configSlotData(
            this.SLOT_TYPES.CAM_RECORDED,
            name,
            device,
            this.SOURCES.CAMERA
          );
          break;

        case "info":
          this.configSlotData(
            this.SLOT_TYPES.INFO,
            name,
            device,
            this.SOURCES.CAMERA
          );
          break;

        case "activate-digital-output":
          if (confirm('¿Segur@ de activar la salida digital de la cámara?')) {
            try {
              await axios.get('/v1/command/run-command-by-name', { params: { name: 'activate-cam-do', id: device.camera_id } });
              alert('Commando ejecutado');
            } catch (error) {
              console.log("[run command]", error);
              alert('Ocurrió un error al ejecutar el comando');
            }
          }
          break;

        case "disable-digital-output":
          if (confirm('¿Segur@ de desactivar la salida digital de la cámara?')) {
            try {
              await axios.get('/v1/command/run-command-by-name', { params: { name: 'deactivate-cam-do', id: device.camera_id } });
              alert('Commando ejecutado');
            } catch (error) {
              console.log("[run command]", error);
              alert('Ocurrió un error al ejecutar el comando');
            }
          }
          break;
      }
    },
    interphoneOptionClick(type, { device }) {
      switch (type) {
        case "interphone-call":
        EventBus.emit(`call_device`, { id: device.device_id });
          break;

        case "interphone-info":
          this.setSlotData(
            this.SLOT_TYPES.INTERPHONE, 
            device.device_id, 
            { data: device }
          );
          break;
      }
    },
    async densityOptionClick(type, { device }) {
      const urlActuators = `${process.env.VUE_APP_BASE_URL}/v1/services/actuators/info/`;
      const data = {
        url: urlActuators + device.id_camera_proxy,
      };

      switch (type) {
        case "info-actuator":
          this.setSlotData( 
            this.SLOT_TYPES.INFO, 
            device.id, 
            { data: data }, 
            this.SOURCES.ACTUATOR
          );
          break;

        case "density-test-shoot":
          if (confirm("¿Segur@ de ejecutar el disparo de prueba?")) {
            try {
              await axios.get(data.url);
              alert("Commando ejecutado");
            } catch (error) {
              console.log("[run command]", error);
              alert("Ocurrió un error al ejecutar el comando");
            }
          }
          break;

        case "density-shoot":
          if (confirm("¿Segur@ de ejecutar el disparo real?")) {
            try {
              await axios.get(data.url);
              alert("Commando ejecutado");
            } catch (error) {
              console.log("[run command]", error);
              alert("Ocurrió un error al ejecutar el comando");
            }
          }
          break;
      }
    },
    async routerOptionClick(type, { device }) {
      switch (type) {
        case "activate-digital-output":
          if (confirm('¿Segur@ de activar la salida digital?')) {
            try {
              await axios.get('/v1/command/run-command-by-name', { params: { name: 'activate-router-do', id: device.id_unit } });
              alert('Commando ejecutado');
            } catch (error) {
              console.log("[run command]", error);
              alert('Ocurrió un error al ejecutar el comando');
            }
          }
          break;

        case "disable-digital-output":
          if (confirm('¿Segur@ de desactivar la salida digital?')) {
            try {
              await axios.get('/v1/command/run-command-by-name', { params: { name: 'deactivate-router-do', id: device.id_unit } });
              alert('Commando ejecutado');
            } catch (error) {
              console.log("[run command]", error);
              alert('Ocurrió un error al ejecutar el comando');
            }
          }
          break;
      }
    },
    configSlotData(slotType, name, data, source = null) {
      setTimeout(() => {
        this.setSlotData(slotType, name, { camera: data }, source);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.device {
  position: absolute;
  cursor: pointer;
}
.cursor-hover:hover {
  background: #efefef
}
</style>
