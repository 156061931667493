export default {
  id: 0,
  name: 'default',
  grid: [
    { "x": 0, "y": 0, "w": 3, "h": 2, "minW": 3, "minH": 2, "id": "9d5361cf-fb7b-4ea2-82c8-74007bf5c2a8", "content": "3 x 2", "name": null, "type": null, "pos": 1, "data": null },
    { "x": 3, "y": 0, "w": 3, "h": 2, "minW": 3, "minH": 2, "id": "53725939-b7fe-4456-a589-dd9165906d39", "content": "3 x 2", "name": null, "type": null, "pos": 2, "data": null },
    { "x": 6, "y": 0, "w": 6, "h": 4, "minW": 3, "minH": 2, "id": "d0cd8d85-5c82-43a5-9ca6-fa0600564d37", "content": "6 x 4", "name": null, "type": null, "pos": 3, "data": null },
    { "x": 0, "y": 2, "w": 3, "h": 2, "minW": 3, "minH": 2, "id": "7a2e98db-4954-4523-9160-e9a22be333e4", "content": "3 x 2", "name": null, "type": null, "pos": 4, "data": null },
    { "x": 3, "y": 2, "w": 3, "h": 2, "minW": 3, "minH": 2, "id": "b49398f6-aeed-4e04-bd1a-6cd50363f2cb", "content": "3 x 2", "name": null, "type": null, "pos": 5, "data": null },
    { "x": 0, "y": 4, "w": 6, "h": 4, "minW": 3, "minH": 2, "id": "76ddb556-1063-4b46-bd55-8a6a0a185795", "content": "6 x 4", "name": null, "type": null, "pos": 6, "data": null },
    { "x": 6, "y": 4, "w": 6, "h": 4, "minW": 3, "minH": 2, "id": "e7867b2e-86b9-4794-9c7c-b487b148360b", "content": "6 x 4", "name": null, "type": null, "pos": 7, "data": null }]
};
