<template>
  <div class="detection-event-container">
    <div class="waiting-detection" v-if="waiting">
      <p class="mb-0 mx-auto text-center font-medium-5">
        <strong>
          {{ $t("grid.slots.info.fr.waiting_detection") + "..." }}
        </strong>
      </p>
    </div>
    <b-container v-show="!waiting">
      <b-row>
        <b-col cols="6" md="6" class="p-0">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="detection-info scroll-area"
          >
            <div v-if="detectionEvent">
              <lpr-detection
                v-if="detectionEvent.event_type == 'lpr'"
                :detectionEvent="detectionEvent"
              />

              <fr-detection
                v-if="detectionEvent.event_type == 'fr'"
                :detectionEvent="detectionEvent"
              />

              <generic-detection
                v-if="
                  detectionEvent.event_type != 'lpr' && detectionEvent.event_type != 'fr'
                "
                :detectionEvent="detectionEvent"
              />
            </div>
          </vue-perfect-scrollbar>
        </b-col>
        <b-col cols="6" md="6" class="p-0">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="detection-table scroll-area"
          >
            <div class="m-1">
              <b-row>
                <b-col
                  cols="6"
                  md="8"
                  class="d-flex align-items-center justify-content-start"
                >
                  <label class="text-capitalize">{{ $t("table.show") }}</label>
                  <v-select
                    v-model="perPage"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label class="text-capitalize">{{ $t("table.entries") }}</label>
                </b-col>

                <b-col cols="6" md="4">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-b-modal.metadata_search_modal
                      variant="info"
                      class="text-capitalize"
                      type="button"
                      v-b-tooltip.hover
                      :title="$t('filters')"
                    >
                      <feather-icon icon="FilterIcon" size="15" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-table
              ref="refListTable"
              class="table table-sm table-bordered"
              :fields="fields"
              :items="fetchList"
              :current-page="currentPage"
              :per-page="perPage"
              @row-selected="onRowSelected"
              select-mode="single"
              selectable
            >
            </b-table>
            <div class="d-flex justify-content-center">
              <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
            </div>
            <div class="d-flex justify-content-center mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalList"
                :per-page="perPage"
                prev-class="prev-item"
                next-class="next-item"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
              ></b-pagination>
            </div>
          </vue-perfect-scrollbar>
        </b-col>
      </b-row>

      <metadata-search-modal @search="refetchData()" @clear="clearSearchFilters()">
        <template v-slot:main-filters>
          <filters
            :unit-filter.sync="unitFilter"
            :unit-options="unitOptions"
            :tenant-filter.sync="tenantFilter"
            :tenant-options="tenantOptions"
            :camera-filter.sync="cameraFilter"
            :camera-options="cameraOptions"
            :event-type-filter.sync="eventTypeFilter"
            :event-type-options="eventTypeOptions"
            :provider-filter.sync="providerFilter"
            :provider-options="providerOptions"
            :start-datetime.sync="startDatetimeFilter"
            :end-datetime.sync="endDatetimeFilter"
            :alarm-filter.sync="alarmFilter"
          />
        </template>
        <template v-slot:meta-filters>
          <meta-filters-component v-model="objectTypeData" />
        </template>
      </metadata-search-modal>
    </b-container>
  </div>
</template>

<script>
import store from "@/store";
import vSelect from "vue-select";
import Filters from "@/views/detection_event/Filters.vue";
import axios from "@axios";
import { BButton, BTable, BCard } from "bootstrap-vue";
import EventEmitter from "@/libs/DOMEventEmitter";
import MetadataSearchModal from "@/components/MetadataSearchModal.vue";
import MetaFiltersComponent from "@/components/MetaFiltersComponent";
import { formatFilters as buildMetadataFilters } from "@/components/MetaFiltersComponent/helpers";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FrDetection from "@/views/components/DetectionEventSlot/FrDetection.vue";
import LprDetection from "@/views/components/DetectionEventSlot/LprDetection.vue";
import GenericDetection from "@/views/components/DetectionEventSlot/GenericDetection.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import LayoutApi from "@/libs/LayoutApi";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";

const layoutApi = new LayoutApi(store);
const eventEmitter = new EventEmitter();

export default {
  components: {
    BButton,
    BTable,
    BCard,
    vSelect,
    Filters,
    MetadataSearchModal,
    MetaFiltersComponent,
    LprDetection,
    FrDetection,
    GenericDetection,
    VuePerfectScrollbar,
  },
  props: {
    tabInfo: Object,
    data: Object,
  },
  data() {
    return {
      waiting: true,
      fields: [
        { label: "target", key: "", sortable: true },
        { label: "type", key: "formatted_event_type", sortable: true },
        { label: "camera", key: "camera_proxy.camera.name", sortable: true },
        { label: "date", key: "created_at", sortable: true },
      ],
      detectionEvent: null,
      totalRows: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      unitFilter: null,
      unitOptions: [],
      tenantFilter: null,
      tenantOptions: [],
      cameraFilter: [],
      cameraOptions: [],
      eventTypeFilter: null,
      eventTypeOptions: [],
      providerFilter: null,
      providerOptions: [],
      startDatetimeFilter: null,
      endDatetimeFilter: null,
      alarmFilter: null,
      totalList: 0,
      objectTypeData: {},
      filters: {},
      perfectScrollbarSettings: { maxScrollbarLength: 60 },
    };
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  computed: {
    dataMeta: function () {
      let localItemsCount = this.$refs.refListTable
        ? this.$refs.refListTable.localItems.length
        : 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalList,
      };
    },
    tabSlots() {
      return this.tabInfo && store.state.grid.tabs[this.tabInfo.id]
        ? store.state.grid.tabs[this.tabInfo.id].slots
        : [];
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    document.querySelector('.detection-info').setAttribute("style", `height:${this.$parent.$el.clientHeight}px`);
    document.querySelector('.detection-table').setAttribute("style", `height:${this.$parent.$el.clientHeight}px`);
    this.filterList();

    eventEmitter.on(`create:detection_event`, (data) => {
      this.syncWithCamera(data.detail);
    });

    const slotDada = layoutApi.getSlotData(this.tabInfo.pos);

    if (slotDada && slotDada.data.in_app_source === SOURCES.ALARM_VIEW) {
      this.setAlarmViewConfig();
    }
  },
  methods: {
    setAlarmViewConfig() {
      this.tabSlots.forEach((slot) => {
        const slotData = layoutApi.getSlotData(slot.pos);
        if (slotData && slotData.type === SLOT_TYPES.CAM_LIVE) {
          const data = { id_camera_proxy: slotData.data.camera.id_camera_proxy };
          this.syncWithCamera(data);
        }
      });
    },
    syncWithCamera(data) {
      if (this.cameraFilter.length > 0) {
        if (!this.cameraFilter.includes(data.id_camera_proxy)) {
          this.cameraFilter.unshift(data.id_camera_proxy);
        }
      } else {
        this.cameraFilter.push(data.id_camera_proxy);
      }

      this.setDetectionEventWS(data.id_camera_proxy);
      this.refetchData();
      this.waiting = false;
      this.toast({
        component: ToastificationContent,
        props: {
          title: this.$t("grid.slots.info.detection_events.default.title"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },
    setDetectionEventWS(cameraProxyId) {
      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log(channel, data);
      });

      if (!window.Echo.connector.socket.connected) window.Echo.connect();
      window.Echo.private(`detection-event-${cameraProxyId}`).listen(
        ".create",
        (data) => {
          this.refetchData();
        }
      );
    },
    async openImageModal(imageUrl) {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    },
    onRowSelected(item) {
      if (item.length > 0) {
        this.detectionEvent = item[0];
      }
    },
    fetchList(ctx, callback) {
      store
        .dispatch("detection_event/getAll", {
          max: this.perPage,
          "page[n]": this.currentPage,
          sortBy: "id",
          sortDesc: true,
          ...this.buildFilters(),
        })
        .then((response) => {
          callback(response.data);
          this.setFields(response.data[0]);
          this.detectionEvent = response.data[0];
          this.totalList = response.meta.total;
        });
    },
    filterList() {
      axios("v1/tenants").then((response) => {
        this.tenantOptions = response.data.data.map((tenant) => ({
          label: tenant.name,
          value: tenant.id,
        }));
      });

      axios("v1/unit?hasDetectionEvent=true").then((response) => {
        this.unitOptions = response.data.data.map((unit) => ({
          label: unit.description,
          value: unit.id,
        }));
      });

      axios("v1/proxy_camera?hasDetectionEvent=true&max=5000").then((response) => {
        this.cameraOptions = response.data.data.map((camera) => ({
          label: camera.camera.name,
          value: camera.id,
        }));
      });

      axios("v1/services/detection-events/get-catalog").then((response) => {
        this.providerOptions = response.data.data.providers.map((provider) => ({
          label: provider.label,
          value: provider.value,
        }));

        response.data.data.providers.forEach((provider) => {
          provider.detections.forEach((event_type) => {
            if (
              !this.eventTypeOptions
                .map((value) => value.value)
                .includes(event_type.value)
            ) {
              this.eventTypeOptions.push({
                label: event_type.label,
                value: event_type.value,
              });
            }
          });
        });
      });
    },
    buildFilters() {
      this.filters = {
        alarm: this.alarmFilter,
        unit: this.unitFilter,
        tenant: this.tenantFilter,
        cameras: this.cameraFilter,
        provider: this.providerFilter,
        event_type: this.eventTypeFilter,
        from: this.startDatetimeFilter,
        to: this.endDatetimeFilter,
        ...buildMetadataFilters(this.objectTypeData),
      };
      return this.filters;
    },
    clearSearchFilters() {
      this.alarmFilter = null;
      this.unitFilter = null;
      this.tenantFilter = null;
      this.cameraFilter = null;
      this.providerFilter = null;
      this.eventTypeFilter = null;
      this.startDatetimeFilter = null;
      this.endDatetimeFilter = null;
      this.objectTypeData = null;
    },
    refetchData() {
      this.$refs.refListTable.refresh();
    },
    setFields(data) {
      if (data != undefined) {
        switch (data.event_type) {
          case "lpr":
            this.fields[0].key = "data.plate";
            break;

          case "fr":
            this.fields[0].key = "luna_match.name";
            break;

          default:
            this.fields[0].key = "null";
            break;
        }
      }
    },
    resizeHandler()  {
      document.querySelector('.detection-info').setAttribute("style", `height:${this.$parent.$el.clientHeight}px`);
      document.querySelector('.detection-table').setAttribute("style", `height:${this.$parent.$el.clientHeight}px`);
    },
  },
};
</script>

<style lang="scss" scoped>
.detection-event-container {
  height: 100%;
}
.waiting-detection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
