<template>
  <div class="layout-container">
    <button
      class="btn btn-sm btn-danger rounded-circle p-50 btn-remove"
      @click="removeView"
    >
      <i class="feather icon-x"></i>
    </button>
    <p class="text-center">{{ view.name }}</p>
    <div class="grid-layout__container">
      <widget
        v-for="widget in items"
        :widget="widget"
        :key="widget.local_id"
        :style="{ ...gridItemStyles(widget) }"
        :tab-data="tabData"
      />
    </div>
  </div>
</template>

<script>
import Widget from "./LayoutViewsSlot.vue";
import useLayout from "@/composables/layouts";
import { onMounted, reactive, toRefs, computed } from "@vue/composition-api";
import defaultLayout from "@/config/defaultLayout";
import store from "@/store";

export default {
  components: {
    Widget,
  },
  props: {
    view: Object,
  },
  setup(props, { emit }) {
    const userLayouts = computed(() => {
      return store.state.layouts.userLayouts.map((l) => ({
        id: l.id,
        name: l.name,
        grid: l.data,
      }));
    });
    let layout = null;

    if (props.view.layout_id === "default") {
      layout = defaultLayout;
    } else {
      layout = userLayouts.value.find((layout) => layout.id === props.view.layout_id);
      if (layout) {
        layout = Object.assign({}, { ...layout });
      } else {
        layout = defaultLayout;
      }
    }

    const tabData = props.view.data;
    const { gridItemStyles } = useLayout(layout, 15);
    const state = reactive({
      items: [],
    });

    onMounted(() => {
      if (layout && layout.grid) {
        state.items = [...layout.grid];
      }
    });

    const removeView = (event) => {
      event.stopPropagation();
      emit("remove-view", props.view.id);
    };

    return {
      ...toRefs(state),
      gridItemStyles,
      removeView,
      tabData,
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  position: relative;
  min-height: 170px;
  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: none;
  }
  &.selected,
  &:hover {
    .btn-remove {
      display: block;
    }
    .app-grid {
      .slot-item {
        border: 1px solid #fff;
      }
    }
  }
}
</style>
