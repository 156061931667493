<template>
  <div class="user-charts widget bg-white border-bottom">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">{{ title }}</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="24"
            />
          </template>
          <span class="handle cursor-move" v-if="isConfigModeOn">
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        :visible="false"
        v-model="isCollapseOpen"
        @input="onUpdateStatus"
      >
        <div class="user-charts-container d-flex flex-wrap justify-content-around p-2">
          <div v-for="chart in userCharts" :key="chart.id">
            <div
              class="p-2 chart-item border cursor-pointer mb-2 text-center"
              draggable
              @dragstart="onDragStart($event, chart)"
              @dragend="onDragEnd"
            >
              <feather-icon
                :icon="icons[chart.data.chart] || 'BarChart2Icon'"
                size="16"
                class="mr-25"
              />
              <button
                class="btn btn-sm btn-danger rounded-circle p-25 btn-remove"
                @click="onRemoveChart(chart)"
              >
                <i class="feather icon-x"></i>
              </button>
              <p>{{ chart.name }}</p>
            </div>
          </div>
          <div v-if="!userCharts.length">
            {{ $t("grid.widgets.charts.title") }}
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import store from "@/store";
import LayoutViews from "./LayoutViews";
import Swal from "sweetalert2";
import axios from "@axios";
import layoutMixin from "@/mixins/layoutMixin";
import { SLOT_TYPES } from "@/config/layoutConfig";
import { EventBus } from "@/libs/event-bus";

export default {
  mixins: [layoutMixin],
  components: {
    BCollapse,
  },
  components: {
    LayoutViews,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
    selectedLayout: {
      type: String | Number,
      default: null,
    },
  },
  data() {
    return {
      userCharts: [],
      isCollapseOpen: true,
      icons: {
        bar: "BarChart2Icon",
        pie: "PieChartIcon",
        map: "MapIcon",
      },
    };
  },
  async mounted() {
    this.isCollapseOpen = this.isOpen;
    EventBus.on("charts:action-performed", this.onChartAction);
    this.fetchData();
  },
  beforeDestroy() {
    EventBus.off("charts:action-performed", this.onChartAction);
  },
  computed: {
    activeTabId() {
      return store.state.grid.activeTab;
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      console.log("tabsQty");
      return Object.keys(this.tabs).length;
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    userLayouts() {
      return store.state.layouts.userLayouts.map((l) => ({
        id: l.id,
        name: l.name,
        grid: l.data,
      }));
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
  },
  methods: {
    fetchData() {
      return axios.get("v1/services/user-charts").then((resp) => {
        this.userCharts = resp.data.data;
      });
    },
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    onRemoveChart(chart) {
      Swal.fire({
        title: this.$t("messages.delete.title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("actions.delete"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await axios.delete(`v1/services/user-charts/${chart.id}`);
          await this.fetchData();
        }
      });
    },
    onChartAction() {
      this.fetchData();
    },
    onDragStart(evt, chart) {
      evt.target.classList.add("dragging");
      this.setSlotData(SLOT_TYPES.CHARTS_SDK, `Charts SDK`, { chartId: chart.id });
    },
    onDragEnd(evt) {
      evt.target.classList.remove("dragging");
      this.resetSlotSelection();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-charts {
  .user-charts-container {
    .chart-item {
      position: relative;
      &.dragging {
        .btn-remove {
          display: none;
        }
      }
      .btn-remove {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 1;
      }
    }
  }
}
</style>
