<template>
    <div :class="`w-100 h-100 row mx-0 px-0 align-items-center justify-content-center overflow-auto ${background}`">
        <div v-if="loading" class="col-12 text-center">
            <b-spinner variant="white" />
        </div>
        <div v-if="weather && !loading" class="col-12">
            <div class="mb-1">
                <p class="text-center">
                    <i :class="`wi wi-owm-${weather.codeIcon} icon text-warning`"></i>
                </p>
                <p class="temp mb-2 text-center text-white">
                    {{ temperature }}
                </p>
                <h3 class="mb-0 text-center text-white">
                    <feather-icon
                        icon="MapPinIcon"
                        size="15"
                        class="icon-marker"
                    />
                    {{ title }}
                </h3>
            </div>
            <div class="row mx-0 justify-content-center text-white">
                <div class="col-auto">
                    <p>
                        <i class="wi wi-strong-wind"></i> {{ wind }}
                    </p>
                </div>
                <div class="col-auto">
                    <p>
                        <i class="wi wi-raindrop"></i> {{ weather.humidity }} %
                    </p>
                </div>
                <div class="col-auto">
                    <p>
                        <i class="wi wi-cloudy"></i> {{ weather.clouds }} %
                    </p>
                </div>
                <div class="col-auto">
                    <p class="">
                        <b>
                            <i class="wi wi-direction-down"></i>
                        </b>
                        Min: {{ tempMin }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="col text-center">
                        <b>
                            <i class="wi wi-direction-up"></i>
                        </b>
                        Max: {{ tempMax }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="errorMessage" class="col-12">
            <h5 class="mb-0 text-center text-white p-1 bg-danger rounded">
                {{ $t(errorMessage) }}
            </h5>
        </div>
    </div>
</template>

<script>
import axios from '@/libs/axios';
import store from "@/store";

export default {
    mounted() {
      this.initGeolocation()
    },
    data() {
        return {
            loading: false,
            weather: null,
            errorMessage: null,
            weather: null
        }
    },
    computed: {
        background() {
            return (store.state.appConfig.layout.skin === 'light') ? 'bg-info' : 'bg-primary'
        },
        lang() {
            return store.getters['user/getPreferences'].lang
        },
        unitTemp() {
            return (store.getters['user/getPreferences'].lang === 'en') ? 'F' : 'C'
        },
        temperature() {
            return `${this.weather.temp} ° ${this.unitTemp}`
        },
        tempMax() {
            return `${this.weather.tempMax} ° ${this.unitTemp}`
        },
        tempMin() {
            return `${this.weather.tempMin} ° ${this.unitTemp}`
        },
        title() {
            return `${this.weather.place }, ${this.weather.codeCountry } - ${this.weather.description }`
        },
        unitWind() {
            return (store.getters['user/getPreferences'].lang === 'en') ? 'mi/h' : 'm/s'
        },
        wind() {
            return `${this.weather.wind} ${this.unitWind}`
        }
    },
    methods: {
        async initGeolocation() {
            const permissions = await navigator.permissions.query({ name: 'geolocation' })

            permissions.onchange = () => this.getGeolocation()

            this.getGeolocation()
        },
        getGeolocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    let { coords } = position

                    this.errorMessage = null
                    this.getWeather(coords.latitude, coords.longitude)
                }, (error) => {
                    let { code } = error

                    this.errorMessage = 'grid.weather.errorGelocation'

                    if (code === 1) {
                        this.errorMessage = 'grid.weather.permmisionDenied'
                    }

                    this.weather = null
                });
            } else {
                this.errorMessage = 'grid.weather.errorGelocation'
                this.weather      = null
            }
        },
        async getWeather(latitude, longitude) {
            this.loading = true

            try {
                let { data } = await axios.post('/v1/weather', { latitude,longitude })
                let { data: dataApi } = data

                this.weather = {
                    place: dataApi.name,
                    temp: Math.round(dataApi.main.temp),
                    tempMax: Math.round(dataApi.main.temp_max),
                    tempMin: Math.round(dataApi.main.temp_min),
                    humidity: dataApi.main.humidity,
                    description: dataApi.weather[0].description,
                    wind: dataApi.wind.speed,
                    clouds: dataApi.clouds.all,
                    codeIcon: dataApi.weather[0].id,
                    codeCountry: dataApi.sys.country
                }
            } catch (error) {
                this.weather = null
            } finally {
                this.loading = false
            }
        }
    },
    watch: {
        lang() {
            this.getGeolocation();
        }
    }
}
</script>


<style lang="scss" scoped>
.icon-marker {
    margin-right: 3px;
}
.icon {
    font-size: 55px;
}
.sub-details {
    font-size: 16px;
}

.temp {
    font-size: 45px;
    font-weight: bold;
}
</style>
