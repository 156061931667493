<template>
  <div :class="`omniview-map omniview-map-${mapId}`"></div>
</template>

<script>
import { Map as OMS } from "@omnicloud/maps-sdk";
import store from "@/store";
import { SLOT_TYPES } from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import { v4 as uuidv4 } from "uuid";
import LayoutApi from "@/libs/LayoutApi";
import axios from "@axios";
import { EventBus } from "@/libs/event-bus";
import useAppConfig from '@core/app-config/useAppConfig'

const token = window.localStorage.accessToken;
const map = new OMS({
  token,
  apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
  mode: "alarms",
});
const layoutApi = new LayoutApi(store);

export default {
  mixins: [layoutMixin],
  props: {
    data: {
      type: Object,
    },
    tabInfo: {
      type: Object,
    },
  },
  data() {
    return {
      mapSelector: null,
      SLOT_TYPES,
      mapId: uuidv4(),
      firstMarkersLoaded: false,
      lastPos: {
        lat: null,
        lng: null,
      },
    };
  },
  computed: {
    units() {
      return store.state.grid.units;
    },
  },
  async mounted() {
    const selector = `.omniview-map-${this.mapId}`;
    this.mapSelector = document.querySelector(selector);

    const { skin } = useAppConfig()

    map.setConfig({
      container: selector,
      latLng: { lat: 19.39068, lng: -99.2836975 },
      gmapsKey: process.env.VUE_APP_GMAPS_KEY,
      triggerEvents: true,
      mode: "tracking",
      darkMode: skin.value == "dark" ? true : false
    });

    EventBus.on(`toogle-dark-mode`, (params) => {
      if (params == 'dark') {
        map.setDarkMode();
      } else {
        map.setLightMode();
      }
    });

    map.onMapReady().then(() => {
      map.setTrackingMarker(this.buildMarkerObject(this.data.alarm));
      this.lastPos.lat = this.data.alarm.coordinates.lat;
      this.lastPos.lng = this.data.alarm.coordinates.lng;

      if (!this.firstMarkersLoaded) {
        axios
          .post("/v1/luna/getAlarmData", { alarmId: this.data.alarm.id })
          .then(({ data }) => {
            if (data.data.length) {
              data.data.forEach((item) => {
                const marker = this.buildMarkerObject(item, "tracking");
                map.setTrackingMarker(marker);
              });

              const lastItem = data.data.at(-1);
              this.lastPos.lat = lastItem.location.lat;
              this.lastPos.lng = lastItem.location.lng;
            }
            this.firstMarkersLoaded = true;
          });
      }

      window.Echo.private(`fr-location-alarm-${this.data.alarm.id}`).listen(
        ".create",
        (data) => {
          if (data.message) {
            const item = data.message;
            const unit = item.unit;
            const cameras = unit.cameras;

            if (cameras.length) {
              const slotData = layoutApi.getSlotData(1);
              const camSlotData = slotData.data.camera;
              const camera = cameras[0].camera;

              if (slotData && camSlotData.camera_proxy_id !== camera.camera_proxy_id) {
                layoutApi.unsetSlot("slot", 1);
                layoutApi.unsetSlot("slot", 2);

                const liveData = { camera };
                const recordedData = { camera, ts: item.created };

                layoutApi.setSlot(camera.name, 1, liveData, this.SLOT_TYPES.CAM_LIVE);
                layoutApi.setSlot(
                  `${camera.name} (PA)`,
                  2,
                  recordedData,
                  this.SLOT_TYPES.CAM_PA
                );
              }
            }

            const marker = this.buildMarkerObject(item, "tracking");
            map.setTrackingMarker(marker);

            this.lastPos.lat = item.location.lat;
            this.lastPos.lng = item.location.lng;

            map.setCenter(
              parseFloat(item.location.lat),
              parseFloat(item.location.lng),
              15
            );
          }
        }
      );
    });

    this.mapSelector.addEventListener("camera:click", this.onCameraClick);
    this.mapSelector.addEventListener("alarms:last-position", this.onClickLastPosition);
  },
  beforeDestroy() {
    this.mapSelector.removeEventListener("camera:click", this.onCameraClick);
    this.mapSelector.removeEventListener(
      "alarms:last-position",
      this.onClickLastPosition
    );
    window.Echo.leave(`fr-location-alarm-${this.data.alarm.id}`);
  },
  methods: {
    onCameraClick({ detail }) {
      const unit = this.data.alarm.unit;
      const cameras = unit.cameras;
      const camera = cameras.find((c) => c.id === detail.camera_id);
      setTimeout(() =>
        this.setSlotData(this.SLOT_TYPES.CAM_LIVE, camera.name, { camera })
      );
    },
    onClickLastPosition() {
      map.setCenter(parseFloat(this.lastPos.lat), parseFloat(this.lastPos.lng), 15);
    },
    buildMarkerObject(data, type = "unit") {
      if (type === "unit") {
        const _data = {
          ...data.unit,
          ...data.coordinates,
          coordinates: data.coordinates,
          type,
          icon: "https://qa.omniview.mx/psim/assets/mapiconscollection-markers/apartment-3-red.png",
        };
        _data.cameras = data.unit.cameras;

        return _data;
      }

      const _data = {
        unit_name: data.unit.description,
        name: data.name,
        created: data.created_parsed,
        list: data.list,
        lat: data.location.lat,
        lng: data.location.lng,
        images: {
          snapshot: data.url,
          portrait_img: data.url_portraitImg,
          enrolled_profile_img: data.url_enrolledProfileImg,
        },
        index: data.index + 1,
        type,
      };

      return _data;
    },
  },
};
</script>

<style lang="scss" scoped>
.omniview-map {
  width: 100%;
  height: 100%;
}
</style>
