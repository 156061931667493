<template>
  <div>

    <table
      v-if="device.camera.camera_type == 'router'" 
      class="table table-sm"
    >
      <tr>
        <th>IMEI</th>
        <td>{{ device.camera.imei }}</td>
      </tr>
    </table>

    <table 
      v-if="device.camera.device_type == 'camera'"
      class="table table-sm"
    >
      <tr>
        <th>Rtsp</th>
        <td>{{ device.rtsp_available }}</td>
      </tr>
      <tr>
        <th>Delayed record minutes</th>
        <td>{{ device.camera.delayed_record_minutes }}</td>
      </tr>
      <tr>
        <th>Forward record minutes</th>
        <td>{{ device.camera.forward_record_minutes }}</td>
      </tr>
    </table>

    <table
      v-if="device.camera.camera_type == 'interphone'" 
      class="table table-sm"
    >
      <tr>
        <th>{{ $t('grid.slots.interphone.type') }}</th>
        <td>{{ device.camera.type_device_interphone.type }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.interphone.alarm') }}</th>
        <td>{{ device.camera.alarm }}</td>
      </tr>
        <tr>
        <th>Ivr</th>
        <td>{{ device.camera.ivr }}</td>
      </tr>
      <tr v-if="device.camera.user">
        <th>{{ $t('grid.slots.interphone.user') }}</th>
        <td>{{ device.camera.user }}</td>
      </tr>
    </table>

    <table 
      v-if="device.camera.camera_type == 'density_device'"
      class="table table-sm"
    >
      <tr>
        <th>Connection</th>
        <td>{{ setConnection() }}</td>
      </tr>
      <tr>
        <th>Tamper</th>
        <td>{{ setTampered() }}</td>
      </tr>
      <tr>
        <th>Shot num</th>
        <td>{{ device.camera.shot_num }}</td>
      </tr>
      <tr>
        <th>Armed</th>
        <td>{{ setArmed() }}</td>
      </tr>
      <tr>
        <th>Ready</th>
        <td>{{ setReady() }}</td>
      </tr>
      <tr>
        <th>Fluid level</th>
        <td>{{ device.camera.fluid_level }} %</td>
      </tr>
      <tr>
        <th>Boil temp</th>
        <td>{{ device.camera.boil_temp }} &deg;C</td>
      </tr>
      <tr>
        <th>Last shot</th>
        <td>{{ device.camera.last_shot }}</td>
      </tr>
    </table>

    <table
      v-if="device.camera.device_type == 'sensor'" 
      class="table table-sm"
    >
      <tr>
        <th>{{ $t('grid.slots.info.sensors.last_measurement') }}</th>
        <td>{{ device.camera.last_measurement +' '+ device.camera.abbrev }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    device: Object,
    required: true,
  },
  data() {
    return {
      connection: "",
      tampered: "",
      armed: "",
      ready: "",
    };
  },
  methods: {
    setConnection() {
      if (this.device.camera.connection === 1) {
        this.connection = "Online";
      } else {
        this.connection = "Offline";
      }

      return this.connection
    },
    setTampered() { 
      if (this.device.camera.tampered === 0) {
        this.tampered = "Ok";
      } else {
        this.tampered = "WARNING";
      }

      return this.tampered
    },
    setArmed() {
      if (this.device.camera.armed === 1) {
        this.armed = "Yes";
      } else {
        this.armed = "No";
      }

      return this.armed
    },
    setReady() {
      if (this.device.camera.ready === 1) {
        this.ready = "Ok";
      } else {
        this.ready = "WARNING";
      }

      return this.ready
    },
  }
};
</script>