<template>
  <b-modal
    id="subsystem_action_modal"
    title="Instalación"
    @show="onShow"
    @hidden="resetDataModal"
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
  >
    <p class="w-100 lead border-bottom pb-50">Activar salida digital</p>
    <div class="row py-2">
      <div class="col-md-12">
        Se activará la salida digital del router, ¿Deseas continuar?
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="secondary" @click="onCancel()"> Cancelar </b-button>
      <b-button variant="primary" @click="submit()"> Continuar </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BSpinner,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import axios from "@axios";

export default {
  components: {
    BSpinner,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      comments: "",
      loading: false,
      actionType: {},
      alarmLevelSelected: null,
      alarmLevelOptions: [],
    };
  },
  computed: {
    subsystemTreeModalData() {
      return store.state.subsystem_tree.subsystemTreeModal;
    },
  },
  methods: {
    showMessage(msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Acción de árbol de subsistemas",
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    onShow() {},
    async submit() {
      axios
        .get(this.subsystemTreeModalData.data.digital_output_link)
        .then((resp) => {
          this.showMessage("Se ha iniciado el proceso para activar la salida digital");
        })
        .catch((resp) => {
          this.showMessage(
            "Ocurrió un error durante el proceso para activar la salida digital",
            "danger"
          );
        })
        .finally(() => {
          this.closeModal();
          store.dispatch("subsystem_tree/resetSubsystemTreeModal");
        });
    },
    onCancel() {
      this.closeModal();
    },
    closeModal() {
      this.$bvModal.hide("subsystem_action_modal");
    },
    resetDataModal() {
      store.dispatch("subsystem_tree/resetSubsystemTreeModal");
    },
  },
};
</script>

<style></style>
