<template>
  <div class="layouts widget bg-white border-bottom">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">{{ title }}</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="24"
            />
          </template>
          <span class="handle cursor-move" v-if="isConfigModeOn">
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        :visible="false"
        v-model="isCollapseOpen"
        @input="onUpdateStatus"
      >
        <div
          :class="[
            'layouts-container d-flex flex-wrap justify-content-between',
            { 'flex-column': sidebarMinWidthReached },
          ]"
          v-if="userViews.length"
        >
          <div
            v-for="(view, index) in userViews"
            :key="index"
            :class="[
              'layout',
              {
                selected: checkSelectedView(view.data.id),
                'w-100': sidebarMinWidthReached,
                'w-50': !sidebarMinWidthReached,
              },
            ]"
            @click="setView(view)"
          >
            <layout-views
              :key="view.updated_at"
              :view="view"
              @remove-view="onRemoveView"
            />
          </div>
        </div>
        <div v-else>
          <p class="mb-0 py-1 lead text-center">{{ $t('no_record_found') }}</p>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import store from "@/store";
import LayoutViews from "./LayoutViews";
import Swal from "sweetalert2";

export default {
  components: {
    BCollapse,
  },
  components: {
    LayoutViews,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
    selectedLayout: {
      type: String | Number,
      default: null,
    },
  },
  data() {
    const layouts = [];

    return {
      layouts,
      isCollapseOpen: true,
      loading: true
    };
  },
  async mounted() {
    this.isCollapseOpen = this.isOpen;
  },
  watch: {
    userLayouts() {
      store.dispatch("grid/getUserViews");
    },
  },
  computed: {
    activeTabId() {
      return store.state.grid.activeTab;
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      console.log("tabsQty");
      return Object.keys(this.tabs).length;
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    userLayouts() {
      return store.getters["layouts/getUserLayouts"];
    },
    userViews() {
      return store.getters["grid/getUserViews"];
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    onRemoveView(id) {
      Swal.fire({
        title: this.$t("messages.delete.title"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("actions.delete"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store.dispatch("grid/removeView", { id }).then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: this.$t("messages.delete.deleted_correctly"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
          });
        }
      });
    },
    checkSelectedView(idView) {
      return this.activeTabId == idView;
    },
    setView(view) {
      const viewData = view.data;
      if (!Object.keys(this.tabs).includes(viewData.id)) {
        store.dispatch("grid/addNewTab", { viewData });
      }
      store.dispatch("grid/setActiveTab", viewData.id);
    },
  },
};
</script>
