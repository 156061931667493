<template>
  <b-table
    class="mb-0 alarms-widget__data-table"
    :items="items"
    :fields="visibleFields"
    primary-key="id"
    :busy="busy"
    show-empty
    small
    :empty-text="$t('grid.widgets.alarms.table.no_data')"
    responsive
    @row-dblclicked="onRowClick"
    :tbody-tr-class="rowClass"
  >
    <template #head()="data">
      <span> {{ $t(data.label) }}</span>
    </template>
    <template #cell(alarm_acronym)="row">
      <span
        class="px-2 badge cursor-pointer"
        :style="{ backgroundColor: row.item.alarm_level.color }"
        :id="`alarm-${row.item.id}`"
      >
        {{ row.item.alarm_acronym }}
      </span>
      <b-tooltip
        :target="`alarm-${row.item.id}`"
        :title="row.item.alarm_type"
        triggers="hover"
        variant="info"
      >
        {{ row.item.alarm_type }}
      </b-tooltip>
    </template>
    <template #cell(actions)="row">
      <div class="d-flex justify-content-end">
        <!-- Sensors popovers -->
        <div class="d-flex align-items-center">
          <feather-icon
            v-if="row.item.alarm_acronym === 'SD'"
            :id="`SD-${row.item.id}`"
            icon="UserIcon"
            size="18"
            @mouseover="configSD(row.item)"
          />
          <b-popover
            v-if="row.item.alarm_acronym === 'SD'"
            :target="`SD-${row.item.id}`"
            placement="right"
            triggers="hover"
            boundary="window"
          >
            <img
              style="width: 350px"
              :src="imgUrl"
              @click="openImageModal(imgUrl)"
              v-b-modal.image_modal
            />
          </b-popover>

          <!-- SIP CALL Indicator -->
          <feather-icon
            :id="`sip-call-button-${row.item.id}`"
            v-if="row.item.sip_call.length"
            icon="PhoneCallIcon"
            :stroke="
              row.item.sip_call[0].state == 'waiting'
                ? 'red'
                : row.item.sip_call[0].state == 'answered' ||
                  row.item.sip_call[0].state == 'transfer'
                ? 'orange'
                : 'gray'
            "
            size="18"
            :class="[
              {
                'on-call':
                  row.item.sip_call[0].state == 'waiting' ||
                  row.item.sip_call[0].state == 'answered'
                    ? true
                    : false,
              },
            ]"
          />
          <b-popover
            :target="`sip-call-button-${row.item.id}`"
            v-if="row.item.sip_call.length"
            placement="right"
            triggers="hover"
            boundary="window"
          >
            {{
              row.item.sip_call[0].destAddr
                ? row.item.sip_call[0].unit_user == undefined
                  ? `Llamada contestada por:${row.item.sip_call[0].remotePartyNumber}`
                  : `Llamada contestada por:${row.item.sip_call[0].unit_user}`
                : row.item.sip_call[0].state
            }}
          </b-popover>

          <!-- FR Eye Indicator -->
          <feather-icon
            :id="`fr-button-${row.item.id}`"
            v-if="row.item.alarm_acronym === 'FR'"
            icon="EyeIcon"
            size="18"
          />
          <b-popover
            :target="`fr-button-${row.item.id}`"
            v-if="row.item.alarm_acronym === 'FR'"
            placement="right"
            triggers="hover"
            boundary="window"
          >
            <carrousel :frData="row.item.facial_recognition" :firstImage="loadedImages" />
          </b-popover>

          <feather-icon
            :id="`lpr-button-${row.item.id}`"
            v-if="row.item.alarm_acronym === 'LPR' && row.item.lpr_data"
            icon="TruckIcon"
            size="18"
          />
          <b-popover
            :target="`lpr-button-${row.item.id}`"
            v-if="row.item.alarm_acronym === 'LPR' && row.item.lpr_data"
            placement="right"
            triggers="hover"
            boundary="window"
          >
            <carrousel-lpr
              :idAlarm="row.item.id"
              :lprData="row.item.lpr_data"
              :lprMatches="row.item.lpr_data.total_matches"
            />
          </b-popover>

          <!-- Generic detection event -->
          <detection-event-type
            v-if="
              row.item.detection_event_data &&
              row.item.detection_event_data.provider != 'luna' &&
              !excludedDetectionEventAlerts.includes(row.item.alarm_acronym)
            "
            :alarmData="row.item"
          />
          <!----------------------------->

          <!-- <b-link
            v-if="row.item.alarm_acronym === 'LPR' && row.item.lpr_data"
            v-b-popover.hover="configLPR(row.item.lpr_data)"
          >
            <feather-icon icon="TruckIcon" size="18" />
          </b-link> -->

          <!-- IntelixVision event indicator -->
          <!-- <b-link
            v-if="row.item.analytic_record ? true : false"
            v-b-popover.hover="configAR(row.item.analytic_record.media[0].url)"
          >
            <feather-icon icon="FilmIcon" size="18" />
          </b-link> -->

          <!-- IntelixVision carrousel -->
          <feather-icon
            :id="`ar-button-${row.item.id}`"
            v-if="row.item.analytic_record ? true : false"
            icon="FilmIcon"
            size="18"
          />
          <b-popover
            :target="`ar-button-${row.item.id}`"
            v-if="row.item.analytic_record ? true : false"
            placement="right"
            triggers="hover"
            boundary="window"
          >
            <carrousel-ar
              :arData="row.item.analytic_record"
              :arMatches="row.item.analytic_record_count"
            />
          </b-popover>
        </div>

        <!-- Alarm dropdown options -->
        <b-dropdown
          text="Ver"
          ref="dropdown"
          class="m-0 p-0"
          size="sm"
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="18" />
          </template>
          <template
            v-if="
              // (filters.waiting || filters.filter === 'waiting') &&
              $can('update_attention', 'Alarm')
            "
          >
            <b-dropdown-item @click="onAlarmClickAction(row, 'confirmed')">
              <span class="text-success">
                <feather-icon class="mr-50" icon="CheckCircleIcon" size="13" />
                {{ $t("grid.widgets.alarms.menu.confirm") }}
              </span>
            </b-dropdown-item>
          </template>
          <template
            v-if="
              // (filters.attending || filters.filter === 'attending') &&
              $can('update_attend', 'Alarm')
            "
          >
            <b-dropdown-item @click="onAlarmClickAction(row, 'attended')">
              <span class="text-success">
                <feather-icon class="mr-50" icon="CheckCircleIcon" size="13" />
                {{ $t("grid.widgets.alarms.menu.attended") }}
              </span>
            </b-dropdown-item>
          </template>

          <b-dropdown-item
            v-if="$can('update_cancel', 'Alarm')"
            @click="onAlarmClickAction(row, 'canceled')"
          >
            <span class="text-danger">
              <feather-icon icon="XCircleIcon" class="mr-50" size="13" />
              {{ $t("grid.widgets.alarms.menu.cancel") }}
            </span>
          </b-dropdown-item>

          <!-- <b-dropdown-item @click="onAlarmClickConfig(row)">
            <span class="text-info">
              <feather-icon icon="GridIcon" class="mr-50" size="13" />
              {{ $t("grid.widgets.alarms.menu.display") }}
            </span>
          </b-dropdown-item> -->
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>

<script>
import store from "@/store";
import axios from "@axios";
import { EventBus } from "@/libs/event-bus";
import Carrousel from "./Carrousel.vue";
import CarrouselAR from "./CarrouselAR.vue";
import CarrouselLPR from "./CarrouselLPR.vue";
import DetectionEventType from "./DetectionEventType.vue";
import dateParser from "@/libs/date-parser";

import {
  BTable,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BLink,
  VBPopover,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BLink,
    Carrousel,
    "carrousel-ar": CarrouselAR,
    "carrousel-lpr": CarrouselLPR,
    DetectionEventType,
  },
  directives: {
    "b-popover": VBPopover,
  },
  props: {
    items: Array,
    location: String,
    busy: Boolean,
    filters: Object,
  },
  data() {
    return {
      fields: [
        {
          key: "alarm_acronym",
          label: "grid.widgets.alarms.table.rows.1",
          minSize: 300,
        },
        {
          key: "unit.description",
          label: "grid.widgets.alarms.table.rows.2",
          minSize: 300,
        },
        {
          key: "detection_event_data.camera_data.camera.name",
          label: "grid.widgets.alarms.table.rows.3",
          minSize: 300,
        },
        {
          key: "unit.region.name",
          label: "grid.widgets.alarms.table.rows.4",
          minSize: 350,
        },
        {
          key: "created_format_1",
          label: "grid.widgets.alarms.table.rows.5",
          minSize: 450,
          formatter: (value, key, item) => {
            return dateParser.parseDateTimezone(value);
          },
        },
        { key: "actions", label: "", minSize: 300 },
      ],
      totalMatches: [],
      loadedImages: [],
      excludedDetectionEventAlerts: ["LPR"],
      imgUrl: null,
      selected: null,
    };
  },
  watch: {
    items() {
      let items = this.$props.items;
      const filteredArray = items.filter(
        (obj) =>
          obj.hasOwnProperty("facial_recognition") && obj.facial_recognition !== null
      );

      filteredArray.forEach(({ facial_recognition }) => {
        (async () => {
          const { data } = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/v1/luna/getAlarmDataFRFirstMatch`,
            {
              alarmId: facial_recognition.alarm_id,
            }
          );
          const snapshot = data.data;
          this.loadedImages["fr_" + facial_recognition.alarm_id] = snapshot;
        })();
      });

      this.$props.items.forEach(async (row) => {
        if (row.sip_call.length) {
          if (row.sip_call[0].destAddr) {
            let unit_user = row.sip_call[0].remotePartyNumber;
            store
              .dispatch("sip/getDevice", {
                device_id: row.sip_call[0].remotePartyNumber,
              })
              .then((response) => {
                if (response.device) {
                  if (response.device.units) {
                    if (response.device.units.length) {
                      unit_user += " - " + response.device.units[0].description;
                    }
                  }
                } else if (response.user) {
                  unit_user += " - " + response.user.email;
                }
                this.$set(row.sip_call[0], "unit_user", unit_user);
              })
              .catch((e) => {
                console.log({ e });
              });
          }
        }
      });
    },
  },
  computed: {
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
    sidebarWidth() {
      if (this.location === "sideBarRigth") {
        return store.state.grid.sidebarRightWidth;
      }

      return store.state.grid.sidebarWidth;
    },
    visibleFields() {
      return this.fields.filter((f) => f.minSize <= this.sidebarWidth);
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return this.tabs ? Object.keys(this.tabs).length : 0;
    },
    selectedAlarms() {
      return store.getters["grid/getVisited"];
    },
  },
  methods: {
    onAlarmClickConfig({ item }) {
      const selectedAlarm = item;
      const cameras = selectedAlarm.cameras;

      store.dispatch("grid/showLayoutModal", {
        source: "alarm",
        data: {
          obj: selectedAlarm,
          cameras,
        },
      });

      this.$root.$emit("bv::show::modal", "layout_modal");
    },
    async onAlarmClickAction({ item }, action) {
      await store.dispatch("grid/setSelectedAlarm", { action, alarm: item });
      this.$root.$emit("bv::show::modal", "alarm_action_modal");
    },
    configSD(alarm) {
      this.imgUrl = `${alarm.host}/v1/events/image/${alarm.smart_detection_id}`;
      return this.imgUrl;
    },
    configLPR(lprData) {
      return {
        html: true,
        boundary: "window",
        content: () => {
          return `<img style="width: 350px;" src="${lprData.general_image_url}">`;
        },
      };
    },
    async loadImg(url) {
      return new Promise(async (resolve) => {
        const config = { url, method: "get", responseType: "blob" };
        const blob = await axios.request(config);
        const reader = new FileReader();
        reader.addEventListener("loadend", () => resolve(reader.result));
        reader.readAsDataURL(blob.data);
      });
    },
    onRowClick(item) {
      if (this.location === "sideBarRigth") {
        if (this.$can("show_alarm_info_in_right_sidebar", "Alarm")) {
          store.dispatch("grid/setAlarmId", item.id);
          store.commit("psimConfig/UPDATE_TOOLBARS_COLLAPSED_RIGHT", 0);
          store.commit("psimConfig/UPDATE_TOOLBARS_COLLAPSED", 1);
          store.dispatch("grid/addVisited", { id: item.id, type: "alarm" });
        }

        if (this.$can("show_alarm_info_in_right_sidebar_and_tab", "Alarm")) {
          store.dispatch("grid/setAlarmView", { alarm: item });
        }

        this.selected = item.id;
        return;
      }

      if (this.tabsQty < store.state.app.maxTabs) {
        store.dispatch("grid/setAlarmView", { alarm: item });
      }
      this.selected = item.id;
    },
    // onClick(item) {
    //   store.dispatch("grid/setAlarmView", {alarm: item});
    //   store.dispatch("grid/setAlarmId", item.id);
    //   store.commit("psimConfig/UPDATE_TOOLBARS_COLLAPSED_RIGHT", 0);
    //   store.commit("psimConfig/UPDATE_TOOLBARS_COLLAPSED", 1);
    //   store.dispatch("grid/addVisited", { id: item.id, type: "alarm" });
    // },
    configAR(url) {
      return {
        html: true,
        boundary: "window",
        content: () => {
          return `<img style="width: 350px;" src="${url}">`;
        },
      };
    },
    async getDeviceUnitUser(user) {
      let unit_user = user;
      store
        .dispatch("sip/getDevice", {
          device_id: user,
        })
        .then((response) => {
          if (response.device.units) {
            if (response.device.units.length) {
              unit_user += " - " + response.device.units[0].description;
            }
          } else if (response.user) {
            unit_user += " - " + response.user.email;
          }
          //row.item.sip_call[0].unit_user = unit_user;
          return unit_user;
        })
        .catch((e) => {
          console.log({ e });
        });
    },
    async openImageModal(imageUrl) {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;

      if (this.selected === item.id) return "selected-row";

      if (
        this.selectedAlarms &&
        this.selectedAlarms.find((v) => v.id === item.id && v.type === "alarm")
      ) {
        return "visited-row";
      }
    },
  },
  mounted() {
    EventBus.on(`show-alarm-modal`, (params) => {
      this.$root.$emit("bv::show::modal", params);
    });

    EventBus.on(`show-alarm-config`, (params) => {
      this.onAlarmClickConfig({ item: params });
    });

    EventBus.on(`show-alarm-row`, (params) => {
      this.onRowClick(params);
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/variables";
.popover {
  max-width: none !important;

  .popover-body {
    padding: 10px;
  }
}

[role="row"] {
  cursor: pointer;
}

.selected-row {
  background-color: $info;
  color: white;
}

.visited-row {
  background-color: $slate-gray;
  color: white;
}

.alarms-widget {
  .table-responsive {
    min-height: 145px;
  }

  .table.table-sm td {
    padding: 0 0.2rem !important;
    max-height: 50px;

    .btn.dropdown-toggle {
      padding: 0.1rem 1rem;
    }
  }
}

.selectable {
  cursor: pointer;
}

.on-call {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
</style>
