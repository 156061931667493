var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layouts widget bg-white border-bottom"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('div',{staticClass:"widget-header border-bottom"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.collapsible ? ("main-accordion-" + _vm.id) : ''),expression:"collapsible ? `main-accordion-${id}` : ''"}],staticClass:"m-0 h4 d-flex"},[_c('p',{staticClass:"mb-0 mx-auto text-center"},[_vm._v(_vm._s(_vm.title))]),(_vm.collapsible)?[(!_vm.isConfigModeOn)?_c('feather-icon',{attrs:{"icon":_vm.isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon',"size":"24"}}):_vm._e()]:_vm._e(),(_vm.isConfigModeOn)?_c('span',{staticClass:"handle cursor-move"},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"24"}})],1):_vm._e()],2)]),_c('b-collapse',{attrs:{"id":("main-accordion-" + _vm.id),"accordion":("main-accordion-" + _vm.id),"role":"tabpanel","visible":false},on:{"input":_vm.onUpdateStatus},model:{value:(_vm.isCollapseOpen),callback:function ($$v) {_vm.isCollapseOpen=$$v},expression:"isCollapseOpen"}},[(_vm.userViews.length)?_c('div',{class:[
          'layouts-container d-flex flex-wrap justify-content-between',
          { 'flex-column': _vm.sidebarMinWidthReached } ]},_vm._l((_vm.userViews),function(view,index){return _c('div',{key:index,class:[
            'layout',
            {
              selected: _vm.checkSelectedView(view.data.id),
              'w-100': _vm.sidebarMinWidthReached,
              'w-50': !_vm.sidebarMinWidthReached,
            } ],on:{"click":function($event){return _vm.setView(view)}}},[_c('layout-views',{key:view.updated_at,attrs:{"view":view},on:{"remove-view":_vm.onRemoveView}})],1)}),0):_c('div',[_c('p',{staticClass:"mb-0 py-1 lead text-center"},[_vm._v(_vm._s(_vm.$t('no_record_found')))])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }