<template>

  <carousel
    :v-bind="imagesLoaded"
    style="width: 350px"
    :per-page="1"
    :adjustableHeight="false"
    paginationPosition="bottom-overlay"
    :paginationEnabled="false"
    :centerMode="true"
    paginationActiveColor="#1C3D5E"
    :navigationEnabled="true"
    @page-change="pageChange"
    class="carrousel-alarm-table"
  >
    <div v-if="!imagesLoaded">Cargando...</div>
    <slide
      v-for="(value, key) in imagesLoaded"
      :key="`slide-${key}`"
      style="width: 350px; min-height: 150px; position: relative;"
      @slide-click="slideClick(value.url)"
      v-b-modal.image_modal
    >
      <div style="position: absolute; width: 100%; height: 100%; z-index: 3;" v-if="value && value.cameraName">
        <div style="
              color: white;
              width: 100%;
              background-color: rgba(0,0,0,0) !important;
              text-shadow: 1px 2px #000;
              padding: .5rem;
              top: 0;
              background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 0.5) 95%);">
          <span>{{ value.cameraName }}</span>
          <span style="float: right;">{{ dateParser.parseDateTimezone(value.created_parsed) }}</span>
        </div>

        <div style="
              position: absolute;
              color: white;
              width: 100%;
              background-color: rgba(0,0,0,0) !important;
              text-shadow: 1px 2px #000;
              padding: .5rem;
              bottom: 0;
              background: linear-gradient(transparent, rgba(0, 0, 0, 0.5))">
          <span style="bottom: 0;">{{ value.name }}</span>
        </div>
      </div>

      <img style="width: 350px" :src="value.url" v-if="value.url" />
      <b-spinner v-else label="Spinning" variant="primary" style="margin-left: calc(50% - 10px); margin-top: 70px;width: 3rem; height: 3rem;"></b-spinner>

    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import axios from "@axios";
import store from "@/store";
import dateParser from "@/libs/date-parser";

export default {
  name: "Carrousel",
  components: {
    Carousel,
    Slide,
  },
  props: {
    frData: Object,
    firstImage: Array
  },
  data() {
    return {
      imagesLoaded: [],
      totalMatches: 1,
      dateParser,
    };
  },
  methods: {
    async loadImg(url) {
      return new Promise(async (resolve) => {
        const config = { url, method: "get", responseType: "blob" };
        const blob = await axios.request(config);
        const reader = new FileReader();
        reader.addEventListener("loadend", () => resolve(reader.result));
        reader.readAsDataURL(blob.data);
      });
    },
    pageChange(pageNumber) {
      axios.post(`${process.env.VUE_APP_BASE_URL}/v1/luna/getAlarmDataFRMatchByIndex`, {
          alarmId: this.$props.frData.alarm_id,
          index: pageNumber + 1,
        }).then( (res) => {
          const snapshot = res.data.data
          this.imagesLoaded[pageNumber] = snapshot
          this.$set(this.imagesLoaded, pageNumber, snapshot);
        });
    },
    async slideClick(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    }
  },
  mounted() {
    this.imagesLoaded = [];
    this.imagesLoaded.push(this.$props.firstImage[`fr_${this.$props.frData.alarm_id}`]);

    this.totalMatches = this.$props.firstImage[`fr_${this.$props.frData.alarm_id}`].total_matches;

    for (let index = 1; index < this.totalMatches - 1; index++) {
      this.imagesLoaded[index] = {};
    }
  },
};
</script>

<style>
 .carrousel-alarm-table .VueCarousel-navigation-prev {
    left: 40px;
  }

  .carrousel-alarm-table .VueCarousel-navigation-next {
    right: 40px;
  }
</style>
