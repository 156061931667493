<template>
  <div style="overflow-x: hidden">
    <div>
      <alarms-widget
        :title="$t('grid.widgets.alarms.incoming_alarms.title')"
        :filters="{ filter: 'waiting' }"
        :id="`alarm-${tabId}`"
        :key="`alarm-${tabId}`"
        :parent-collapsed="isToolbarsCollapsed"
        :collapsible="true"
        :isOpen="true"
        location="sideBarRigth"
      />
    </div>
    <div
      style="position: relative; height: 100%"
      :class="[{ 'show-remove-btn': showRemoveBtn }]"
      id="alarm-data-container"
    >
      <div style="height: 60%" id="timeline-alarm-data">
        <div style="display: none" class="backdrop" id="backdrop"></div>
        <cam-slot
          v-if="alarmId && cameraInfo.data"
          :parent-id="tabId"
          :data="cameraInfo.data"
          :type="cameraInfo.type"
          :tab-info="tabInfo"
          :pos="1"
          :key="`${cameraInfo.type}:${cameraInfo.data.camera_proxy_id}`"
          :style="`height: ${cameraHeight}%;`"
        />
        <timeline-slot
          v-if="alarInfo"
          :tab-info="tabInfo"
          :style="`height: ${timelineHeight}%;`"
          :filters="{
            event_filter: { event: alarInfo.alarm_acronym, ts: alarInfo.created },
          }"
          source="sidebar-right"
          :key="`tml-${alarmId}`"
        />
        <div class="remove-btn">
          <div>
            <feather-icon
              class="icon"
              icon="XIcon"
              size="48"
              @click="remove()"
              v-b-tooltip="'Cerrar'"
            />
            <feather-icon
              class="icon"
              :icon="!fullScreenEnabled ? 'Maximize2Icon' : 'Minimize2Icon'"
              size="40"
              @click="goFullScreen"
              v-b-tooltip="'Pantalla completa'"
            />
          </div>
        </div>
      </div>
      <div style="height: 40%">
        <info-slot
          v-if="alarmId && alarInfo"
          :data="alarInfo"
          :tab-info="tabInfo"
          style="height: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import { v4 as uuidv4 } from "uuid";
import CamSlot from "./layouts/slots/CamSlot.vue";
import InfoSlot from "./layouts/slots/InfoSlot";
import TimelineSlot from "./layouts/slots/TimelineSlot.vue";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import { ref, computed, watch } from "@vue/composition-api";
import AlarmsWidget from "./widgets/AlarmsWidget";
import { EventBus } from "@/libs/event-bus";

export default {
  components: {
    CamSlot,
    InfoSlot,
    TimelineSlot,
    AlarmsWidget,
  },
  setup(_, context) {
    const tabId = uuidv4();

    const fullScreenEnabled = ref(false);

    const cameraHeight = ref(60);
    const timelineHeight = ref(40);

    const tabInfo = computed(() => ({
      id: tabId,
      type: SLOT_TYPES.TIMELINE,
      slots: [],
    }));

    const cameraInfo = ref({
      type: SLOT_TYPES.CAM_RECORDED,
    });

    const alarInfo = ref(null);
    const alarmId = computed(() => store.state.grid.alarm_id);
    const alarmDataContainer = ref(null);
    const isToolbarsCollapsed = computed(
      () => store.state.psimConfig.isToolbarsCollapsedRight === 0
    );

    watch([alarmId], () => {
      if (alarmId.value) {
        refetchData();
      }
    });

    const refetchData = () => {
      alarInfo.value = null;

      axios
        .get(`${process.env.VUE_APP_BASE_URL}/v1/alarm/${alarmId.value}`)
        .then((result) => {
          let data = result.data.data;
          alarInfo.value = data;
          alarInfo.value.data = {
            ...data,
            in_app_source: SOURCES.ALARM_VIEW,
          };

          const resolveCamera = (camera) => {
            return (
              camera.camera_proxy_id == data.detection_event_data?.id_camera_proxy ||
              camera.camera_proxy_id ==
                data.detection_event_data?.camera_data.camera.id_camera_proxy
            );
          };

          if (data.detection_event_data) {
            cameraInfo.value.data = JSON.parse(
              JSON.stringify(data.cameras.find(resolveCamera))
            );
            cameraInfo.value.data.camera = JSON.parse(
              JSON.stringify(cameraInfo.value.data)
            );
          } else {
            cameraInfo.value.data = JSON.parse(JSON.stringify(data.cameras[0]));
            cameraInfo.value.data.camera = JSON.parse(JSON.stringify(data.cameras[0]));
          }

          tabInfo.value.slots.push({
            type: SLOT_TYPES.INFO,
            data,
          });

          tabInfo.value.slots.push(cameraInfo.value);

          setTimeout(() => {
            EventBus.emit(
              `timeline:${tabInfo.value.id}:sidebar:set_main_camera`,
              {
                camera: {
                  ...cameraInfo.value.data.camera,
                  cameraId: cameraInfo.value.data.camera.camera_id,
                },
                tabInfo,
              },
              2000
            );
          });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const remove = () => {
      store.dispatch("grid/setAlarmId", null);
    };

    const goFullScreen = () => {
      if (!fullScreenEnabled.value) {
        document.querySelector("#timeline-alarm-data").requestFullscreen();
        fullScreenEnabled.value = true;
        cameraHeight.value = 80;
        timelineHeight.value = 20;
      } else {
        fullScreenEnabled.value = false;
        document.exitFullscreen();
        cameraHeight.value = 60;
        timelineHeight.value = 40;
      }
    };

    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        fullScreenEnabled.value = false;
        cameraHeight.value = 60;
        timelineHeight.value = 40;
      } else {
        cameraHeight.value = 80;
        timelineHeight.value = 20;
      }
    };

    document.addEventListener("fullscreenchange", onFullScreenChange);

    let showRemoveBtn = computed(() => {
      return store.state.grid.showRemoveBtn;
    });

    EventBus.on("alarm:attending", (alarmId) => {
      if (alarmId == alarmId) {
        remove();
      }
    });

    EventBus.on("alarm:attended", (alarmId) => {
      if (alarmId == alarmId) {
        remove();
      }
    });

    EventBus.on("alarm:canceled", (alarmId) => {
      if (alarmId == alarmId) {
        remove();
      }
    });

    return {
      tabId,
      tabInfo,
      alarmId,
      alarInfo,
      cameraInfo,
      remove,
      goFullScreen,
      fullScreenEnabled,
      showRemoveBtn,
      alarmDataContainer,
      cameraHeight,
      timelineHeight,
      isToolbarsCollapsed,
    };
  },
};
</script>

<style lang="scss">
.side-bar {
  display: flex;
  flex-flow: column;

  .sortable-ghost {
    width: 50%;
    height: auto;
    opacity: 0.5;
    background: rgba(0, 145, 255, 0.81);
  }

  .sortable-chosen {
    width: 100%;
  }
}

.remove-btn {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.5);
  z-index: 999;

  .icon {
    color: #fff;
    cursor: pointer;
  }
}

.show-remove-btn {
  .remove-btn {
    display: flex;
  }
}
</style>
