var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{staticClass:"side-bar",attrs:{"handle":".handle","group":"dashboard"},on:{"start":_vm.onStart,"end":_vm.onEnd},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},[_vm._l((_vm.items),function(item){return [(
        item.componentName &&
        item.componentName === 'alarms-widget' &&
        _vm.shouldShowWidget(item)
      )?[_c(item.componentName,{key:item.id,tag:"component",attrs:{"title":_vm.resolveWidgetTitle(item.title),"filters":item.filters,"id":item.id,"collapsible":item.collapsible,"isOpen":item.isOpen,"location":"sideBar"},on:{"widget-collapsed":_vm.onWidgetCollapse}})]:(
        item.componentName &&
        item.componentName === 'tenants-widget' &&
        _vm.shouldShowWidget(item)
      )?[_c(item.componentName,{key:("tenants-widget-" + (item.id)),tag:"component",attrs:{"title":_vm.resolveWidgetTitle(item.componentName),"id":item.id,"collapsible":item.collapsible,"isOpen":item.isOpen,"location":"sideBar"},on:{"widget-collapsed":_vm.onWidgetCollapse}})]:(item.componentName && _vm.shouldShowWidget(item))?[_c(item.componentName,{key:item.id,tag:"component",attrs:{"title":_vm.resolveWidgetTitle(item.componentName),"id":item.id,"collapsible":item.collapsible,"isOpen":item.isOpen,"location":"bottomBar"},on:{"widget-collapsed":_vm.onWidgetCollapse}})]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }