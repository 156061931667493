<template>
  <div class="units-widget widget bg-white" style="position: relative">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">{{ title }}</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
              size="24"
            />
          </template>
          <span
            class="handle cursor-move"
            v-if="isConfigModeOn"
            :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
          >
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        visible
        v-model="isCollapseOpen"
      >
        <div class="accordion" role="tablist" v-if="items.length">
          <b-card
            no-body
            class="mb-0 rounded-0 shadow-none"
            style="border-bottom: 1px solid #eee"
            v-for="(item, index) in items"
            :key="index"
          >
            <b-card-header header-tag="header" class="p-0" role="tab">
              <div class="w-100 mb-0 text-center" v-b-toggle="`unit-groups-accordion-${index}`">
                <div class="m-0 p-25 h4 d-flex">
                  <p class="h5 mb-0 mx-auto p-25">
                    <span>{{ item.name }}</span>
                  </p>
                  <feather-icon
                    :icon="items[index].shown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    size="24"
                  />
                </div>
              </div>
            </b-card-header>

            <b-collapse
              :id="`unit-groups-accordion-${index}`"
              :accordion="`unit-groups-main-accordion-${id}`"
              role="tabpanel"
              v-model="items[index].shown"
            >
              <!-- ================================= -->
              <div class="border py-25">
                <unit-group
                  ref="tree"
                  :data="items[index].data"
                  mode="widget"
                  role="unit_group"
                />
              </div>
              <!-- ================================= -->
            </b-collapse>
          </b-card>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BCollapse,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  VBToggle,
} from "bootstrap-vue";
import { debounce } from "lodash";
import UnitGroup from "@/components/UnitGroup";
import store from "@/store";
import axios from "@axios";

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BTable,
    UnitGroup,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      required: false,
      type: Number | String,
    },
    location: {
      type: String,
      default: "bottomBar",
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    TreeMode: {
      type: String,
      default: "widget",
    },
    role: {
      type: String,
      default: "disabled",
    },
  },
  data() {
    return {
      isCollapseOpen: true,
      searchQuery: "",
      searchBarEnabled: false,
      results: [],
      tenantsResults: [],
      unitsResults: [],

      // ------

      isBusy: false,
      fields: [
        {
          key: "data",
          label: this.$t("grid.widgets.units.table.rows.1"),
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.isCollapseOpen = this.isOpen;
    axios.get("/v1/unit-groups").then(({ data }) => {
      this.items = data.data;
    });
  },
  // watch: {
  //   sidebarWidth() {
  //     // this.$refs.searchBarContainer.style.width = `${this.$el.offsetWidth - 20}px`;
  //   },
  // },
  computed: {
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
    sidebarWidth() {
      return store.state.grid.sidebarWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.units-widget .table.table-sm td {
  padding: 0 0.5rem !important;
  max-height: 70px;
}
</style>
