<template>
    <weather-widget />
</template>

<script>
import WeatherWidget from '../../widgets/WeatherWidget.vue';

export default {
    components: { WeatherWidget },
    setup () {


        return {}
    }
}
</script>
