<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <div class="d-flex align-items-center mb-1">
      <b-avatar
        size="35"
        :src="chat.image"
        badge
        class="badge-minimal mr-2 "
        :badge-variant="chat.online ? 'success': 'danger'"
        variant="primary"
      />
  
      <h6 class="mb-0">
        {{ `${chat.name} ${chat.first_name} ${chat.second_name}` }}
      </h6>
      <p class="card-text text-truncate">
        {{ isChatContact ? chat.last_message : '' }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(chat.unread_from, { hour: 'numeric', minute: 'numeric' }) }}</small>
      <b-badge
        v-if="chat.unreads"
        pill
        variant="primary"
        class="float-right"
      >
        {{ chat.unreads }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    chat: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup(props) {
    return { formatDateToMonthShort }
  },
}
</script>

<style>

</style>
