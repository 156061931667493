<template>
  <div class="layouts widget bg-white border-bottom">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">Video Wall</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="24"
            />
          </template>
          <span class="handle cursor-move" v-if="isConfigModeOn">
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        :visible="false"
        v-model="isCollapseOpen"
        @input="onUpdateStatus"
      >
        <div class="widgets-container d-flex flex-wrap justify-content-around">
          <b-table
            class="mb-0 alarms-widget__data-table"
            :items="userVideoWall"
            :fields="fields"
            primary-key="id"
            :busy="isBusy"
            show-empty
            small
            :empty-text="$t('grid.widgets.alarms.table.no_data')"
            responsive
          >
            <template #head(name)="data">
              <span> {{ $t(data.label) }}</span>
            </template>
            <template #cell(active_sesion_video_wall)="row">
              <span
                :class="[
                  `text-${
                    row.item.active_sesion_video_wall === 'on' ? 'success' : 'danger'
                  }`,
                ]"
              >
                <feather-icon icon="CircleIcon" size="14" class="align-middle" />
              </span>
            </template>
            <template #cell(viewname)="row">
              <b-button
                size="sm"
                variant="outline-primary"
                v-if="row.item.view"
                :disabled="isBusy"
                @click="setView(row.item.view)"
              >
                <feather-icon icon="LayoutIcon" size="14" class="align-middle" />
              </b-button>
            </template>
          </b-table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle, BTable, BButton } from "bootstrap-vue";
import layoutMixin from "@/mixins/layoutMixin";
import store from "@/store";
import axios from "@axios";
import LayoutApi from "@/libs/LayoutApi";

const layoutApi = new LayoutApi(store);

export default {
  mixins: [layoutMixin],
  components: {
    BCollapse,
    VBToggle,
    BTable,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
    selectedLayout: {
      type: String | Number,
      default: null,
    },
  },
  data() {
    return {
      showMenu: false,
      selectedIndex: null,
      isCollapseOpen: true,
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "commands.gridWidget.table.rows.1",
          minSize: 300,
        },
        {
          key: "active_sesion_video_wall",
          label: "Status",
          minSize: 300,
        },
        {
          key: "viewname",
          label: "",
          minSize: 300,
        },
      ],
    };
  },
  mounted() {
    this.isCollapseOpen = this.isOpen;
    store.dispatch("video_wall/uservideowall");
    store.dispatch("video_wall/ping");

    setTimeout(() => {
      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log(channel, data);
      });

      if (!window.Echo.connector.socket.connected) window.Echo.connect();

      window.Echo.join("video-wall-users")
        .leaving((user) => {
          store.dispatch("video_wall/setVideoWallUserStatus", {
            id_user: user.id,
            status: "offline",
          });
        })
        .listen(".update", (data) => {
          store.dispatch("video_wall/setVideoWallUserStatus", {
            id_user: data.id_user,
            status: data.status,
          });
        });
    }, 1000);
  },
  beforeDestroy() {
    window.Echo.leave(`video-wall-users`);
  },
  computed: {
    user() {
      return store.getters["user/getUser"];
    },
    userVideoWall() {
      return store.getters["video_wall/getItems"];
    },
    userViews() {
      return store.getters["grid/getUserViews"].map((userv) => ({
        label: userv.name,
        value: userv.id,
      }));
    },
    currentTab() {
      return store.getters["grid/currentTab"] ? store.getters["grid/currentTab"] : {};
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return Object.keys(this.tabs).length;
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
  },

  methods: {
    async setView(view) {
      this.isBusy = true;
      const { data } = await axios.get(`/v1/video-wall/view/${view.id}`);
      const resp = data.data;
      const tabData = resp.data;
      tabData.id = resp.tabId;

      await layoutApi
        .addNewTab(null, null, tabData)
        .then(() => layoutApi.setActiveTab(tabData.id));
      this.isBusy = false;
    },

    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
  },
};
</script>

<style scoped></style>
