<template>
  <div class="layouts widget bg-white border-bottom">
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">{{ title }}</p>
          <template v-if="collapsible">
            <feather-icon v-if="!isConfigModeOn" :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="24" />
          </template>
          <span class="handle cursor-move" v-if="isConfigModeOn">
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
      </div>
      <b-collapse :id="`main-accordion-${id}`" :accordion="`main-accordion-${id}`" role="tabpanel" :visible="false"
        v-model="isCollapseOpen" @input="onUpdateStatus">
        <div :class="[
          'layouts-container d-flex flex-wrap justify-content-between chat-application',
          { 'flex-column': sidebarMinWidthReached },
        ]" v-if="chats.length">
          <div class="layout-container sidebar-content" style="width: 100%;">
            <vue-perfect-scrollbar :settings="perfectScrollbarSettings"
              class="chat-user-list-wrapper list-group scroll-area" style="width: 100%; height: auto;">

              <!--Chats Title Groups-->
              <h4 class="chat-list-title" style="margin-top: 1rem;">
                {{$t("chat.chats_group")}} 
                <feather-icon 
                  v-if="$can('create', 'ChatGroup')"
                  @click="showAddModal()" 
                  class="float-right mr-1 cursor-pointer" 
                  icon="PlusCircleIcon" 
                  size="20"
                />
              </h4>

              <!--Chat Groups -->
              <ul class="chat-users-list chat-list media-list">
                <li v-for="group in chatGroup" >
                  <div class="d-flex align-items-center mb-1">
                    <div @click="openChat(group)" class="d-flex align-items-center">
                      <b-avatar icon="people-fill" size="35" class="mr-1 cursor-pointer badge-minimal" badge badge-variant='success' variant="primary"/>
                      <h6 class="m-auto"><span>{{group.name}}</span></h6>
                    </div>
                    <b-dropdown variant="link" toggle-class="text-decoration-none p-0">
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="15"
                          class="text-body float-right"
                        />
                      </template>

                      <b-dropdown-item 
                        v-if="$can('update', 'ChatGroup')"
                        @click="showEditModal(group)" 
                      >
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50 text-capitalize">{{
                            $t("actions.edit")
                          }}</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('delete', 'ChatGroup')"
                        @click="confirmDelete(group)"
                      >
                        <feather-icon icon="TrashIcon"/>
                        <span class="align-middle ml-50 text-capitalize">{{
                            $t("actions.delete")
                          }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </li>
              </ul>
      
              <!-- Chats Title -->
              <h4 class="chat-list-title" style="margin-top: 1rem;">
                {{$t("chat.chats")}} 
              </h4>

              <!-- Chats -->
              <ul class="chat-users-list chat-list media-list">
                <chat-contact v-for="chat in chats" v-if="chat.last_message" :key="chat.id" :chat="chat" tag="li"
                  :class="{ 'active': chat.online }" is-chat-contact @click="openChat(chat)" />
              </ul>

              <!-- Contacts Title -->
              <h4 class="chat-list-title" style="margin-top: 1rem;">
                {{$t("chat.contacts")}} 
              </h4>

              <!-- Contacts -->
              <ul class="chat-users-list contact-list media-list">
                <chat-contact v-for="chat in chats" v-if="!chat.last_message" :key="chat.id" :chat="chat" tag="li"
                  :class="{ 'active': chat.online }" @click="openChat(chat)" />
              </ul>
            </vue-perfect-scrollbar>
          </div>
        </div>
        <div v-else>
          <p class="mb-0 py-1 lead text-center">{{ message }}</p>
        </div>
      </b-collapse>
      <div>
        <b-modal
          class="text-capitalize"
          id="chat_group"
          hide-header
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
        >
          <h5 class="mb-0 text-capitalize">
            {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
          </h5>
          <template>
            <validation-observer v-slot="{ handleSubmit }">
              <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(onSubmit)" ref="createChatGroup">

                <!--Name Chat Group-->
                <validation-provider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group 
                    class="text-capitalize"
                    :label="$t('chat.group_name')" 
                    label-for="name">
                    <b-form-input
                      id="name"
                      v-model="name"
                      trim
                    />
                    <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
                  </b-form-group>
                </validation-provider>

                <!--Users Chat Group-->
                <validation-provider
                  name="users"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group 
                    class="text-capitalize"
                    :label="$t('chat.users')" 
                    label-for="users"
                  >
                    <v-select
                      v-model="users"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="chatUsers"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      input-id="tenant"
                      multiple
                    />
                    <p v-if="errors" class="small text-danger">{{ errors[0] }}</p>
                  </b-form-group>
                </validation-provider>

                <b-button
                  variant="primary"
                  class="mr-1 text-capitalize"
                  type="submit"
                >
                  {{ isAdd ? `${$t("actions.add")}` :`${$t("actions.edit")}` }}
                </b-button>
                <b-button
                  type="button"
                  variant="outline-secondary"
                  @click="onCancel()"
                  class="text-capitalize"
                >
                  {{$t('actions.cancel')}}
                </b-button>
              </b-form>
            </validation-observer>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import store from "@/store";
import ChatContact from './ChatContact.vue'
import LayoutApi from "@/libs/LayoutApi.js";
import { SLOT_TYPES } from "@/config/layoutConfig";
import axios from '@axios'
import { EventBus } from "@/libs/event-bus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate"
import vSelect from "vue-select";

const layoutApi = new LayoutApi(store);

export default {
  components: {
    BAvatar,
    VuePerfectScrollbar,
    ChatContact,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
  },
  computed: {
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    sidebarMinWidthReached() {
      return store.getters["grid/sidebarMinWidthReached"];
    },
    message() {
      if (this.error) {
        return this.$t('chat.error');
      } else {
        return `${this.$t('messages.loading.title')}...`;
      }
    }
  },
  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
      isCollapseOpen: true,
      chats: [],
      socket: null,
      message_id: 1,
      userData: JSON.parse(localStorage.getItem("userData")),
      isLoading: false,
      error: false,
      chatGroup: [],
      chatUsers: [],
      users: [],
      name: null,
      id_group: null,
      isAdd: true,
    };
  },
  async mounted() {
    this.isCollapseOpen = this.isOpen;
    this.getChatGroups();
    try {
      let { data } = await axios.get("/v1/services/chat/chat");
      this.chats = data.data;
    } catch (error) {
      this.isLoading = true;
      this.error = true;
      return
    }

    axios.get(`/v1/services/user/hash`)
      .then(({ data: response }) => {
        //this.socket = new WebSocket(process.env.VUE_APP_ROCKET_CHAT_WS);
        this.socket = new WebSocket('ws://localhost:3000/websocket');

        this.socket.onopen = async function (e) {
          let connectMessage = {
            "msg": "connect",
            "version": "1",
            "support": [
              "1"
            ]
          };

          this.socket.send(JSON.stringify(connectMessage));
        }.bind(this);

        this.socket.onmessage = function (event) {
          let message = {};
          let messageGroup = {};
          let data = JSON.parse(event.data);

          switch (data.msg) {
            case 'connected':
              message = {
                "msg": "method",
                "method": "login",
                "id": `${this.message_id++}`,
                "params": [
                  {
                    "user": { "username": `omniuser_${this.userData.id}` },
                    "password": {
                      "digest": response.data,
                      "algorithm": "sha-256"
                    }
                  }
                ]
              }

              this.socket.send(JSON.stringify(message));
              break;
            case 'ping':
              message = {
                "msg": "pong"
              }

              this.socket.send(JSON.stringify(message));
              break;
            case 'added':
              this.chats.forEach(chat => {
                message = {
                  "msg": "sub",
                  "id": `${this.message_id++}`,
                  "name": "stream-room-messages",
                  "params": [
                    chat.room_id,
                    false
                  ]
                }

                this.socket.send(JSON.stringify(message));
              });

              this.chatGroup.forEach(chat => {
                messageGroup = {
                  "msg": "sub",
                  "id": `${this.message_id++}`,
                  "name": "stream-room-messages",
                  "params":[
                      chat.roomId,
                      false
                  ]
                }

                this.socket.send(JSON.stringify(messageGroup));
              })

              break;
            case 'changed':           
              if (data.collection === "stream-room-messages") {
                let response_message = data.fields.args[0];

                EventBus.emit(`new-message-${response_message.rid}`,
                  {
                    "message_id": response_message._id,
                    "roomId": response_message.rid,
                    "text": response_message.msg,
                    "ts": new Date(response_message.ts.$date).toISOString(),
                    "user_id": response_message.u._id,
                    "username": response_message.u.username,
                    "updated_at": new Date(response_message._updatedAt.$date).toISOString()
                  }
                );

                this.chats.forEach(chat => {
                  if (chat.room_id == response_message.rid) {
                    chat.last_message = response_message.msg;
                    chat.unread_from = new Date(response_message.ts.$date).toISOString();
                  }
                });

                let isLoaded = false;

                layoutApi.getCurrentTabData().slots.forEach(slot => {
                  if(slot.data.chat.id){
                    if (slot.type == SLOT_TYPES.CHAT && slot.name != `CHAT_${slot.data.chat.id}` && 
                      response_message.u.username != `omniuser_${this.userData.id}`) {
                    isLoaded = true;
                    }
                  }
                })

                if (!isLoaded) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: this.$t("chat.new_message", { user: response_message.u.name }),
                      variant: "infor",
                      icon: "BellIcon",
                      text: response_message.msg,
                      timeout: 5000,
                    },
                  });
                }
              }
              break;
            default:
              break;
          }
        }.bind(this);

        this.socket.onclose = function (event) {
          if (event.wasClean) {
            console.log(`[close] Conexión cerrada limpiamente, código=${event.code} motivo=${event.reason}`);
          } else {
            // ej. El proceso del servidor se detuvo o la red está caída
            // event.code es usualmente 1006 en este caso
            console.log('[close] La conexión se cayó');
          }
        };

        this.socket.onerror = function (error) {
          console.log(`[error]`);
        };
      });
  },
  unmounted() {
    this.socket.close()
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    openChat(chat) {
      let isLoaded = false;

      layoutApi.getCurrentTabData().slots.forEach(slot => {
        if (slot.type == SLOT_TYPES.CHAT && slot.name == `CHAT_${chat.id}` || slot.name == `CHAT_${chat.roomId}`) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t("grid.slots.main.messages.widget_not_be_added"),
              variant: "warning",
              icon: "BellIcon",
              text: this.$t("grid.slots.main.messages.chat_widget_exists"),
              timeout: 5000,
            },
          });
          isLoaded = true;
        }
      })

      if (!isLoaded) {
        if(chat.roomId){
          layoutApi.setSlotData(SLOT_TYPES.CHAT, `CHAT_${chat.roomId}`, {
            chat: chat,
          });
        }
        else{
          layoutApi.setSlotData(SLOT_TYPES.CHAT, `CHAT_${chat.id}`, {
            chat: chat,
          });
        }
        store.dispatch("grid/updateUserConfig");
      }
    },
    async getUsers(){
      try {
        let { data } = await axios.get("/v1/services/chat/chat-user");
        this.chatUsers = data.data.map((user) => ({
          value: user.userId,
          label: user.name,
        }))
      } catch (response) {
        console.error(response)
      }
    },
    async getChatGroups(){
      try {
        let { data } = await axios.get("/v1/services/chat/chat-group");
        this.chatGroup = data.data;
      } catch (response) {
        console.error(response)
        return
      }
    },
    showAddModal(){
      this.resetModal();
      this.getUsers();
      this.isAdd = true;
      this.$bvModal.show('chat_group');
    },
    async showEditModal(group) {
      this.getUsers();
      this.isAdd = false;
      this.id_group = group.roomId;
      this.$bvModal.show('chat_group')
      try {
        let { data } = await axios.get(`/v1/services/chat/chat-group/getGroupUsers/${group.roomId}`);
        this.users = data.data.map((user) => ({
          value: user.userId,
          label: user.name,
        }))
        this.name = group.name;
      } catch (response) {
        console.error(response)
        return
      }
    },
    async confirmDelete(group){
      try {
        let { data } = await axios.delete(`/v1/services/chat/chat-group/${group.roomId}`);
        if (data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.getChatGroups();
          this.onCancel();
        } 
        else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      } catch (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.response.data.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async onSubmit(){
      let data;
      let form = {
        name: this.name,
        users: this.users
      }

      try {
        if(this.isAdd){
          data = await axios.post("/v1/services/chat/chat-group", form);
        }
        else{
          data = await axios.put(`/v1/services/chat/chat-group/${this.id_group}`, form)
        }

        if (data.data.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.getChatGroups();
          this.onCancel();
        } 
        else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      } catch (response) {
        console.error(response)
        return
      }
    },
    onCancel(){
      this.$bvModal.hide('chat_group');
      this.resetModal();
    },
    resetModal(){
      this.users = [];
      this.name = null;
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
