<template>
  <div :class="['grid-layout__main-container', { 'selection-dnd': slotDataWillOpenATab, 'fullscreen': fullScreenEnabled }]">
    <main-slot
      v-for="(widget, index) in slots"
      :index="index"
      :widget="widget"
      :slots="slots"
      :key="widget.pos"
      :tabId="tabId"
      :style="{ ...gridItemStyles(widget) }"
      :layoutHeight="slotHeight"
      :id="widget.id"
    ></main-slot>
  </div>
</template>

<script>
import store from "@/store";
import { EventBus } from "@/libs/event-bus";
import {
  onMounted,
  onBeforeUnmount,
  onActivated,
  reactive,
  watch,
  computed,
  toRefs,
  nextTick,
  ref,
} from "@vue/composition-api";
import useLayout from "@/composables/layouts";
import defaultLayout from "@/config/defaultLayout";
import statisticsLayout from "@/config/statisticsLayout";
import liveVideoLayout from "@/config/liveVideoLayout";
import liveVideo5x1Layout from "@/config/liveVideo5x1Layout";
import timelineLayout from "@/config/timelineLayout";
import { useWindowSize } from "@vueuse/core";
import MainSlot from "./MainSlot";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";

export default {
  props: {
    tabId: {
      type: String | Number,
    },
  },
  components: {
    MainSlot,
  },
  setup(props) {
    const state = reactive({
      slots: [],
      sHeight: 85,
    });
    const rows = 8;

    const offsetHeightFullScreen = 100 / rows;
    const { height } = useWindowSize();
    const { slotHeight, gridItemStyles } = useLayout(layout, state.sHeight);

    // computed properties ------------------------
    const currentTab = computed(() => store.state.grid.tabs[props.tabId]);
    const layout = computed(() => {
      const NaN = isNaN(currentTab.value.layout);

      if (NaN && ["default", "standalone"].includes(currentTab.value.layout)) {
        if (currentTab.value.layout === "standalone") {
          return { id: "standalone", name: "standalone", grid: currentTab.value.slots };
        }

        return defaultLayout;
      }

      if (NaN && ["statistics"].includes(currentTab.value.layout)) {
        if (currentTab.value.layout === "standalone") {
          return { id: "standalone", name: "standalone", grid: currentTab.value.slots };
        }

        return statisticsLayout;
      }

      if (NaN && ["unit-live"].includes(currentTab.value.layout)) {
        if (currentTab.value.layout === "standalone") {
          return { id: "standalone", name: "standalone", grid: currentTab.value.slots };
        }

        return currentTab.value.slots.length > 4 ? liveVideo5x1Layout : liveVideoLayout;
      }

      if (NaN && ["unit-recording"].includes(currentTab.value.layout)) {
        if (currentTab.value.layout === "standalone") {
          return { id: "standalone", name: "standalone", grid: currentTab.value.slots };
        }

        return currentTab.value.slots.length > 4 ? liveVideo5x1Layout : liveVideoLayout;
      }

      if (NaN && ["timeline"].includes(currentTab.value.layout)) {
        if (currentTab.value.layout === "standalone") {
          return { id: "standalone", name: "standalone", grid: currentTab.value.slots };
        }

        return timelineLayout;
      }

      const userLayouts = store.getters["layouts/getUserLayouts"];
      const layout = userLayouts.find((l) => l.id === currentTab.value.layout);

      return layout || {};
    });
    const fullScreenEnabled = computed(() => store.state.grid.fullScreenEnabled);
    const isBottomBarEmpty = computed(() => store.state.grid.bottomBarItems.length === 0);
    const slotData = computed(() => store.state.grid.slotData);
    const slotDataWillOpenATab = computed(() => store.getters["grid/slotDataWillOpenATab"]);
    const resetSlotData = () => store.dispatch("grid/resetSlotSelection");

    // watchers ------------------------
    watch(layout, () => buildLayout());
    watch(isBottomBarEmpty, () => resizeSlotHeight());
    watch(fullScreenEnabled, () => resizeSlotHeight());
    watch(slotData, (value) => {
      if (!value) resetSlotData();
    });

    // methods ------------------------
    const resizeSlotHeight = () => {
      slotHeight.value =
        (!fullScreenEnabled.value ? height.value - 94.9 : height.value) *
        (offsetHeightFullScreen / 100);
    };

    const buildLayout = () =>
      (state.slots = layout.value.grid
        ? [...JSON.parse(JSON.stringify(layout.value.grid))]
        : []);

    // hooks ------------------------
    onMounted(() => {
      nextTick(() => {
        buildLayout();
        resizeSlotHeight();
      });
      window.addEventListener("resize", () => resizeSlotHeight());
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", () => resizeSlotHeight());
      state.slots = [];
    });

    onActivated(() => {
      EventBus.emit(`tab:${props.tabId}:resume`);
    });

    return {
      ...toRefs(state),
      gridItemStyles,
      slotHeight,
      fullScreenEnabled,
      layout,
      height,
      isBottomBarEmpty,
      slotDataWillOpenATab,
    };
  },
};
</script>
