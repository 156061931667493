<template>
    <div class="floating-container" ref="containerVideo" :id="`floating-container-${uuid}`">
        <div class="header-floating-container" @mousedown="dragMouseDown">

        </div>
        <div :class="[
                'cam-container-floating',
                `cam-${cameraId}`,
            ]">
            <div :class="`camera-${uuid}`"></div>
            <sidenav :is-sidebar-active.sync="isSidebarActive" @refetch-data="() => { }"
                :luna-master-account-options="lunaMasterAccountOptions" :luna-list-options="lunaListOptions"
                :image-url="imageUrl" />
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { EventBus } from "@/libs/event-bus";
import { Camera } from "@omnicloud/cameras-sdk";
import layoutMixin from "@/mixins/layoutMixin";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import Sidenav from "../../../luna/face/Sidenav.vue";
import store from "@/store";

export default {
    mixins: [layoutMixin],
    components: {
        Sidenav
    },
    props: {
        cameraId: {
            type: Number | String,
            default: null,
        },
        cameraType: {
            type: String,
            default: null,
        },
        uuid: {
            type: String,
            default: null,
        },
        dateRange: {
            type: Object,
        },
        nameSufix: {
            type: String,
            default: "",
        },
        host: {
            type: String,
            default: process.env.VUE_APP_BASE_URL,
        },
    },
    data() {
        let token = localStorage.getItem("accessToken");

        if (this.host !== process.env.VUE_APP_BASE_URL) {
            const externalUser = this.$store.state.user.user.external_users.find(
                (external_user) => external_user.host == this.host
            );

            if (externalUser) token = externalUser.token;
        }

        return {
            isSidebarActive: false,
            camera: null,
            imageUrl: '',
            config: {
                cameraId: this.cameraId,
                token,
                htmlElement: `.camera-${this.uuid}`,
                style: "float",
                apiUrl: `${this.host}/v1`,
                debugMode: "info|error",
                mode: "default",
                locale: this.$i18n.locale,
                capabilities: {
                    closeBtn: true,
                    cloneBtn: true,
                    recordingBtn: this.cameraType == 'dahua_camera' ? false : true,
                    dateRangeBtn: this.cameraType == 'dahua_camera' ? false : true,
                    facialRecognitionEnrollment: true
                },
            },
            mode: "live",
            SLOT_TYPES,
            SOURCES,
            isMainCamera: false,
            isSynced: false,
            ready: false,
            pos1: 0,
            pos2: 0,
            pos3: 0,
            pos4: 0,
            parent: null
        };
    },
    mounted() {
        this.parent = document.querySelector(`#floating-container-${this.uuid}`).parentElement;
        try {
          if (document.fullscreen) {
            document.querySelector(`#${document.fullscreenElement.id}`).appendChild(document.querySelector(`#floating-container-${this.uuid}`))
          }

            if (this.cameraId && this.cameraType) {
                const camType = this.cameraType === "bridge" ? "vms" : this.cameraType;
                this.camera = new Camera(camType, this.config);

                if (this.dateRange && this.dateRange.start && this.dateRange.end) {
                    this.camera.buildPlayer("historical", this.dateRange.start, this.dateRange.end);
                    this.mode = "recorded";
                } else {
                    this.camera.buildPlayer();
                    this.camera.getCameraInstance().on("clone:instance", () => {
                        this.setSlotData(
                            this.SLOT_TYPES.CAM_LIVE,
                            this.camera.getCameraInstance().cameraData.name ||
                            `CAM_${this.camera.getCameraInstance().cameraData.id}`,
                            {
                                camera: {
                                    ...this.camera.getCameraInstance().cameraData,
                                    camera_type: "camera_manager",
                                    camera_id: this.camera.getCameraInstance().cameraData.id,
                                },
                            },
                            SOURCES.ALARM
                        );
                    });
                }

                this.camera.getCameraInstance().on("create:recording", () => {
                    this.setSlotData(
                        this.SLOT_TYPES.CAM_RECORDED,
                        this.camera.getCameraInstance().cameraData.name ||
                        `CAM_${this.camera.getCameraInstance().cameraData.id}`,
                        {
                            camera: {
                                ...this.camera.getCameraInstance().cameraData,
                                camera_type: "camera_manager",
                                camera_id: this.camera.getCameraInstance().cameraData.id,
                            },
                        },
                        SOURCES.ALARM
                    );
                });

                this.camera.getCameraInstance().on("create:facial_recognition_enrollment", (img) => {
                    this.imageUrl = img;
                    this.isSidebarActive = true;
                });

                this.camera.getCameraInstance().on("player:destroy", () => {
                    this.$store.commit("grid/removeFloatVideos", { id: this.cameraId, type: this.cameraType});
                });
            }

            EventBus.on(
                `timeline:${this.uuid}:${this.cameraId}:activated`,
                this.enableTimeline
            );

            EventBus.on(
                `timeline:${this.uuid}:${this.cameraId}:disabled`,
                this.disableTimeline
            );

            EventBus.on(
                `timeline:${this.uuid}:${this.cameraId}:reset`,
                this.resetTimeline
            );

            EventBus.on(`timeline:${this.uuid}:ping`, () => {
                if (this.mode === "timeline") {
                    EventBus.emit(`timeline:${this.uuid}:ping-cam`, this.camera);
                }
            });

            EventBus.on(
                `entering-full-screen`,
                this.enteringFullScreen
            );

            EventBus.on(
                `exiting-full-screen`,
                this.exitingFullScreen
            );
        } catch (error) {
            console.log(error);
        }
        store.dispatch('luna_master_account/getAll')

        store.dispatch('luna_list/getAll')
    },
    computed: {
        validData() {
            return this.cameraId && this.cameraType;
        },
        tlBorderColor() {
            if (this.camera && this.camera.camera.mode === "timeline" && this.isSynced) {
                return this.isMainCamera ? "lime" : "yellow";
            }
            return "red";
        },
        lunaMasterAccountOptions() {
            return store.getters['luna_master_account/getSelectedItems'];
        },
        lunaListOptions() {
            return store.getters['luna_list/getSelectedItems'];
        },
    },
    beforeDestroy() {
        try {
            this.camera.destroy();
            this.camera = null;
            this.detachTimelineListeners();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        enableTimeline: debounce(async function (data) {
            if (this.camera) await this.camera.destroy();

            const config = Object.assign(JSON.parse(JSON.stringify(this.config)), {
                mode: "timeline",
                timelineConfig: {
                    mainCamera: data.mainCamera,
                },
                capabilities: {
                    closeBtn: false,
                    cloneBtn: false,
                    recordingBtn: false,
                    timelineBtn: true,
                },
            });

            this.mode = "timeline";
            this.isMainCamera = data.mainCamera;
            this.isSynced = true;
            this.camera = new Camera(this.cameraType, config);
            this.camera.buildPlayer("historical", data.start, data.end).then(() => {
                EventBus.emit(`timeline:${this.tabInfo.id}:ready`, { cameraId: this.cameraId });
            });
        }, 800),
        async disableTimeline(data) {
            await this.camera.destroy();
            const config = Object.assign(JSON.parse(JSON.stringify(this.config)), {
                mode: "recorded",
                capabilities: {
                    closeBtn: false,
                    cloneBtn: true,
                    recordingBtn: true,
                    timelineBtn: true,
                },
            });
            this.mode = "recorded";
            this.isMainCamera = false;
            this.isSynced = false;
            this.camera = new Camera(this.cameraType, config);
            this.camera.buildPlayer("historical", data.start, data.end);
        },
        async enteringFullScreen(slot) {
            document.querySelector(`#${slot.id}`).appendChild(document.querySelector(`#floating-container-${this.uuid}`))
        },
        async exitingFullScreen() {
            this.parent.appendChild(document.querySelector(`#floating-container-${this.uuid}`));
        },
        async resetTimeline(data) {
            await this.camera.destroy();
            const config = Object.assign(JSON.parse(JSON.stringify(this.config)), {
                mode: "recorded",
                capabilities: {
                    closeBtn: false,
                    cloneBtn: true,
                    recordingBtn: true,
                    timelineBtn: true,
                },
            });
            this.mode = "recorded";
            this.isMainCamera = false;
            this.isSynced = false;
            this.camera = new Camera(this.cameraType, config);
            this.camera.buildPlayer("historical", data.start, data.end);
        },
        detachTimelineListeners() {
            EventBus.off(
                `timeline:${this.uuid}:${this.cameraId}:activated`,
                this.enableTimeline
            );
            EventBus.off(
                `timeline:${this.uuid}:${this.cameraId}:disabled`,
                this.disableTimeline
            );
            EventBus.off(`timeline:${this.uuid}:ping`, () => {
                if (this.mode === "timeline") {
                    EventBus.emit(`timeline:${this.uuid}:ping-cam`, this.camera);
                }
            });

            EventBus.off(
                `entering-full-screen`,
                this.enteringFullScreen
            );

            EventBus.off(
                `exiting-full-screen`,
                this.exitingFullScreen
            );
        },
        remove() {
            try {
                this.camera.destroy();
                this.detachTimelineListeners();
                this.$emit("cam-removed", this.pos);
            } catch (error) {
                console.log(error);
            }
        },
        dragMouseDown(e) {
            // eslint-disable-next-line no-param-reassign
            e = e || window.event
            e.preventDefault()
            // get the mouse cursor position at startup:
            this.pos3 = e.clientX
            this.pos4 = e.clientY
            document.onmouseup = this.closeDragElement
            // call a function whenever the cursor moves:
            document.onmousemove = this.elementDrag
        },
        elementDrag(e) {
            // eslint-disable-next-line no-param-reassign
            e = e || window.event
            e.preventDefault()
            // calculate the new cursor position:
            this.pos1 = this.pos3 - e.clientX
            this.pos2 = this.pos4 - e.clientY
            this.pos3 = e.clientX
            this.pos4 = e.clientY

            let top = 0
            let left = 0

            if (this.$refs.containerVideo.offsetTop - this.pos2 > 0) {
                top = this.$refs.containerVideo.offsetTop - this.pos2
            }

            if (this.$refs.containerVideo.offsetHeight + this.$refs.containerVideo.offsetTop + 10 > window.innerHeight) {
                top = window.innerHeight - this.$refs.containerVideo.offsetHeight - 10
            }

            if (this.$refs.containerVideo.offsetLeft - this.pos1 > 0) {
                left = this.$refs.containerVideo.offsetLeft - this.pos1
            }

            if (this.$refs.containerVideo.offsetWidth + this.$refs.containerVideo.offsetLeft + 10 > window.innerWidth) {
                left = window.innerWidth - this.$refs.containerVideo.offsetWidth - 10
            }

            // set the element's new position:
            this.$refs.containerVideo.style.top = `${top}px`
            this.$refs.containerVideo.style.left = `${left}px`
        },
        closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null
            document.onmousemove = null
        }
    },
};
</script>

<style lang="scss" scoped>

.floating-container {
    position: absolute;
    min-height: 10rem;
    min-width: 20rem;
    width: 30vw;
    top: 0;
    left: 0;
    background: black;
    z-index: 5;
    resize: both;
    overflow: hidden;

    span {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        z-index: 9;
        cursor: pointer;
    }

    div[class^="camera-"] {
        height: 100%;
    }

    .cam-container-floating {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.header-floating-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 2rem;
    z-index: 4;
}

</style>
