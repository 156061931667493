<template>
  <div>
    <div class="row">
      <div class="col-12">
        <img
          :src="detectionEvent.media.url"
          class="img-fluid w-100"
          @click="openImageModal(detectionEvent.media.url)"
          v-b-modal.image_modal
        />
      </div>
      <div class="col-6 text-center">
        <img
          :src="detectionEvent.data.id_luna"
          class="img-fluid w-100"
          @click="openImageModal(detectionEvent.data.id_luna)"
          v-b-modal.image_modal
        />
        <p class="my-0 mt-1">{{ $t("grid.slots.info.fr.face_detected") }}</p>
      </div>
      <div class="col-6 text-center">
        <img
          :src="(detectionEvent.luna_match) ? detectionEvent.luna_match.avatar : imgError"
          class="img-fluid w-100"
          @click="openImageModal(detectionEvent.luna_match.avatar)"
          v-b-modal.image_modal
        />
        <p class="my-0 mt-1">{{ $t("grid.slots.info.fr.enrolled_person") }}</p>
      </div>
    </div>
    <table class="table table-sm">
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.default.title") }}</th>
        <td>{{ detectionEvent.formatted_event_type }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.detection_events.labels.camera_name") }}</th>
        <td>{{ detectionEvent.camera_proxy.camera.name }}</td>
      </tr>
      <tr>
        <th>{{ $t("detection_event.event_created_at") }}</th>
        <td>{{ detectionEvent.created_at }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.person_name") }}</th>
        <td>{{ (detectionEvent.luna_match) ? detectionEvent.luna_match.name : '' }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.age") }}</th>
        <td>{{ detectionEvent.metadata[0].data.age }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.gender") }}</th>
        <td>{{ (detectionEvent.metadata[0].data.gender) ? $t("grid.slots.info.fr.genderMale") : $t("grid.slots.info.fr.genderFemale")  }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.emotion") }}</th>
        <td>{{ detectionEvent.metadata[0].data.predominant_emotion }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {} from "bootstrap-vue";
import store from "@/store";
import ImageError from '../../../assets/images/placeholder-user.png';

export default {
  components: {
  },
  props: {
    detectionEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgError: ImageError,
    }
  },
  methods: {
    async openImageModal(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    },
  }
};
</script>