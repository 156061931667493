<template>
  <b-modal
    :id="`${tabId}-download_manager_modal`"
    hide-header
    no-close-on-backdrop
    centered
    size="lg"
    scrollable
    content-class="overflow-hidden"
    @shown="onShown"
  >
    <div>
      <p class="h3">{{ $t("grid.timeline.downloadModal.title") }}</p>
      <div class="row my-2">
        <div class="col">
          From:
          <input
            id="date_selector_from"
            class="form-control form-control-sm"
            readonly="readonly"
            v-model="dates.from"
          />
        </div>
        <div class="col">
          To:
          <input
            id="date_selector_to"
            class="form-control form-control-sm"
            readonly="readonly"
            v-model="dates.to"
          />
        </div>
      </div>
      <table class="table">
        <tr>
          <th class="pl-0">
            <span class="text-uppercase">Camera</span>
          </th>
          <th></th>
          <th>
            <!-- <span
              class="cursor-pointer border-2"
              v-b-tooltip.hover.bottom.viewport
              :title="$t('grid.timeline.toolbar.download')"
              @click="downloadAll()"
              v-if="source === 'from_btn'"
            >
              Download all &nbsp;
              <feather-icon class="icon" size="16" icon="DownloadCloudIcon" />
            </span> -->
          </th>
        </tr>
        <tr v-for="(job, jobIndex) in jobsList" :key="jobIndex">
          <td class="pl-0">
            {{ job.name }}
          </td>
          <td>
            <feather-icon
              class="icon cursor-pointer"
              size="18"
              icon="DownloadIcon"
              v-b-tooltip.hover.bottom.viewport
              :title="$t('grid.timeline.toolbar.download')"
              @click="onDownloadClick(job)"
              v-if="!subjobsData[job.id] || subjobsData[job.id].length === 0"
            />
          </td>
          <td>
            <template v-if="subjobsData[job.id] && subjobsData[job.id].length">
              <div style="max-height: 170px; overflow: auto">
                <table class="table table-sm table-borderless">
                  <tr
                    v-for="(subjob, subjobIndex) in subjobsData[job.id]"
                    :key="`${subjob.id}_${subjobIndex}`"
                  >
                    <td>
                      <p class="mb-0">{{ subjob.from }} - {{ subjob.to }}</p>
                      <p class="mb-0">{{ status[subjob.status] }}</p>
                    </td>
                    <td>
                      <a
                        :href="subjob.url"
                        target="_blank"
                        v-if="subjob.status === 'done'"
                      >
                        <feather-icon
                          class="icon cursor-pointer"
                          size="16"
                          icon="DownloadCloudIcon"
                          v-b-tooltip.hover.bottom.viewport
                          :title="$t('grid.timeline.toolbar.download')"
                        />
                      </a>
                    </td>
                    <!-- <td>
                      <feather-icon
                        class="icon cursor-pointer"
                        size="16"
                        icon="RefreshCwIcon"
                        v-b-tooltip.hover.bottom.viewport
                        :title="$t('grid.timeline.toolbar.download')"
                        @click="restartJob(job)"
                      />
                    </td> -->
                  </tr>
                </table>
              </div>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <template #modal-footer="{ close }">
      <div class="">
        <b-button variant="primary" size="sm" class="float-left mr-1" @click="close()">
          {{ $t("close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import axios from "@axios";
import moment from "moment-timezone";

import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    vSelect,
  },
  props: {
    config: Object,
    source: String,
    jobs: Array,
    tabId: String,
    timeZone: String,
  },
  data() {
    return {
      cameras: [],
      dates: {
        from: "",
        to: "",
      },
      dateSelectorFrom: null,
      dateSelectorTo: null,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      currentSubjobs: {},
      status: {},
      subjobsList: [],
      disabledDatePickers: false,
    };
  },
  mounted() {
    this.status["working"] = "Working...";
    this.status["done"] = "Done.";
  },
  beforeDestroy() {
    this.subjobsList.forEach((jobId) => this.unsetDownloadWS(jobId));
    if (this.dateSelectorFrom) this.dateSelectorFrom.destroy();
    if (this.dateSelectorTo) this.dateSelectorTo.destroy();
  },
  computed: {
    jobsList() {
      if (this.source === "from_tag") {
        return this.jobs.filter((j) => j.type === "event");
      }
      return this.jobs.filter((j) => j.type === "camera");
    },
    subjobsData() {
      return this.currentSubjobs;
    },
  },
  methods: {
    getCameras() {
      this.cameras = [];
    },
    initializeDateTimePickers() {
      const config = {
        minDate: moment(moment()
          .subtract(this.config.minDays ? this.config.minDays : 15, "days")
          .format("YYYY-MM-DD HH:mm:ss")).toDate(),
        maxDate: moment(moment.tz(this.timeZone).format("YYYY-MM-DD HH:mm:ss")).toDate(),
        altInput: true,
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
        locale: this.$i18n.locale === "en" ? null : Spanish,
        onChange: () => {
          this.reset();
        },
      };

      const dateSelectorFrom = "#date_selector_from";
      this.dateSelectorFrom = flatpickr(dateSelectorFrom, { ...config });

      const dateSelectorTo = "#date_selector_to";
      this.dateSelectorTo = flatpickr(dateSelectorTo, { ...config });
    },
    onShown() {
      this.initializeDateTimePickers();

      this.dates.from = "";
      this.dates.to = "";

      if (this.source === "from_tag") {
        const job = this.jobsList[0];

        const from = moment(job.date_time)
          .subtract(15, "seconds")
          .format(this.dateFormat);
        const to = moment(job.date_time).add(15, "seconds").format(this.dateFormat);

        this.dates.from = from;
        this.dates.to = to;
      } else {
        this.dates.from = moment().subtract(1, "minute").format(this.dateFormat);
        this.dates.to = moment().subtract(15, "seconds").format(this.dateFormat);
      }

      this.dateSelectorFrom.setDate(this.dates.from);
      this.dateSelectorTo.setDate(this.dates.to);
    },
    onDownloadClick(job) {
      this.downloadVideo(job);
    },
    async downloadVideo(job) {
      const { camera_id, camera_type, camera_proxy_id, type } = job;
      let recordingId = null;

      if (!this.currentSubjobs[job.id]) {
        this.$set(this.currentSubjobs, job.id, []);
      }

      if (type === "event") {
        // Remove any other job event in the jobs
        const eventJobIndex = this.currentSubjobs[job.id].findIndex(
          (j) => j.type === "event"
        );

        if (eventJobIndex >= 0) {
          this.currentSubjobs[job.id].splice(eventJobIndex, 1);
        }
      }

      if (camera_type === "camera_manager") {
        const videoEl = document.querySelector(
          `#omniview-recording-video-component-${camera_proxy_id} video`
        );

        if (!videoEl) {
          console.error(`Timeline::downloadMgr: videoEl is not valid`);
          return;
        }

        if (!videoEl.src) {
          console.error(`Timeline::downloadMgr: videoEl.src is not valid`);
          return;
        }

        const url = videoEl.src;
        const parts = url.split("/");
        const filename = parts[parts.length - 1].split("?")[0];
        recordingId = filename.split(".")[0];
      }

      // TODO: Create job if fail
      // this.createJob(id, '-', '-', 'fail: msg...');

      const dates = {};

      if (type !== "event") {
        dates.from = this.dates.from;
        dates.to = this.dates.to;
      } else {
        dates.from = moment(job.date_time)
          .subtract(15, "seconds")
          .format(this.dateFormat);
        dates.to = moment(job.date_time).add(15, "seconds").format(this.dateFormat);
      }

      let url = `/v1/services/download-video/${camera_type}/${camera_id}`;

      if (recordingId) {
        url = `${url}?recording_id=${recordingId}`;
      }

      let { data } = await axios.get(url, {
        params: { start: dates.from, end: dates.to },
      });

      const subjobs = data.data;
      const currentSubjobsLength = this.currentSubjobs[job.id].length;
      let currentSubjobIndex = currentSubjobsLength === 0 ? 0 : currentSubjobsLength - 1;

      this.$set(job, "start_dt", moment().format(this.dateFormat));

      subjobs.id.forEach((id, index) => {
        const dates = subjobs.dates[index];
        this.$set(
          this.currentSubjobs[job.id],
          currentSubjobIndex++,
          this.createSubjobData(id, dates[0], dates[1], "working", type)
        );
        this.subjobsList.push(id);
        this.setDownloadWS(id);
      });
    },
    createSubjobData(id, from, to, status = "working", type, url = null) {
      return {
        id,
        from: moment.utc(from).local().format(this.dateFormat),
        to: moment.utc(to).local().format(this.dateFormat),
        status,
        type,
        url,
      };
    },
    updateSubjobProperty(jobId, property, status) {
      this.jobs.forEach((job) => {
        if (this.currentSubjobs[job.id]) {
          const currentJob = this.currentSubjobs[job.id].find((j) => j.id === jobId);
          if (currentJob) currentJob[property] = status;
        }
      });
    },
    setDownloadWS(jobId) {
      window.Echo.private(`video-download-${jobId}`).listen(".update", (data) => {
        if (data.message.status === "ok") {
          this.updateSubjobProperty(jobId, "status", "done");
          this.updateSubjobProperty(jobId, "url", data.message.url);
          window.open(data.message.url, "_blank");
        }

        const jobIndex = this.subjobsList.findIndex((j) => j === jobId);
        if (jobIndex >= 0) this.subjobsList.splice(jobIndex, 1);

        this.unsetDownloadWS(jobId);
      });
    },
    unsetDownloadWS(jobId) {
      window.Echo.leave(`video-download-${jobId}`);
    },
    downloadAll() {
      this.processArray(this.jobs, 800, (job) => {
        if (job.type === "camera") {
          this.downloadVideo(job);
        }
      });
    },
    reset(job = null) {
      if (job) {
        this.unsetDownloadWSByJob(job);
        this.$set(this.currentSubjobs, job.id, []);
        return;
      }

      this.jobs.forEach((job) => {
        this.unsetDownloadWSByJob(job);
        this.$set(this.currentSubjobs, job.id, []);
      });
    },
    unsetDownloadWSByJob(job) {
      if (job && Array.isArray(this.currentSubjobs[job.id])) {
        this.currentSubjobs[job.id].forEach((subjobId) => this.unsetDownloadWS(subjobId));
      }
    },
    processArray(array, delay, callback) {
      let i = 0;

      function next() {
        if (i < array.length) {
          callback(array[i], i, array);
          i++;
          setTimeout(next, delay);
        }
      }

      next();
    },
    restartJob(job) {
      this.reset(job);
      this.downloadVideo(job);
    },
  },
};
</script>

<style></style>
