<template>
  <div>
    <h1 class="lead text-center">{{ data.data.device_id }}</h1>
    <table class="table">
      <tr>
        <th>{{ $t('grid.slots.interphone.type') }}</th>
        <td>{{data.data.type_device_interphone.type}}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.interphone.alarm') }}</th>
        <td>{{data.data.alarm}}</td>
       </tr>
        <tr>
        <th>Ivr</th>
        <td>{{data.data.ivr}}</td>
       </tr>
         <tr>
        <th>{{ $t('grid.slots.interphone.created') }}</th>
        <td>{{formatDateAssigned(data.data.created_at)}}</td>
         </tr>
          <tr>
        <th>{{ $t('grid.slots.interphone.updated') }}</th>
        <td>{{formatDateAssigned(data.data.updated_at)}}</td>
      </tr>
      <tr v-if="data.data.user">
        <th>{{ $t('grid.slots.interphone.user') }}</th>
        <td>{{data.data.user}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    data: Object
  },
setup () {
      const formatDateAssigned = (value) => {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate;
    };

    return {
      formatDateAssigned
    }
}

};
</script>

<style></style>
