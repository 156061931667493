<template>
  <div class="page-slot">
    <div class="page-slot-data p-2" v-if="!data.data.load">
      <b-form-group
        v-if="data.data.save"
        class="text-capitalize" :label="$t('pages.description')" label-for="description">
        <b-form-input id="description" v-model="data.data.description" trim />
      </b-form-group>
      <b-form-group class="text-capitalize" :label="$t('grid.frame.url')" label-for="url">
        <b-form-input id="url" v-model="data.data.url" trim />
      </b-form-group>
      <!-- Form Actions -->
      <div class="d-flex mt-2" v-if="data.data.save">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2 text-capitalize"
          type="button"
          @click="save()"
        >
          {{ $t("actions.save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="data.data.load = true; data.data.save = false;"
          class="text-capitalize"
        >
          {{ $t("actions.cancel") }}
        </b-button>
      </div>
      <b-button
        v-if="!data.data.save"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="outline-secondary"
        @click="loadUrl()"
        class="text-capitalize"
      >
        {{ $t("actions.load") }}
      </b-button>
    </div>

    <iframe
      v-if="data.data.load && !data.data.save"
      :src="data.data.url"
      class="frame"
    />
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import { BFormInput, BFormGroup, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  mixins: [layoutMixin],
  components: {
    BFormInput,
    BButton,
    BFormGroup,
  },
  props: {
    data: {
      type: Object,
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    loadUrl() {
      this.data.data.load = true
    },
    save() {
      if (this.data.data.id) {
        store.dispatch("pages/edit", {
          id: this.data.data.id,
          params: {
            description: this.data.data.description,
            url: this.data.data.url,
          }
        }).then(response => {
          this.data.data.id = response.data.id;
          this.data.data.load = true;
          this.data.data.save = false;
          store.dispatch("pages/getAll");
        });
      } else {
        store.dispatch("pages/add", {
          description: this.data.data.description,
          url: this.data.data.url,
        }).then(response => {
          this.data.data.id = response.data.id;
          this.data.data.load = true;
          this.data.data.save = false;
          store.dispatch("pages/getAll");
        });
      }
    }
  },
  mounted() {
    if (this.data.data.url) {
      this.data.data.load = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.page-slot {
  width: 100%;
  height: 100%;
}

.page-slot-data {
  width: 100%;
  height: 100%;
}

.frame {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}
</style>
