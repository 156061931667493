export default {
  id: 1,
  name: 'default',
  grid: [{
    "w": 12,
    "h": 6,
    "minW": 2,
    "minH": 1,
    "id": "8be464af-9479-440f-8028-deaead2c27e0",
    "x": 0,
    "y": 0,
    "content": "12 x 6",
    "name": null,
    "type": null,
    "pos": 1,
    "data": null
  },
  {
    "w": 12,
    "h": 2,
    "minW": 2,
    "minH": 1,
    "id": "677272e2-dbfe-4810-affd-af44fbba26a5",
    "x": 0,
    "y": 6,
    "content": "12 x 2",
    "name": null,
    "type": null,
    "pos": 2,
    "data": null
  }]
}
