 <template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      class="chat"
      :class="{ 'chat-left': formattedChatData.contact.id ?  msgGrp.senderId == formattedChatData.contact.id : msgGrp.senderId != formattedChatData.contact.id_user }">
      <div class="chat-avatar">
        <b-avatar size="35" class="avatar-border-2 box-shadow-1" variant="secondary"
          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : chatData.image" />
      </div>
      <div class="chat-body" v-if="!formattedChatData.contact.id_user">
        <div v-for="msgData in msgGrp.messages" :key="msgData.time" class="chat-content">
          <p>{{ msgData.msg }}</p>
        </div>
      </div>

      <div class="chat-body" v-if="formattedChatData.contact.id_user">
        <div v-for="msgData in msgGrp.messages" :key="msgData.time">
          <div v-if="msgData.t">
            <span class="text-muted">{{ msgData.user }}</span>
            <p :class="msgData.t == 'au' ?'alert alert-success' : 'alert alert-danger'"> {{ msgData.t == 'au' ? `${$t("actions.add")} `+ msgData.msg : `${$t("actions.delete")} `+ msgData.msg }} </p>
          </div>
          <div v-else class="chat-content">
            <span class="text-muted">{{ msgData.user }}</span>
            <p>{{ msgData.msg }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      let userData = JSON.parse(localStorage.getItem("userData"));

      let contact = {
        id: props.chatData.id,
        avatar: props.chatData.image,
      }

      if(props.chatData.roomId){
        contact.id_user = userData.id
      }

      let chatLog = []
      if (props.chatData) {
        chatLog = props.chatData.messages
      }

      const formattedChatLog = []
      let chatMessageSenderId = userData.id

      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        let userId = msg.username.split('_')[1]
        if (chatMessageSenderId == userId) {
          msgGroup.messages.push({
            msg: msg.text,
            time: msg.ts,
            user: msg.username,
            t: msg.t
          })
        } else {
          chatMessageSenderId = userId
          if (msgGroup.messages.length > 0)
            formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: userId,
            messages: [{
              msg: msg.text,
              time: msg.ts,
              user: msg.username,
              t: msg.t
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

<style></style>
