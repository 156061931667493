<template>
  <div class="wrapper">
    <div :class="['loading-overlay', { 'd-none': !loading }]">
      <span class="loader"></span>
    </div>
    <headless
      ref="video_player"
      id="video-player"
      :camera="camera"
      :timeline-current-time="currentTime"
      :recording="currentRecording"
      :last-max-date="lastMaxDate"
      :requests-history="requestsHistory"
      :tab-info="tabInfo"
      @loadstart="onVideoLoadStart"
      @loadedmeta-data="onVideoLoadedMetaData"
      @timeupdate="onVideoTimeChange"
      @canplay="onVideoCanPlay"
      @video-status-change="videoStatusChange"
    ></headless>
    <div
      class="toolbar row justify-content-center"
      fit-menu
      :class="{
        'fit-menu': height < 200,
      }"
    >
      <div
        :class="{
          'col-12 mb-1': slotW < 790,
          'col-8': slotW > 790 && slotW < 1200,
          'col-6': slotW > 1200,
        }"
        style="padding: 5px"
      >
        <div class="row">
          <div
            class="col-4 d-flex justify-content-center"
            :style="firstLoad ? 'opacity: 0.4;' : 'opacity: 1;'"
          >
            <div class="events-navigation">
              <span
                :class="['btn-events-nav', { disabled: firstLoad }]"
                @click="moveThrough('prev')"
              >
                <feather-icon size="18" icon="ChevronLeftIcon" />
              </span>
              <span v-if="filterType === 'events'">
                {{ $t("grid.timeline.toolbar.alarms") }}
              </span>
              <span v-else>
                {{ $t("grid.timeline.toolbar.objects") }}
              </span>
              <span
                :class="['btn-events-nav', { disabled: firstLoad }]"
                @click="moveThrough('next')"
              >
                <feather-icon size="18" icon="ChevronRightIcon" />
              </span>
            </div>
          </div>
          <!-- Control bar -->
          <div class="col-8 d-flex align-items-center justify-content-center">
            <div class="row mr-25">
              <div class="col d-flex">
                <!-- Zoom buttons -->
                <div
                  :class="[
                    'col align-items-center',
                    { 'd-none': slotW < 1000, 'd-flex': slotW > 1000 },
                  ]"
                >
                  <feather-icon
                    :class="[
                      'icon cursor-pointer mr-2',
                      { 'text-light cursor-not-allowed': this.currentZoom === 0 },
                    ]"
                    size="18"
                    icon="ZoomOutIcon"
                    v-b-tooltip.hover.bottom.viewport
                    :title="$t('grid.timeline.toolbar.zoom_out')"
                    @click="updateZoom('zoomOut')"
                  />
                  <feather-icon
                    :class="[
                      'icon cursor-pointer',
                      { 'text-light cursor-not-allowed': this.currentZoom === 8 },
                    ]"
                    size="18"
                    icon="ZoomInIcon"
                    v-b-tooltip.hover.bottom.viewport
                    :title="$t('grid.timeline.toolbar.zoom_in')"
                    @click="updateZoom('zoomIn')"
                  />
                </div>
              </div>
            </div>
            <div class="row control-bar">
              <div class="col d-flex align-items-center">
                <feather-icon
                  class="icon"
                  size="18"
                  icon="ChevronLeftIcon"
                  v-b-tooltip.hover.bottom.viewport
                  :title="$t('grid.timeline.toolbar.x_seconds_bw')"
                  @click="$refs.video_player.moveXSeconds(5, 'backward')"
                />
                <span class="mx-2">
                  <feather-icon
                    v-if="isPlaying"
                    class="icon"
                    size="18"
                    icon="PlayIcon"
                    v-b-tooltip.hover.bottom.viewport
                    :title="$t('grid.timeline.toolbar.play')"
                    @click="$refs.video_player.play()"
                  />
                  <feather-icon
                    v-else
                    class="icon"
                    size="18"
                    icon="PauseIcon"
                    v-b-tooltip.hover.bottom.viewport
                    :title="$t('grid.timeline.toolbar.pause')"
                    @click="$refs.video_player.pause()"
                  />
                </span>
                <feather-icon
                  class="icon"
                  size="18"
                  icon="ChevronRightIcon"
                  v-b-tooltip.hover.bottom.viewport
                  :title="$t('grid.timeline.toolbar.x_seconds_fw')"
                  @click="$refs.video_player.moveXSeconds(5, 'forward')"
                />
                <feather-icon
                  class="icon ml-1"
                  size="18"
                  icon="RefreshCwIcon"
                  v-b-tooltip.hover.bottom.viewport
                  :title="
                    $t(
                      `grid.timeline.toolbar.${
                        !syncedCamerasStatus ? 'synchronize' : 'desynchronize'
                      }`
                    )
                  "
                  @click="syncCameras"
                  v-if="timelineSource === 'grid'"
                />
                <feather-icon
                  class="icon ml-1"
                  size="18"
                  icon="FlagIcon"
                  v-b-tooltip.hover.bottom.viewport
                  :title="$t('grid.timeline.toolbar.mostRecentEvent')"
                  @click="goToMostRecentEvent"
                />
                <feather-icon
                  class="icon ml-1"
                  size="18"
                  icon="DownloadIcon"
                  v-b-tooltip.hover.bottom.viewport
                  :title="$t('grid.timeline.toolbar.download')"
                  @click="showDownloadManagerModal('from_btn')"
                />
                <div class="video-speed">
                  <select
                    class="form-control form-control-sm"
                    @change="setVideoSpeed"
                    v-model="videoSpeed"
                  >
                    <option disabled>
                      {{ $t("grid.timeline.toolbar.speed") }}
                    </option>
                    <option value="0.5">0.5x</option>
                    <option value="1.0" selected>Normal</option>
                    <option value="2.0">2x</option>
                    <option value="4.0">4x</option>
                    <option value="6.0">6x</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-12 mr-1 mb-1': slotW < 790,
          'd-none': slotW > 790 && slotW < 1200,
          'col-2 mr-1': slotW > 1200,
          'd-none': height < 200 && slotW < 790,
        }"
      >
        <div :class="['row g-0', { 'd-none': !showDatePicker }]">
          <div
            :class="['date-selector input-group', { 'opacity-25': loading }]"
            style="margin-top: 4px"
          >
            <input
              :id="`date_selector_input-${tabInfo.id}`"
              :value="currentTimeDate"
              class="form-control form-control-sm"
              readonly="readonly"
              tabindex="-1"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <feather-icon size="18" icon="CalendarIcon" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-12': slotW < 790,
          'col-4': slotW > 790 && slotW < 1200,
          'col-3': slotW > 1200,
        }"
        style="padding: 2px"
      >
        <div
          class="d-flex justify-content-start align-items-center"
          style="min-height: 40px"
        >
          <feather-icon
            class="icon mr-1 cursor-pointer d-none"
            size="20"
            icon="AlertCircleIcon"
            v-if="filterType === 'events'"
            @click="filterType = 'objects'"
          />
          <feather-icon
            class="icon mr-1 cursor-pointer"
            size="20"
            icon="BoxIcon"
            v-if="filterType === 'objects'"
            @click="filterType = 'events'"
          />
          <v-select
            :options="filterOptions"
            v-model="filtersSelected"
            :append-to-body="true"
            :calculate-position="withPopper"
            multiple
            :reduce="(val) => val.value"
            placeholder="Filter events"
            @option:selecting="onFilterSelected"
            @option:deselecting="onFilterDeselected"
            v-if="filterType === 'events'"
            class="w-100 timeline-events-filter"
          />
          <div v-if="filterType === 'objects'" @click="showObjectsFilterModal">
            {{ $t("grid.timeline.toolbar.filter_type") }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="['timeline-container', `location-${timelineSource}`]"
      v-show="groups"
      @mousewheel.prevent="onMouseWheel"
    >
      <div class="center-line"></div>
      <vis-timeline
        ref="timeline"
        :options="options"
        @rangechange="onDrag"
        @rangechanged="onDragged"
        @mouse-down="onMouseDown"
        @mouse-up="onMouseUp"
        @click="onTimelineClick"
        @double-click="onTimelineDoubleClick"
      >
      </vis-timeline>
    </div>
    <!-- ======= Current time for debug ====== -->
    <div class="current-time mt-1 text-center small d-none">
      {{ currentTimeFormatted }}
    </div>
    <object-filter-modal :config="objectFilterModalConfig" @search="onObjectSearch" />
    <download-manager-modal
      :config="objectFilterModalConfig"
      :source="downloadManagerSource"
      :jobs.sync="downloadManagerJobs"
      :tab-id="tabId"
      :time-zone="timeZone"
    />
  </div>
</template>

<script>
import moment from "moment";
import { debounce } from "lodash";
import { throttle } from "lodash";
import { VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";
import VisTimeline from "./VisTimeline";
import { DataSet } from "vis-data/esnext";
import "./vis-timeline/styles/vis-timeline-graph2d.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import "flatpickr/dist/flatpickr.css";

import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import axios from "@axios";
import axiosIns from "axios";
import VideoPlayer from "./VideoPlayer.vue";
import Headless from "./Headless";
import store from "@/store";
import { createPopper } from "@popperjs/core";
import ObjectFilterModal from "./ObjectFilterModal.vue";
import DownloadManagerModal from "./DownloadManagerModal.vue";
import LayoutApi from "@/libs/LayoutApi";
import Config from "../Config";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import { setTimeout } from "lodash/_freeGlobal";
import { EventBus } from "@/libs/event-bus";
import { cameraOptions } from "@/libs/timeline";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const layoutApi = new LayoutApi(store);
const timeZone = "Etc/GMT+6";
const objectDetectionApi = process.env.VUE_APP_MS_EVENTS_URL;

const allowedEventTypesToDownload = [
  "fr",
  "pia",
  "vc",
  "lpr",
  "mbx",
  "me",
  "crw",
  "loit",
];
import dateParser from "@/libs/date-parser";

moment.tz.setDefault(timeZone);

const ALARMS_HOURS = 8;

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    VideoPlayer,
    Headless,
    vSelect,
    VueTimepicker,
    ObjectFilterModal,
    DownloadManagerModal,
    VisTimeline,
  },
  props: {
    camera: {
      type: Object,
    },
    tabInfo: Object,
    slotWidth: {
      type: Number | String,
    },
    forceUpdateCurrentTime: {
      type: Number,
      required: false,
    },
    syncedCamerasStatus: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: "grid",
    },
    filters: {
      type: Object,
      default: null,
    },
  },

  data() {
    const tabId = this.tabInfo.id;

    return {
      now: null,
      currentTime: null,
      previousTime: null,
      alarmTypes: null,
      filterOptions: [],
      filtersSelected: [],
      minTimelineDate: null,
      maxTimelineDate: null,
      lastMinDateRequested: null,
      lastMaxDateRequested: null,
      newestRequest: null,
      lastMaxDate: null,
      isPlaying: true,
      prevStart: null,
      prevEnd: null,
      direction: null,
      currentRecording: {
        id: null,
        url: null,
        token: null,
        start: null,
        end: null,
      },
      options: {
        height: "95px",
        orientation: "bottom",
        groupHeightMode: "fixed",
        selectable: false,
        showCurrentTime: false,
        showMajorLabels: false,
        stack: false,
        stackSubgroups: false,
        zoomable: false,
        verticalScroll: false,
        zoomMin: 1000 * 60 * 15,
        zoomMax: 1000 * 60 * 60 * 3,
        // zoomMax: 1000 * 60 * 60 * 72,
        tooltip: {
          delay: 200,
          followMouse: true,
          overflowMethod: "flip",
          template: this.tooltipTemplate,
        },
      },
      currentZoom: 1,
      tmSetInterval: null,
      recordings: {},
      dateTimePickerConfig: {
        date: {
          minDate: null,
          maxDate: moment().toDate(),
          altInput: true,
          altFormat: "Y-m-d H:i:S",
          dateFormat: "Y-m-d H:i:S",
          locale: this.$i18n.locale === "en" ? null : Spanish,
          enableTime: true,
          time_24hr: true,
          minuteIncrement: 1,
          onOpen: () => this.onDatePickerStatusChange(true),
          onClose: () => this.onDatePickerStatusChange(false),
          onChange: this.onDateSelection,
        },
      },
      datePickerOpen: false,
      dateSelector: null,
      groups: null,
      items: new DataSet(),
      newestItemId: null,
      selectedEvent: null,
      recordingsInfo: null,
      loading: false,
      loadingAlarms: false,
      validRecording: false,
      firstLoad: true,
      stopSyncWithVideo: true,
      lockLoadNewRecordings: true,
      videoLoading: false,
      videoCanPlay: false,
      abortController: null,
      requestsHistory: [],
      videoSpeed: "1.0",
      filterType: "events",
      recordingsInterval: null,
      tabId,
      config: null,
      nextVideoThresholdReached: true,
      objectFilterModalConfig: {
        minDays: null,
      },
      timelineSource: "grid",
      movedWithMouseWheel: false,
      infoSlot: null,
      syncWithMostRecentVideo: false,
      isMounted: false,
      downloadManagerSource: "from_btn",
      downloadManagerJobs: [],
      forceMove: false,
      timelineClickEvent: null,
      cameraOptions: null,
      timeZone: null,
    };
  },

  async mounted() {
    this.isMounted = true;
    this.timelineSource = this.source;

    await this.setAlarmTypes();

    // Dummy items =====
    this.items.add({
      id: "mark_0000",
      content: "",
      tag: "dummny_item",
      className: "dummy-item",
      start: moment().add(5, "seconds").format("YYYY-MM-DD HH:mm:ss"),
      subgroup: "dummy_events",
      group: 2,
    });

    this.items.add({
      id: "recording_000",
      start: moment().subtract(10, "seconds").format("YYYY-MM-DD HH:mm:ss"),
      end: moment().format("YYYY-MM-DD HH:mm:ss"),
      className: "recording",
      tag: "recording",
      style: "background-color: #4E4F4E; border-color: #4E4F4E;",
      group: 3,
    });

    this.options.min = moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
    this.$refs.timeline.setGroups(this.groups);
    this.$refs.timeline.setItems(this.items);
    this.$refs.timeline.setOptions(this.options);

    await this.zoomTimeline();
  },

  beforeDestroy() {
    clearInterval(this.tmSetInterval);
    clearInterval(this.recordingsInterval);
    EventBus.off(`new_alarm:${this.camera.camera_proxy_id}`, this.fetchNewData);
  },

  watch: {
    camera(val) {
      if (val) {
        this.initTimeline();
      }
    },

    // TODO: Check if it's posible improve this feature
    currentTime: throttle(async function (value) {
      if (this.lockLoadNewRecordings) return;

      try {
        if (this.direction === "left" && this.shouldLoadPreviousVideo(value)) {
          this.fetchDataOnDemand("back", value);
        } else if (this.direction === "right" && this.shouldLoadNextVideo(value)) {
          this.fetchDataOnDemand("forward", value);
        }
      } catch (error) {
        console.log(error);
      }
    }, 800),

    forceUpdateCurrentTime: throttle(function () {
      this.$refs.video_player.setCurrentTime();
    }, 1000),

    filterType(value) {
      this.cleanLandmarks();
      this.clearItemsFromTimeline();

      if (value === "events") {
        this.fetchAndAddAlarms(this.camera.camera_proxy_id);
      }
    },
  },

  computed: {
    slotW() {
      return this.slotWidth;
    },
    height() {
      if (!this.isMounted) return 0;
      return this.$refs.video_player.$el.parentElement.offsetHeight;
    },
    groupId() {
      return this.tabInfo.id;
    },
    currentTimeFormatted() {
      if (this.currentTime) {
        return dateParser.parseDate(this.currentTime);
      }
      return "";
    },
    currentTimeTime: {
      get() {
        if (this.currentTime) {
          return this.currentTime.format("HH:mm:ss");
        }
        return "";
      },
      set(val) {},
    },
    currentTimeDate() {
      if (this.currentTime) {
        return this.currentTime.format("YYYY-MM-DD HH:mm:ss");
      }
      return "";
    },
    cameraType() {
      if (this.camera) {
        return this.camera.camera_type;
      }

      return null;
    },
    tabSlots() {
      return store.state.grid.tabs[this.tabId]
        ? store.state.grid.tabs[this.tabId].slots
        : [];
    },
    availableCameras() {
      const slots = this.tabSlots.filter((slot) =>
        [SLOT_TYPES.CAM_LIVE, SLOT_TYPES.CAM_RECORDED, SLOT_TYPES.CAM_PA].includes(
          slot.type
        )
      );

      return slots.map((s) => ({
        pos: s.pos,
        name: s.name,
        data: s.data.camera,
      }));
    },
    isFilterSelected() {
      return this.filtersSelected.length > 0;
    },
    showDatePicker() {
      return this.timelineSource !== "sidebar-right";
    },
  },
  methods: {
    async initTimeline() {
      this.resolveTabInfo();

      this.config = Config.create(this.cameraType);
      EventBus.on(`new_alarm:${this.camera.camera_proxy_id}`, this.fetchNewData);

      const cameraOpts = await this.fetchOldestRecordingDate();
      this.oldestRecordingDate = cameraOpts.oldestRecording;
      this.latestRecordingDate = cameraOpts.latestRecording;
      this.timeZone = cameraOpts.timeZone;

      const minSeconds = moment().diff(this.oldestRecordingDate, "seconds");
      this.objectFilterModalConfig.minDays = Math.floor(minSeconds / 86400);

      this.initializeDateTimePickers();
      this.setInitialDatesAndTimes();
      this.setTimelineMinMaxDates();

      await this.zoomTimeline();

      const alarmsRange = {
        start: this.config.buildDate(
          moment(this.latestRecordingDate).subtract(
            this.config.lastMinDateRequested,
            "seconds"
          ),
          "dts"
        ),
        end: this.config.buildDate(
          moment(this.latestRecordingDate).add(1, "minutes"),
          "dts"
        ),
      };
      this.fetchAndAddAlarms(this.camera.camera_proxy_id, alarmsRange);

      this.$refs.timeline.setGroups(this.groups);
      this.$refs.timeline.setItems(this.items);
      this.$refs.timeline.setOptions(this.options);

      this.loading = true;

      await this.fetchAndAddRecordings();

      if (this.cameraType === "vxg") {
        this.fetchRecoringsAndAlarms(this.lastMinDateRequested, 6);
      }

      if (this.cameraType !== "generic_device") {
        this.recordingsInterval = setInterval(
          this.fetchNewestRecordings,
          this.config.fetchNewestRecordingsTime
        );
      }

      if (this.infoSlot && this.timelineSource === "grid") {
        const dateTime = this.infoSlot.dateTime.format("YYYY-MM-DD HH:mm:ss");
        this.stopSyncWithVideo = true;
        this.setFilter(this.infoSlot.currentFilter);
        this.pauseVideo();

        await sleep(500);

        this.moveTo(
          dateTime,
          async () => {
            await sleep(1000);
            this.stopSyncWithVideo = false;
            this.playVideo();
          },
          false
        );

        this.currentTime = this.infoSlot.dateTime;
        this.updateDateSelector();
      }

      if (
        this.filters &&
        this.filters.event_filter &&
        this.filters.event_filter.source === SOURCES.SUBSYSTEM_TREE
      ) {
        this.timelineSource = SOURCES.SUBSYSTEM_TREE;
        const event = this.filters.event_filter.event;

        if (event.toLowerCase() === "all") {
          this.filtersSelected = this.alarmTypes.map(
            (evt) => `${evt.acronym.toLowerCase()}_events`
          );
          this.filtersSelected.forEach((evt) => {
            this.$nextTick(() => this.setFilter(evt));
          });
        } else {
          const eventFilter = `${event.toLowerCase()}_events`;
          this.filtersSelected = [eventFilter];
          this.setFilter(eventFilter);
        }

        // TODO: Improve this code
        // set zoom to max
        for (let i = 0; i < 10; i++) {
          await sleep(1);
          this.updateZoom();
        }
      } else if (this.filters && this.filters.event_filter) {
        const event = this.filters.event_filter.event;
        const timeStamp = this.filters.event_filter.ts;
        const eventFilter = `${event.toLowerCase()}_events`;
        this.filtersSelected = [eventFilter];
        this.setFilter(eventFilter);

        const start = moment(timeStamp).subtract(5, "minutes");
        const end = moment(timeStamp).add(5, "minutes");

        // ====================== move to helper ======================
        const dateRange = {
          start: this.config.buildDate(start.clone(), "dts", "utc"),
          end: this.config.buildDate(end.clone(), "dts", "utc"),
        };
        this.fetchAndAddRecordings(dateRange, false);
        this.emitDateSelectionEvent(
          start.format("YYYY-MM-DD HH:mm:ss"),
          end.format("YYYY-MM-DD HH:mm:ss")
        );
        // ============================================================

        setTimeout(async () => {
          this.stopSyncWithVideo = true;
          this.moveTo(
            timeStamp,
            async () => {
              this.$refs.video_player.setCurrentTime(timeStamp);
              await sleep(500);
              this.tinyMove();
            },
            false
          );

          // TODO: Improve this code
          // set zoom to max
          for (let i = 0; i < 10; i++) {
            await sleep(1);
            this.updateZoom();
          }
        }, 1000);
      }

      this.addDownloadManagerJobFromCameras();
    },

    fetchNewData(data) {
      console.log(`new_alarm:${this.camera.camera_proxy_id}`);
      // ====================== Check for future reference ======================
      const date = data.created;
      const start = moment(date).subtract(5, "minutes");
      const end = moment(date).add(5, "minutes");
      const dateRecordingsRange = {
        start: this.config.buildDate(start.clone(), "dts", "utc"),
        end: this.config.buildDate(end.clone(), "dts", "utc"),
      };
      const dateAlarmRange = {
        start: this.config.buildDate(start.clone(), "dts"),
        end: this.config.buildDate(end.clone(), "dts"),
      };

      this.fetchAlarms(dateAlarmRange, this.camera.camera_proxy_id).then((resp) => {
        this.addAlarms(resp.data.data);
      });

      this.fetchAndAddRecordings(dateRecordingsRange, false);
      this.emitDateSelectionEvent(
        start.format("YYYY-MM-DD HH:mm:ss"),
        end.format("YYYY-MM-DD HH:mm:ss")
      );
      // =======================================================================
    },

    resolveTabInfo() {
      if (this.tabInfo.slots) {
        const data = this.tabInfo.slots[0].data;
        const token = localStorage.getItem("accessToken");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-localization": store.getters["user/getPreferences"].lang,
        };
        const eventFilter = `${data.alarm_acronym.toLowerCase()}_events`;
        this.filtersSelected = [eventFilter];

        this.infoSlot = {
          id: this.tabInfo.id,
          pos: 3,
          host: data.host,
          dateTime: moment(data.created),
          buildUrl: function (alarmId) {
            return `${this.host}/v1/services/alarms/alarm-by-id?alarm_id=${alarmId}`;
          },
          headers,
          currentFilter: eventFilter,
        };
      } else {
        const tabData = layoutApi.getCurrentTabData();
        const tabId = this.tabInfo.id;
        const slots = tabData.slots;

        slots.forEach((slot) => {
          if (slot.type === SLOT_TYPES.INFO) {
            const data = layoutApi.getSlotData(slot.pos).data;
            const token = localStorage.getItem("accessToken");
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-localization": store.getters["user/getPreferences"].lang,
            };
            const eventFilter = `${data.alarm_acronym.toLowerCase()}_events`;
            this.filtersSelected = [eventFilter];

            this.infoSlot = {
              id: tabId,
              pos: slot.pos,
              host: data.host,
              dateTime: moment(data.created_format_2),
              buildUrl: function (alarmId) {
                return `${this.host}/v1/services/alarms/alarm-by-id?alarm_id=${alarmId}`;
              },
              headers,
              currentFilter: eventFilter,
            };
          }
        });
      }
    },

    fetchOldestRecordingDate() {
      return new Promise(async (resolve, _) => {
        this.cameraOptions = await cameraOptions(
          this.camera.camera_id,
          this.camera.camera_type
        );
        resolve({
          oldestRecording: this.cameraOptions.oldest_recording.start_date,
          latestRecording: this.cameraOptions.latest_recording.start_date,
          timeZone: this.cameraOptions.time_zone_data.reseller,
        });
      });
    },

    shouldLoadPreviousVideo(currentTime) {
      if (!this.currentRecording) return false;
      // if (this.currentRecording.duration < 300) return false;
      let minutes = this.cameraType !== "vxg" ? 5 : 90;

      const threshold = 1000 * 60 * minutes;
      const validRecording = this.currentRecording.start && this.currentRecording.end;

      let recordings = this.items.get({
        filter: (item) =>
          item.tag === "recording" &&
          moment(this.currentRecording.start).isBetween(
            moment(item.start),
            moment(item.end),
            null,
            "(]"
          ),
      });

      return (
        (currentTime.valueOf() - this.currentRecording.start < threshold ||
          !validRecording) &&
        recordings.length < 1
      );
    },

    // shouldLoadNextVideo(currentTime) {
    //   if (!this.currentRecording) return false;
    //   // if (this.currentRecording.duration < 300) return false;
    //   let minutes = this.cameraType !== "vxg" ? 5 : 60;

    //   const threshold = 1000 * 60 * minutes;
    //   const timeToNextVideoThreshold = 1000 * 60 * minutes;
    //   const timeToNextVideo = currentTime.valueOf() - this.currentRecording.start;
    //   const shouldLoad = timeToNextVideo > timeToNextVideoThreshold;

    //   let recordings = this.items.get({
    //     filter: (item) =>
    //       item.tag === "recording" &&
    //       moment(this.currentRecording.end).isBetween(
    //         moment(item.start),
    //         moment(item.end),
    //         null,
    //         "[)"
    //       ),
    //   });

    //   return (
    //     this.currentRecording.end - currentTime.valueOf() < threshold &&
    //     shouldLoad &&
    //     recordings.length < 1
    //   );
    // },

    shouldLoadNextVideo(currentTime) {
      if (!this.currentRecording) return false;

      const threshold = 1000 * 60 * 60;

      const timeToNextVideoThreshold = 1000 * 60 * 60;
      const timeToNextVideo = currentTime.valueOf() - this.currentRecording.start;
      const shouldLoad = timeToNextVideo > timeToNextVideoThreshold;

      return this.currentRecording.end - currentTime.valueOf() < threshold && shouldLoad;
    },

    clearItemsFromTimeline() {
      const items = this.items.get({
        filter: (item) =>
          item.tag === "object" || item.tag === "event" || item.tag === "event_mark",
      });
      this.items.remove([...items.map((itm) => itm.id)]);
    },

    setFilterOptions() {
      this.filterOptions = this.alarmTypes.map((at) => ({
        value: `${at.acronym.toLowerCase()}_events`,
        label: at.description,
      }));
    },

    setInitialDatesAndTimes() {
      this.now = moment();
      this.currentTime = this.now;
      const days = moment().diff(this.oldestRecordingDate, "days");

      this.minTimelineDate = moment()
        .subtract(days + 2, "days")
        .set({ hour: 23, minute: 59, second: 59 });
      this.maxTimelineDate = this.now.clone();

      this.lastMinDateRequested = moment(this.latestRecordingDate).subtract(
        this.config.lastMinDateRequested,
        "seconds"
      );
      this.lastMaxDateRequested = moment(this.latestRecordingDate).add(1, "minutes");
      this.newestRequest = this.lastMaxDateRequested.clone();
    },

    setTimelineMinMaxDates() {
      this.options.min = this.minTimelineDate.format("YYYY-MM-DD HH:mm:ss");
    },

    async zoomTimeline() {
      let zoomLevel = 1;
      if (this.cameraType === "vxg") zoomLevel = 6;

      for (let i = 0; i < zoomLevel; i++) {
        await sleep(1);
        this.$refs.timeline.zoomIn(1, { animation: false });
      }
      await sleep(1);
    },

    async fetchAndAddAlarms(camera_id, dateRange = null, limit = null) {
      return new Promise((resolve, _) => {
        const rangeAlarms = dateRange
          ? dateRange
          : {
              start: this.config.buildDate(this.minTimelineDate, "dts"),
              end: this.config.buildDate(this.maxTimelineDate, "dts"),
            };

        this.loadingAlarms = true;
        this.loading = true;

        this.fetchAlarms(rangeAlarms, camera_id, limit)
          .then((resp) => {
            this.addAlarms(resp.data.data);
          })
          .finally(() => {
            resolve();
            this.loadingAlarms = false;
            this.loading = false;
          });
      });
    },

    async fetchAndAddRecordings(dateRange = null, move = true) {
      return new Promise((resolve, reject) => {
        const rangeRecordings = dateRange
          ? dateRange
          : {
              start: this.config.buildDate(this.lastMinDateRequested, "dts", "utc"),
              end: this.config.buildDate(this.lastMaxDateRequested, "dts", "utc"),
            };

        this.fetchRecordings(rangeRecordings)
          .then(async (resp) => {
            if (!resp.data) return;

            // Remove dummy item
            const objects = this.items.get({
              filter: (item) => item.id === "recording_000",
            });
            this.items.remove([...objects]);

            const data = resp.data;
            const lastItem =
              this.cameraType !== "generic_device"
                ? data.data[data.data.length - 1]
                : data.data[0];
            this.currentRecording.token = data.token;
            this.addRecordings(data.data);

            if (move) {
              await sleep(300);
              await this.updateCurrentTimeAndMoveTo(lastItem);
              this.tinyMove();
            }

            this.firstLoad = false;
            resolve();
          })
          .catch((error) => {
            this.handleFetchRecordingsError(error);
            reject();
          })
          .finally(() => {
            this.lockLoadNewRecordings = false;
            this.loading = false;
          });
      });
    },

    handleFetchRecordingsError(error) {
      if (error.request.response.includes("recordings_not_found")) {
        console.log("error", "recordings_not_found");
      }
    },

    async updateCurrentTimeAndMoveTo(lastItem) {
      return new Promise((resolve, _) => {
        const subConfig = {
          amount: 5,
          units: "minutes",
        };

        if (this.cameraType === "generic_device") {
          subConfig.amount = 5;
          subConfig.units = "seconds";
        }

        this.lastMaxDate = moment(lastItem.end).subtract(
          subConfig.amount,
          subConfig.units
        );
        this.currentTime = this.lastMaxDate;

        this.moveTo(
          this.lastMaxDate,
          async () => {
            this.checkIfValidRecording();
            await sleep(1000);
            this.$refs.video_player.setCurrentTime(this.currentTime);
            resolve();
          },
          true
        );
      });
    },

    fetchNewestRecordings() {
      const dates = {
        start: this.newestRequest,
        end: moment(),
      };

      const buildDateCondition = {
        camera_manager: (date) => this.config.buildDate(date, null, "utc"),
        vxg: (date) => this.config.buildDate(date, "dts", "utc"),
        generic_device: (date) => this.config.buildDate(date, "dts", "utc"),
      };

      const rangeRecordings = {
        start: buildDateCondition[this.cameraType](dates.start),
        end: buildDateCondition[this.cameraType](dates.end),
      };

      this.fetchRecordings(rangeRecordings).then(() => {
        EventBus.emit(`timeline:${this.groupId}:update_recordings`, {
          dates: { ...rangeRecordings },
        });

        if (this.newestItemId) {
          this.items.updateOnly({
            id: this.newestItemId,
            end: dates.end.format("YYYY-MM-DD HH:mm:ss"),
          });
        }

        this.newestRequest = moment(dates.end);
        this.maxTimelineDate = moment(dates.end);
      });
    },

    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
      });
      return () => popper.destroy();
    },

    emitDateSelectionEvent(start, end) {
      this.requestsHistory.push({
        start: moment(start).valueOf(),
        end: moment(end).valueOf(),
      });

      this.$emit("last-request", { start, end });

      EventBus.emit(`timeline:${this.groupId}:date_selection`, {
        dates: { start, end },
      });
    },

    async setAlarmTypes() {
      const { data } = await this.fetchAlarmTypes();
      this.alarmTypes = data.data.sort((a, z) =>
        a.description > z.description ? 1 : z.description > a.description ? -1 : 0
      );

      const subgroups = this.alarmTypes.reduce((a, v) => {
        const acronym = v.acronym.toLowerCase();
        return { ...a, [`${acronym}_events`]: acronym === "s" };
      }, {});

      this.groups = new DataSet([
        {
          id: 1,
          content: "",
          visible: true,
          subgroupStack: false,
          subgroupVisibility: subgroups,
        },
        {
          id: 2,
          content: "",
          visible: true,
          subgroupStack: false,
          subgroupVisibility: subgroups,
        },
        {
          id: 3,
          content: "",
          subgroupStack: false,
          visible: true,
        },
      ]);

      this.$nextTick(() => {
        this.setFilterOptions();

        if (!this.infoSlot || !this.filters) {
          this.filtersSelected = [];
        }
      });
    },

    initializeDateTimePickers() {
      if (!this.showDatePicker) return;

      let tabIdParsed = this.infoSlot?.id || this.tabId;
      const dateSelector = `#date_selector_input-${tabIdParsed}`;
      this.dateTimePickerConfig.date.minDate = moment(this.oldestRecordingDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.dateTimePickerConfig.maxDate = moment.tz(this.timeZone).format();
      this.dateSelector = flatpickr(dateSelector, { ...this.dateTimePickerConfig.date });
    },

    onTimeSelectorClick() {
      this.$refs.time_selector.toggleActive();
      this.pauseVideo();
    },

    fetchRecordings(range) {
      try {
        if (this.abortController) {
          this.abortController.abort();
        }

        this.abortController = new AbortController();

        const start = this.config.buildDate(range.start, null);
        const end = this.config.buildDate(range.end, null);
        const params = {
          camera_type: this.camera.camera_type,
          start,
          end,
          source: "timeline",
        };

        return axios
          .get(`v1/services/timeline/get-recordings/${this.camera.id}`, {
            signal: this.abortController.signal,
            params,
          })
          .catch((error) => {
            if (error?.request?.response?.includes("recordings_not_found")) {
              this.requestsHistory.push({
                start: moment(range.start).valueOf(),
                end: moment(range.end).valueOf(),
              });
            }
          });
      } catch (error) {
        console.log("fetchRecordings", error);
      }
    },

    fetchAlarms(range, camera_id, limit = null) {
      return axios.get(`v1/services/timeline/get-alarms/${camera_id}`, {
        params: { ...range, limit, source: "timeline" },
      });
    },

    generateDateRanges(startDate, hours) {
      let ranges = [];
      let step = 1;
      let start = moment(startDate);

      for (let i = 0; i < hours; i++) {
        let end = start.clone();
        start = start.clone().subtract(step, "hours");
        ranges.push({
          start: start.format("YYYY-MM-DD HH:mm:ss"),
          end: end.format("YYYY-MM-DD HH:mm:ss"),
        });
      }

      return ranges;
    },

    async fetchRecoringsAndAlarms(startDate, hours) {
      const ranges = this.generateDateRanges(startDate, hours);

      for (let i = 0; i < ranges.length; i++) {
        const range = ranges[i];
        const dateRange = {
          start: this.config.buildDate(moment(range.start), "dts", "utc"),
          end: this.config.buildDate(moment(range.end), "dts", "utc"),
        };
        await this.fetchAndAddRecordings(dateRange, false);
        this.emitDateSelectionEvent(dateRange.start, dateRange.end);
      }
    },

    fetchAlarmTypes() {
      return axios.get(`v1/type_alarm`);
    },

    onMouseDown() {
      this.stopSyncWithVideo = true;
    },

    onMouseUp() {
      this.stopSyncWithVideo = false;
    },

    updateZoom(action = "zoomIn") {
      if (action === "zoomIn" && this.currentZoom === 8) return;
      if (action === "zoomOut" && this.currentZoom === 0) return;

      if (action === "zoomIn") {
        if (this.currentZoom < 8) this.currentZoom += 1;
        this.$refs.timeline.zoomIn(1, { animation: false });
      } else {
        if (this.currentZoom > 0) this.currentZoom -= 1;
        this.$refs.timeline.zoomOut(1, { animation: false });
      }

      this.setTimelineScale();

      // this.setTimelineMax();
    },

    async onMouseWheel(evt) {
      this.stopSyncWithVideo = true;
      const delta = Math.sign(evt.deltaY);
      const target = evt.target.closest(".timeline-container");
      const clientRects = target.getClientRects()[0];
      const timelineX = clientRects.x + 4 + clientRects.width / 2;

      if (evt.x < timelineX) {
        // delta < 0 -> zoom in
        if (delta < 0) {
          this.updateZoom("zoomIn");
        } else {
          this.updateZoom("zoomOut");
        }
      } else {
        this.movedWithMouseWheel = true;
        let currentTime = this.currentTime.clone();
        let step = 10;

        if (this.currentZoom <= 5) {
          step = 15;
        } else if (this.currentZoom > 5 && this.currentZoom <= 7) {
          step = 10;
        } else if (this.currentZoom > 7) {
          step = 3;
        }

        if (delta < 0) {
          currentTime.add(step, "seconds");
        } else {
          currentTime.subtract(step, "seconds");
        }

        // this.pauseVideo();
        this.moveTo(currentTime, () => {}, false);
        // this.$refs.timeline.redraw();
        this.$refs.video_player.setCurrentTime();
        this.$refs.video_player.videoSpeed(this.videoSpeed);
      }

      setTimeout(() => {
        this.stopSyncWithVideo = false;
        this.playVideo();
      }, 1000);
    },

    setTimelineMax() {
      if (this.currentZoom === 0) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(1620, "minutes") });
      } else if (this.currentZoom === 1) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(900, "minutes") });
      } else if (this.currentZoom === 2) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(420, "minutes") });
      } else if (this.currentZoom === 3) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(210, "minutes") });
      } else if (this.currentZoom === 4) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(120, "minutes") });
      } else if (this.currentZoom === 5) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(45, "minutes") });
      } else if (this.currentZoom === 6 || this.currentZoom === 7) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(15, "minutes") });
      } else if (this.currentZoom === 8) {
        this.$refs.timeline.setOptions({ max: this.now.clone().add(3, "minutes") });
      }
    },

    setTimelineScale() {
      if (this.currentZoom === 0) {
        if (this.cameraType === "vxg") {
          this.$refs.timeline.setOptions({ timeAxis: { scale: "minute", step: 15 } });
        } else {
          this.$refs.timeline.setOptions({
            timeAxis: { scale: "hour", step: 3 },
            zoomMin: 1000 * 60 * 15,
          });
        }
      } else if (this.currentZoom > 0 && this.currentZoom < 3) {
        this.$refs.timeline.setOptions({
          timeAxis: { scale: "hour", step: 1 },
          zoomMin: 1000 * 60 * 12,
        });
      } else if (this.currentZoom === 3) {
        this.$refs.timeline.setOptions({
          timeAxis: { scale: "minute", step: 15 },
          zoomMin: 1000 * 60 * 6,
        });
      } else if (this.currentZoom === 4) {
        this.$refs.timeline.setOptions({ timeAxis: { scale: "minute", step: 10 } });
      } else if (this.currentZoom > 4 && this.currentZoom < 8) {
        this.$refs.timeline.setOptions({ timeAxis: { scale: "minute", step: 5 } });
      } else if (this.currentZoom >= 8) {
        this.$refs.timeline.setOptions({ timeAxis: { scale: "minute", step: 1 } });
      }
    },

    async onTimelineClick(data) {
      this.timelineClickEvent = setTimeout(() => {
        if (data.item && data.item.includes("event")) {
          const alarmId = data.item.replace("event_", "");
          // const parent = data.event.target.closest(".alarm-item");
          // parent.classList.toggle("full-event-name");

          // Move to the event date
          const item = this.items.get(data.item);
          const dateTime = item.start;
          this.pauseVideo();
          this.moveTo(dateTime);
          this.$refs.video_player.setCurrentTime(dateTime);
          this.playVideo();

          this.updateInfoSlot(alarmId);
        }
      }, 500);
    },

    async updateInfoSlot(alarmId) {
      if (this.infoSlot) {
        try {
          const headers = this.infoSlot.headers;
          const { data } = await axiosIns.get(this.infoSlot.buildUrl(alarmId), {
            headers,
          });
          const eventFilter = `${data.alarm_acronym.toLowerCase()}_events`;
          this.filtersSelected = [eventFilter];
          layoutApi.updateSlotData(this.infoSlot.pos, SLOT_TYPES.INFO, data);
          EventBus.emit(`timeline:${this.infoSlot.id}:change_event`, data);
        } catch (error) {
          console.error("Timeline::updateInfoSlot:", error.response);
        }
      }
    },

    onTimelineDoubleClick(data) {
      clearTimeout(this.timelineClickEvent);
      if (data.item && data.item.includes("event")) {
        this.addDownloadManagerJobFromTag(data.item);
      }
    },

    onDragged(evt) {
      try {
        if (evt.byUser || this.stopSyncWithVideo) {
          this.checkIfValidRecording();
          this.$refs.timeline.redraw();
          this.$refs.video_player.setCurrentTime();
          this.playVideo();
          this.stopSyncWithVideo = false;
          this.$refs.video_player.videoSpeed(this.videoSpeed);
        }
      } catch (error) {
        console.log("=== onDragged ===");
        console.log(error);
      }
    },

    onDrag(evt) {
      try {
        this.searchObject();
        this.updateDirection(evt.start);

        if (
          evt.byUser ||
          this.stopSyncWithVideo ||
          this.movedWithMouseWheel ||
          this.forceMove
        ) {
          this.pauseVideo();
          this.checkIfValidRecording();
          this.setCurrentTime(evt.start, evt.end);
          this.updateDateSelector();
          this.movedWithMouseWheel = false;
          this.syncWithMostRecentVideo = false;
          this.forceMove = false;
        }
      } catch (error) {
        console.log("=== onDrag ===");
        console.error(error);
      }
    },

    playVideo() {
      this.$refs.video_player.play();
    },

    pauseVideo() {
      this.$refs.video_player.pause();
    },

    setCurrentTime(start, end) {
      const middleTime = moment((start.valueOf() + end.valueOf()) / 2);
      this.currentTime = middleTime;
      this.$refs.video_player.setCurrentTime();
    },

    updateDateSelector() {
      if (this.dateSelector) {
        this.dateSelector.setDate(this.currentTimeDate);
      }
    },

    updateDirection(start, end) {
      if (this.prevStart !== null && this.prevEnd !== null) {
        if (start.valueOf() > this.prevStart.valueOf()) {
          this.direction = "right";
        } else if (start.valueOf() < this.prevStart.valueOf()) {
          this.direction = "left";
        }
      }

      this.prevStart = start;
      this.prevEnd = end;
    },

    onVideoLoadStart() {
      this.videoLoading = true;
      this.videoCanPlay = false;
    },

    onVideoLoadedMetaData() {
      this.videoLoading = false;
    },

    onVideoTimeChange(time) {
      if (this.stopSyncWithVideo) return;
      try {
        if (moment(time).isValid()) {
          this.moveTimeline(time);
          this.currentTime = moment(time);
        }
      } catch (error) {
        console.log("=== onVideoTimeChange ===");
        console.log(error);
        console.log(time);
      }
    },

    onVideoCanPlay() {
      this.videoCanPlay = true;
    },

    videoStatusChange(status) {
      this.isPlaying = status === "play";
    },

    checkIfItemExists(id) {
      return this.items.get(id) !== null;
    },

    addRecordings(data) {
      const newItems = [];

      data.forEach((record) => {
        try {
          if (record.start && record.end) {
            const id = `recording_${record.id}`;

            const duration = moment(record.end).diff(record.start, "seconds");

            if (!this.checkIfItemExists(id)) {
              newItems.push({
                id,
                start: moment(record.start).format("YYYY-MM-DD HH:mm:ss"),
                end: moment(record.end).format("YYYY-MM-DD HH:mm:ss"),
                duration,
                className: "recording",
                tag: "recording",
                style: "background-color: #4E4F4E; border-color: #4E4F4E;",
                group: 3,
              });
            }
          }
        } catch (error) {
          console.log("Error in addRecordings:", error);
        }
      });

      if (newItems.length > 0) {
        this.items.add(newItems);

        if (!this.newestItemId) {
          this.newestItemId = this.items.getIds().at(-1);
        }
      }
    },

    addAlarms(data) {
      data.forEach((alarm) => {
        const itemId = `event_${alarm.id}`;
        const itemExists = this.checkIfItemExists(itemId);
        const acronym = alarm.alarm_acronym.toLowerCase();
        const alarmName = alarm.alarm_type;
        const detectionEventData = alarm.detection_event_data;
        const imageUrl =
          detectionEventData && detectionEventData.image_url
            ? detectionEventData.image_url
            : null;

        if (!itemExists) {
          const newAlarmItem = {
            id: itemId,
            content: this.buildAlarmItemTemplate(alarm),
            start: alarm.created,
            ts_formatted: moment(alarm.created).format("YYYY-MM-DD HH:mm:ss"),
            ts: moment(alarm.created).valueOf(),
            className: `alarm-item ${acronym} no-image`,
            event_name: alarmName,
            image_url: imageUrl,
            tag: "event",
            group: 1,
            subgroup: `${acronym.toLowerCase()}_events`,
          };
          this.items.add(newAlarmItem);

          const newAlarmMark = {
            id: `event_mark_${alarm.id}`,
            start: alarm.created,
            end: alarm.created,
            ts: moment(alarm.created).valueOf(),
            className: `alarm-item-mark`,
            tag: "event_mark",
            group: 2,
            style: "background-color: #D69E01; border-color: #D69E01;",
            subgroup: `${acronym.toLowerCase()}_events`,
          };
          this.items.add(newAlarmMark);
        }
      });
    },

    buildAlarmItemTemplate(data) {
      return `
                <div>
                    <p>
                      <span>${data.alarm_acronym}</span>
                      <span>${data.alarm_type}</span>
                    </p>
                </div>
            `;
    },

    tooltipTemplate(originalItemData, _) {
      if (
        [
          "fr_events",
          "pia_events",
          "vc_events",
          "lpr_events",
          "mbx_events",
          "me_events",
          "crw_events",
          "loit_events",
        ].includes(originalItemData.subgroup)
      ) {
        return `
                <div class="event-img-preview" style="z-index=4">
                  <span class="event-name">${originalItemData.event_name}</span>
                  <span class="event-time">${dateParser.parseDateTimezone(
                    originalItemData.ts_formatted
                  )}</span>
                  <img src="${originalItemData.image_url}" />
                </div>
              `;
      }
      return null;
    },

    findRecordingByCurrentTime() {
      const records = this.items.get({
        filter: (item) =>
          item.tag === "recording" &&
          moment(this.currentTime).isBetween(item.start, item.end),
      });

      if (records.length === 0) return null;

      const [record] = records;
      const recording = {
        id: record.id || null,
        start: moment(record.start).valueOf() || null,
        end: moment(record.end).valueOf() || null,
      };

      return recording;
    },

    setCurrentRecording(recording, validRecording) {
      this.validRecording = validRecording;
      this.currentRecording = recording;
    },

    checkIfValidRecording: throttle(function () {
      const records = this.items.get({
        filter: (item) =>
          item.tag === "recording" &&
          moment(this.currentTime).isBetween(item.start, item.end),
      });

      if (records.length === 0) {
        this.setCurrentRecording(
          {
            id: null,
            start: null,
            end: null,
            duration: 0,
          },
          false
        );
        return false;
      }

      const [record] = records;
      this.setCurrentRecording(
        {
          id: record.id || null,
          start: record.start ? moment(record.start).valueOf() : null,
          end: record.end ? moment(record.end).valueOf() : null,
          duration: record.duration || 0,
        },
        true
      );

      return true;
    }, 300),

    moveTimeline(time, animate = false) {
      this.$refs.timeline.moveTo(time, { animation: animate });
      if (!this.datePickerOpen) this.updateDateSelector();
    },

    moveTo(time, callback = null, animate = false) {
      try {
        if (moment(time).isValid()) {
          this.stopSyncWithVideo = true;
          this.$refs.timeline.moveTo(time, { animation: animate });
          if (callback && typeof callback === "function") callback();
        }
      } catch (error) {
        console.log("=== moveTo ===");
        console.log(error);
      }
    },

    fetchDataOnDemand: debounce(function (dir) {
      return new Promise(async (resolve, reject) => {
        try {
          this.loading = true;
          this.lockLoadNewRecordings = true;

          let minTime, maxTime, rangeRecordings;
          const recording = this.currentRecording;
          const validRecording = recording && recording.start && recording.end;

          if (dir === "back") {
            if (validRecording) {
              minTime = moment(recording.start).subtract(
                this.config.fetchDataOnDemandBackRequestTime,
                "seconds"
              );
              maxTime = moment(recording.start);
            } else {
              minTime = this.currentTime
                .clone()
                .subtract(this.config.fetchDataOnDemandBackRequestTime, "seconds");
              maxTime = this.currentTime.clone().add(5, "minutes");
            }

            if (minTime.isBefore(this.minTimelineDate)) {
              minTime = this.minTimelineDate.clone();
            }

            rangeRecordings = {
              start: this.config.buildDate(minTime, "dts", "utc"),
              end: this.config.buildDate(maxTime, "dts", "utc"),
            };
          }

          if (dir === "forward") {
            if (validRecording) {
              minTime = moment(recording.end);
              maxTime = minTime
                .clone()
                .add(this.config.fetchDataOnDemandForwardRequestTime, "minutes");
            } else {
              minTime = this.currentTime.clone().subtract(5, "minutes");
              maxTime = minTime
                .clone()
                .add(this.config.fetchDataOnDemandForwardRequestTime, "minutes");
            }

            if (maxTime.isAfter(this.maxTimelineDate) || maxTime.isAfter(moment())) {
              this.lockLoadNewRecordings = false;
              this.loading = false;
              return;
            }

            if (maxTime.isAfter(this.maxTimelineDate)) return;

            rangeRecordings = {
              start: this.config.buildDate(minTime.subtract(1, "minute"), "dts", "utc"),
              end: this.config.buildDate(maxTime.add(1, "minute"), "dts", "utc"),
            };
          }

          const alarmsRange = {
            start: this.config.buildDate(
              minTime.clone().subtract(ALARMS_HOURS, "hours"),
              "dts"
            ),
            end: this.config.buildDate(maxTime.clone(), "dts"),
          };
          this.fetchAndAddAlarms(this.camera.camera_proxy_id, alarmsRange);
          const resp = await this.fetchRecordings(rangeRecordings);

          if (resp) {
            this.emitDateSelectionEvent(
              minTime.format("YYYY-MM-DD HH:mm:ss"),
              maxTime.format("YYYY-MM-DD HH:mm:ss")
            );

            if (this.currentRecording) {
              this.currentRecording.token = resp.data.token || null;
            }

            this.addRecordings(resp.data.data);
            this.lockLoadNewRecordings = false;
            this.loading = false;

            this.tinyMove();

            this.fetchOldAlarms();

            resolve();
          } else {
            reject("response for recordings is null");
          }
        } catch (error) {
          reject(error);
        } finally {
          this.lockLoadNewRecordings = false;
          this.loading = false;
        }
      });
    }, 400),

    tinyMove() {
      if (true) {
        const config = {
          amount: 500,
          units: "milliseconds",
          timeout: 800,
        };

        if (this.cameraType === "vxg") {
          config.amount = 100;
          config.units = "milliseconds";
          config.timeout = 200;
        }

        if (this.cameraType === "generic_device") {
          config.amount = 100;
          config.units = "milliseconds";
          config.timeout = 200;
        }

        // Force play video after fetch new recordings
        setTimeout(() => {
          this.forceMove = true;
          const currentTime = this.currentTime
            .clone()
            .subtract(config.amount, config.units)
            .format("YYYY-MM-DD HH:mm:ss");
          this.moveTo(currentTime);
        }, config.timeout);
      }
    },

    onDatePickerStatusChange(isOpen) {
      this.datePickerOpen = isOpen;
    },

    onDateSelection: debounce(function (evt) {
      this.pauseVideo();

      try {
        let time = { hour: 12, minute: 0, second: 0 };
        const parsedDate = moment(evt[0]);

        if (moment(this.oldestRecordingDate).isSame(evt[0], "day")) {
          const oldestRecordingDate = moment(this.oldestRecordingDate).add(3, "seconds");
          time = {
            hour: oldestRecordingDate.hour(),
            minute: oldestRecordingDate.minute(),
            second: oldestRecordingDate.seconds(),
          };
        } else {
          time = {
            hour: parsedDate.hour(),
            minute: parsedDate.minute(),
            second: parsedDate.seconds(),
          };
        }

        const currentTime = parsedDate.set(time);
        this.moveTo(currentTime, async () => {
          this.checkIfValidRecording();
          this.currentTime = currentTime;
          this.$refs.video_player.setCurrentTime(
            currentTime.format("YYYY-MM-DD HH:mm:ss")
          );
          this.stopSyncWithVideo = false;
          this.$refs.timeline.redraw();
          // this.playVideo();
          this.tinyMove();
        });
      } catch (error) {
        if (error.message === "canceled") {
          this.loading = true;
        }
      }
    }, 500),

    onTimeSelection: debounce(async function (evt) {
      this.stopSyncWithVideo = true;
      this.pauseVideo();

      const date = this.currentTime.clone().set({
        hours: evt.data.HH,
        minutes: evt.data.mm,
        seconds: evt.data.ss,
      });

      this.moveTo(date);
      await sleep(200);

      this.$refs.video_player.setCurrentTime();
      this.playVideo();
      this.stopSyncWithVideo = false;
    }, 800),

    onFilterSelected(evt) {
      this.setFilter(evt.value);
    },

    onFilterDeselected(evt) {
      this.unsetFilter(evt.value);
    },

    setFilter(filter) {
      // fr_events
      const group = this.groups.get(1);
      group.subgroupVisibility[filter] = true;
      this.$refs.timeline.setGroups(this.groups);
    },

    unsetFilter(filter) {
      const group = this.groups.get(1);
      group.subgroupVisibility[filter] = false;
      this.$refs.timeline.setGroups(this.groups);
    },

    moveThrough(dir) {
      if (this.filterType === "events") {
        if (!this.isFilterSelected) return;
        this.moveThroughEvents(dir);
      } else {
        this.moveThroughObjects(dir);
      }
    },

    moveToEvent(event) {
      if (!event) return;
      if (this.selectedEvent === event.id) return;

      const currentTime = moment(event.start);

      this.moveTo(currentTime, async () => {
        this.checkIfValidRecording();
        this.selectedEvent = event.id;
        const alarmId = this.selectedEvent.replace("event_", "");
        this.$refs.video_player.setCurrentTime(currentTime.format("YYYY-MM-DD HH:mm:ss"));
        this.$refs.timeline.redraw();

        this.updateInfoSlot(alarmId);
        this.updateDateSelector();
      });

      if (this.cameraType === "generic_device") {
        this.tinyMove();
      }
    },

    async moveThroughEvents(dir) {
      this.pauseVideo();

      // looking for alarms
      const records = this.items.get({
        filter: (item) => {
          const isMatchingDirection =
            dir === "next"
              ? moment(item.start).isAfter(this.currentTime)
              : moment(item.start).isBefore(this.currentTime);
          const isMatchingFilters =
            item.tag === "event" &&
            item.id !== this.selectedEvent &&
            this.filtersSelected.includes(item.subgroup);
          return isMatchingFilters && isMatchingDirection;
        },
        order: (a, b) => (dir === "next" ? a.ts - b.ts : b.ts - a.ts),
      });

      if (records.length > 0) {
        this.moveToEvent(records[0]);
      } else {
        if (dir === "prev") {
          this.fetchOldAlarms();
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Timeline",
              variant: "error",
              icon: "AlertTriangleIcon",
              text: "There aren't more alarms",
              timeout: 5000,
            },
          });
        }
      }
    },

    async fetchOldAlarms() {
      if (this.loadingAlarms) return;

      const currentTime = this.currentTime;
      const startAlarms = this.config.buildDate(
        currentTime.clone().subtract(ALARMS_HOURS, "hours"),
        "dts"
      );
      const endAlarms = this.config.buildDate(currentTime.clone(), "dts");
      const alarmsRange = { start: startAlarms, end: endAlarms };

      await this.fetchAndAddAlarms(this.camera.camera_proxy_id, alarmsRange, 100);

      if (["camera_manager"].includes(this.cameraType)) {
        return;
      }

      const startRecordings = this.config.buildDate(
        currentTime.clone().subtract(3, "hours"),
        "dts",
        "utc"
      );
      const endRecordings = this.config.buildDate(
        currentTime.clone().add(10, "minutes"),
        "dts",
        "utc"
      );
      const rangeRecordings = { start: startRecordings, end: endRecordings };

      this.fetchAndAddRecordings(rangeRecordings, false).then(() => {
        this.emitDateSelectionEvent(startRecordings, endRecordings);
      });

      if (this.cameraType !== "generic_device") {
        // this.moveThroughEvents("prev");
      }
    },

    async moveThroughObjects(dir) {
      this.pauseVideo();
      this.cleanLandmarks();
      await sleep(50);

      const records = this.items.get({
        filter: (item) => {
          if (dir === "next") {
            return (
              item.tag === "object" &&
              item.id !== this.selectedEvent &&
              moment(item.start).isAfter(this.currentTime)
            );
          }
          return (
            item.tag === "object" &&
            item.id !== this.selectedEvent &&
            moment(item.start).isBefore(this.currentTime)
          );
        },
        order: (a, b) => (dir === "next" ? a.ts - b.ts : b.ts - a.ts),
      });

      if (records.length > 0) {
        const record = records[0];
        const currentTime = moment(record.start);
        this.currentTime = currentTime.clone();

        this.moveTo(currentTime, async () => {
          await sleep(50);
          this.$refs.timeline.redraw();
          this.selectedEvent = record.id;
          this.$refs.video_player.setCurrentTime();
          this.updateDateSelector();
        });

        await sleep(1200);
        this.playVideo();
      }

      if (!this.checkIfValidRecording()) {
        try {
          await this.fetchDataOnDemand(this.direction === "left" ? "back" : "forward");
          const recording = this.findRecordingByCurrentTime();
          this.setCurrentRecording(recording, true);

          if (recording) {
            await sleep(100);
            this.currentTime = moment(recording.start);

            await sleep(50);
            this.$refs.video_player.setCurrentTime();
            this.$nextTick(() => this.updateDateSelector());

            await sleep(200);
            this.playVideo();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    checkIfLastRequestExist(start, end) {
      for (let index = 0; index < this.requestsHistory.length - 1; index++) {
        if (
          this.requestsHistory[index].start === start &&
          this.requestsHistory[index].end === end
        )
          return true;
      }
      return false;
    },

    setVideoSpeed(evt) {
      const value = Number.parseFloat(evt.target.value);
      this.$refs.video_player.videoSpeed(value);
    },

    fetchObjects(queryString) {
      return axiosIns.get(`${objectDetectionApi}?${queryString}`);
    },

    addObjects(data) {
      // let uniqueObjects = [
      //   ...new Map(data.map((obj) => [obj["timestamp"], obj])).values(),
      // ];

      data.forEach((object) => {
        const id = `object_${object._id}`;
        const date = moment(object.timestamp).tz(timeZone);

        if (!this.checkIfItemExists(id)) {
          this.items.add({
            id,
            content: "",
            start: date.format("YYYY-MM-DD HH:mm:ss"),
            ts: date.valueOf(),
            camera_id: object.id_camera_proxy,
            className: "object-detection",
            data: object,
            align: "top",
            tag: "object",
            style: "background-color: transparent; border-color: transparent;",
            group: 1,
            subgroup: "object_detections",
          });
        }
      });
    },

    showObjectsFilterModal() {
      this.$root.$emit("bv::show::modal", "timeline_filters_modal");
    },

    onObjectSearch(search) {
      const objects = this.items.get({ filter: (item) => item.tag === "object" });
      this.items.remove([...objects]);

      this.fetchObjects(search).then(async ({ data }) => {
        const objects = data.data;
        this.addObjects(objects);

        const firstRecord = objects[0];

        if (firstRecord && firstRecord.timestamp) {
          const timestamp = moment(firstRecord.timestamp).tz(timeZone);
          const currentTime = timestamp.subtract(1, "second");

          this.pauseVideo();
          await sleep(200);
          await this.moveTo(
            currentTime,
            () => (this.currentTime = currentTime.clone()),
            true
          );
          await sleep(200);
          this.$refs.timeline.redraw();
          this.$refs.video_player.setCurrentTime();
          this.updateDateSelector();
          await sleep(200);
          this.playVideo();

          if (!this.checkIfValidRecording()) {
            try {
              await this.fetchDataOnDemand(
                this.direction === "left" ? "back" : "forward"
              );
              await sleep(500);
              const recording = this.findRecordingByCurrentTime();
              this.setCurrentRecording(recording, true);
              //await this.moveTo(currentTime);
              this.$refs.video_player.setCurrentTime();
              await sleep(200);
              this.playVideo();
            } catch (error) {
              console.log(error);
            }
          }
        }
      });
    },

    searchObject() {
      if (this.filterType !== "objects") return;
      const currentTime = this.currentTime.clone();
      const start = moment(currentTime).subtract(1, "seconds").valueOf();
      const end = moment(currentTime).add(1, "seconds").valueOf();
      const records = this.items.get({
        filter: (item) => {
          const itemDate = item.ts;
          return item.tag === "object" && itemDate >= start && itemDate <= end;
        },
      });

      if (records.length) {
        this.drawLandmarks(records);
      } else {
        this.cleanLandmarks();
      }
    },

    createCanvas(camera) {
      const htmlPlayer = camera.htmlPlayer.querySelector(".video-container");
      const clientRects = htmlPlayer.getClientRects()[0];
      const canvasId = `canvas_${camera.uuid}`;
      const oldCanvas = htmlPlayer.querySelector(`#${canvasId}`);

      if (oldCanvas) {
        return oldCanvas;
      }

      const canvas = document.createElement("canvas");
      canvas.id = canvasId;
      canvas.width = clientRects.width;
      canvas.height = clientRects.height - 30;
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.position = "absolute";
      canvas.style.zIndex = 100;

      htmlPlayer.appendChild(canvas);

      return canvas;
    },

    drawLandmarks(records) {
      const items = records.map(({ data }) => data);
      EventBus.emit(`timeline:${this.groupId}:draw_landmarks`, { items });
    },

    cleanLandmarks() {
      EventBus.emit(`timeline:${this.groupId}:clear_landmarks`);
    },

    syncCameras() {
      this.$emit(
        "sync-cameras",
        !this.syncedCamerasStatus ? "sync-cameras" : "unsync-cameras"
      );
    },

    goToMostRecentEvent() {
      const records = this.items.get({
        filter: (item) => {
          return item.tag === "event" && this.filtersSelected.includes(item.subgroup);
        },
        order: (a, b) => a.ts - b.ts,
      });
      const len = records.length;

      if (len > 0) {
        const lastItem = records[len - 1];
        this.moveToEvent(lastItem);
      }
    },
    showDownloadManagerModal(source = "from_btn") {
      this.downloadManagerSource = source;
      this.$root.$emit("bv::show::modal", `${this.tabId}-download_manager_modal`);
    },
    addDownloadManagerJob(job) {
      const isJobAdded = this.downloadManagerJobs.find((j) => j.name === job.name);
      if (!isJobAdded) {
        this.downloadManagerJobs.push(job);
      }
    },
    addDownloadManagerJobFromCameras() {

      if (this.availableCameras.length === 0) {
        this.addDownloadManagerJob({
          id: `camera_${this.camera.camera_id}`,
          name: this.camera.name,
          camera_id: this.camera.camera_id,
          camera_proxy_id: this.camera.camera_proxy_id,
          camera_type: this.camera.camera_type,
          type: "camera",
          start_dt: null,
        });
        return;
      }

      this.availableCameras.forEach((item) => {
        const job = {
          id: `camera_${item.data.camera_id}`,
          name: item.data.name,
          camera_id: item.data.camera_id,
          camera_proxy_id: item.data.camera_proxy_id,
          camera_type: item.data.camera_type,
          type: "camera",
          start_dt: null,
        };
        this.addDownloadManagerJob(job);
      });
    },
    async addDownloadManagerJobFromTag(item) {
      const alarmId = item.replace("event_", "");
      const _item = this.items.get(item);
      let type = _item.subgroup.replace("_events", ""); // fr, sd, etc...
      const alarm = await axios.get(
        `/v1/services/alarms/alarm-by-id?alarm_id=${alarmId}`
      );
      const alarmData = alarm.data;
      const detectionEventData = alarmData.detection_event_data;

      if (allowedEventTypesToDownload.includes(type)) {
        const resolveCamera = (camera) => {
          return (
            camera.camera_proxy_id == detectionEventData?.id_camera_proxy ||
            camera.camera_proxy_id ==
              detectionEventData?.camera_data.camera.id_camera_proxy
          );
        };

        const camera = alarmData.cameras.find(resolveCamera);

        // Remove any other job event in the jobs
        const eventJobIndex = this.downloadManagerJobs.findIndex(
          (j) => j.type === "event"
        );

        if (eventJobIndex >= 0) {
          this.downloadManagerJobs = this.downloadManagerJobs.filter(
            (job) => job.type !== "event"
          );
        }

        let name = `${camera.name} [${alarmId}] (${type} event)`.toUpperCase();

        this.addDownloadManagerJob({
          id: `event_${alarmId}`,
          name,
          camera_id: camera.camera_id,
          camera_proxy_id: camera.camera_proxy_id,
          camera_type: camera.camera_type,
          date_time: moment(alarmData.created).format("YYYY-MM-DD HH:mm:ss"),
          type: "event",
          start_dt: null,
        });
        this.showDownloadManagerModal("from_tag");
      }

      // store.dispatch("grid/setAlarmViewByAlarmId", { alarmId });
    },
  },
};
</script>

<style lang="scss" scoped>
.fit-menu {
  height: 45px;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 !important;
}

.timeline .wrapper .timeline-container .vis-itemset {
  max-height: 72px !important;
}
</style>
