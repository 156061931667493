<template>
  <div
    :class="[
      `grid-layout__item grid-item gl-w-${widget.w} gl-x-${widget.x}`,
      { 'slot-selection': slotSelection },
      { 'slot-selection-dnd': dragEnter },
      { 'show-remove-btn': showRemoveBtn && slotItem.data },
      {
        'bg-black': cameraTypes.includes(slotItem.type),
      },
      { 'ignored-slot-active': ignoredSlot },
    ]"
    :pos="widget.pos"
    :style="{ ...calcHeight() }"
    @click="onSelectSlot(widget)"
    @dragover="onDragOver"
    @drop="onDrop($event, widget)"
    @dragenter="onDragEnter($event, widget)"
    @dragleave="onDragLeave($event, widget)"
  >
    <!-- v-if="widget.pos === slotItem.pos" -->

    <div style="display: none" class="backdrop" id="backdrop"></div>
    <!-- Camera -->
    <template v-if="cameraTypes.includes(slotItem.type)">
      <cam-slot
        :parent-id="tabId"
        :data="slotItem.data"
        :type="slotItem.type"
        :tab-info="tabInfo"
        :pos="widget.pos"
        :key="`${slotItem.type}:${slotItem.data.camera.id}`"
        :source="slotItem.data.in_app_source"
        @cam-removed="onCamRemoved"
        v-if="slotItem.data.camera.camera_id && slotItem.data.camera.camera_type"
      />
    </template>

    <!-- Map -->
    <alarm-map-slot v-if="slotItem.type == SLOT_TYPES.MAP" :data="slotItem.data" />

    <!-- Gps Map -->
    <gps-map-slot
      v-if="slotItem.type == SLOT_TYPES.GPS"
      :unit_id="slotItem.data.unit_id"
      :data="slotItem.data.data.id"
    />

    <!-- Units Map -->
    <units-map-slot
      v-if="slotItem.type == SLOT_TYPES.UNITS_MAP"
      :unit_id="slotItem.data.alarm ? null : slotItem.data.unit_id"
      :alarm="slotItem.data.alarm"
      :tab-info="tabInfo"
      :data="slotItem.data.data"
    />

    <!-- Info -->
    <info-slot v-if="slotItem.type === SLOT_TYPES.INFO" :data="slotItem" />

    <!--Interphone Info -->
    <interphone-slot
      v-if="slotItem.type === SLOT_TYPES.INTERPHONE"
      :data="slotItem.data"
    />

    <!-- Planes -->
    <plane-slot
      v-if="slotItem.type === SLOT_TYPES.PLANE && slotItem.data"
      :data="slotItem"
    />

    <!-- Timeline -->
    <timeline-slot v-if="slotItem.type === SLOT_TYPES.TIMELINE" :tab-info="tabInfo" />

    <!-- Frame -->
    <page-slot v-if="slotItem.type === SLOT_TYPES.FRAME" :data="slotItem" />

    <!-- Charts SDK -->
    <charts-sdk-slot
      v-if="slotItem.type === SLOT_TYPES.CHARTS_SDK"
      :tab-info="tabInfo"
      :data="slotItem.data"
    />

    <!-- Charts SDK Data -->
    <charts-sdk-data-slot
      v-if="slotItem.type === SLOT_TYPES.CHARTS_SDK_DATA"
      :tab-info="tabInfo"
      :data="slotItem.data"
    />

    <!-- Action Plan -->
    <action-plan-slot v-if="slotItem.type === SLOT_TYPES.ACTION_PLAN" :data="slotItem" />

    <!-- FR Tracking Map -->
    <fr-tracking-map-slot
      v-if="slotItem.type === SLOT_TYPES.FR_TRACKING_MAP"
      :data="slotItem.data"
      :tab-info="tabInfo"
    />

    <!-- LPR Tracking Map -->
    <lpr-tracking-map-slot
      v-if="slotItem.type === SLOT_TYPES.LPR_TRACKING_MAP"
      :data="slotItem.data"
      :tab-info="tabInfo"
    />

    <!-- AR Table Data -->
    <a-r-table-data
      v-if="slotItem.type === SLOT_TYPES.AR_TABLE_DATA"
      :selected-object.sync="slotItem.data"
      :isLoadedChart="true"
      :key="slotItem.data.id"
    />

    <!-- Weather -->
    <weather-slot v-if="slotItem.type === SLOT_TYPES.WEATHER" />

    <!-- Face Detection -->
    <face-detection-slot v-if="slotItem.type === SLOT_TYPES.FACE_DETECTION" />

    <!-- Waze -->
    <waze-slot v-if="slotItem.type === SLOT_TYPES.WAZE" />

    <!-- Chat -->
    <chat-slot
      v-if="slotItem.type === SLOT_TYPES.CHAT"
      :chat="slotItem.data.chat"
      :key="slotItem.data.chat.id"
    />

    <!-- Detection Event-->
    <detection-event-slot
      v-if="slotItem.type === SLOT_TYPES.DETECTION_EVENT"
      :data="slotItem.data"
      :tab-info="tabInfo"
    />

    <div class="remove-btn">
      <div>
        <feather-icon
          class="icon"
          icon="XIcon"
          size="48"
          @click="removeItemFromSlot(widget.pos)"
          v-b-tooltip="'Remove'"
        />
        <feather-icon
          class="icon"
          icon="GridIcon"
          size="40"
          @click="reorderingSlot(widget)"
          v-b-tooltip="'Move'"
        />
        <feather-icon
          class="icon"
          :icon="slotItem.data.id ? 'Edit3Icon' : 'SaveIcon'"
          size="40"
          v-if="slotItem.type == SLOT_TYPES.FRAME && slotItem.data.load"
          @click="() => (slotItem.data.id ? resetSlotData() : saveSlotData())"
          v-b-tooltip="slotItem.data.id ? 'Edit' : 'Save'"
        />
        <feather-icon
          class="icon"
          icon="TrashIcon"
          size="40"
          v-if="slotItem.type == SLOT_TYPES.FRAME && slotItem.data.id"
          @click="deleteUrl(slotItem.data.id)"
          v-b-tooltip="'Delete'"
        />
        <feather-icon
          class="icon"
          :icon="!fullScreenEnabled ? 'Maximize2Icon' : 'Minimize2Icon'"
          size="40"
          @click="goFullScreen"
          v-b-tooltip="'Full Screen'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import { VBTooltip } from "bootstrap-vue";
import CamSlot from "./slots/CamSlot.vue";
import UnitsMapSlot from "./slots/UnitsMapSlot.vue";
import InfoSlot from "./slots/InfoSlot";
import PlaneSlot from "./slots/PlaneSlot";
import AlarmMapSlot from "./slots/AlarmMapSlot.vue";
import GpsMapSlot from "./slots/GpsMapSlot.vue";
import InterphoneSlot from "./slots/InterphoneSlot.vue";
import TimelineSlot from "./slots/TimelineSlot.vue";
import PageSlot from "./slots/PageSlot.vue";
import ActionPlanSlot from "./slots/ActionPlanSlot.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ChartsSdkSlot from "./slots/ChartsSdkSlot.vue";
import ChartsSdkDataSlot from "./slots/ChartsSdkDataSlot.vue";
import FrTrackingMapSlot from "./slots/FrTrackingMapSlot.vue";
import LprTrackingMapSlot from "./slots/LPRTrackingMapSlot.vue";
import ARTableData from "./slots/ARTableData.vue";
import WeatherSlot from "./slots/WeatherSlot.vue";
import { EventBus } from "@/libs/event-bus";
import FaceDetectionSlot from "./slots/FaceDetectionSlot.vue";
import WazeSlot from "./slots/WazeSlot.vue";
import ChatSlot from "./slots/ChatSlot";
import DetectionEventSlot from "./slots/DetectionEventSlot.vue";

export default {
  components: {
    CamSlot,
    UnitsMapSlot,
    InfoSlot,
    PlaneSlot,
    AlarmMapSlot,
    GpsMapSlot,
    TimelineSlot,
    InterphoneSlot,
    PageSlot,
    ChartsSdkSlot,
    ChartsSdkSlot,
    ActionPlanSlot,
    ChartsSdkDataSlot,
    FrTrackingMapSlot,
    ARTableData,
    WeatherSlot,
    FaceDetectionSlot,
    LprTrackingMapSlot,
    WazeSlot,
    ChatSlot,
    DetectionEventSlot,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    tabId: {
      type: String | Number,
    },
    index: {
      type: Number,
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
    slots: {
      type: Array,
    },
    layoutHeight: {
      type: Number,
    },
  },
  data() {
    return {
      cameraTypes: [SLOT_TYPES.CAM_LIVE, SLOT_TYPES.CAM_PA, SLOT_TYPES.CAM_RECORDED],
      SLOT_TYPES,
      isDragEnter: false,
      fullScreenEnabled: false,
    };
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.onFullScreenChange);
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.onFullScreenChange);
  },
  computed: {
    slotItem() {
      const item = this.tabSlots.find((s) => this.widget.pos === s.pos);
      return item || this.widget;
    },
    slotDataWillOpenATab() {
      return store.getters["grid/slotDataWillOpenATab"];
    },
    slotSelection() {
      if (this.slotDataWillOpenATab) {
        return false;
      }
      return store.state.grid.slotSelection;
    },
    dragEnter() {
      if (this.slotDataWillOpenATab) {
        return false;
      }
      return this.isDragEnter;
    },
    slotReordering() {
      return store.state.grid.slotReordering;
    },
    reorderingPath() {
      return store.state.grid.slotReorderingPath;
    },
    showRemoveBtn() {
      return store.state.grid.showRemoveBtn;
    },
    slotData() {
      return store.state.grid.slotData;
    },
    tabSlots() {
      return this.tabId ? store.state.grid.tabs[this.tabId].slots : [];
    },
    currentTab() {
      return store.state.grid.tabs[this.tabId];
    },
    activeTab() {
      return store.state.grid.activeTab;
    },
    layout() {
      return this.currentTab.layout;
    },
    tabInfo() {
      // TODO: improve this code to avoid the use of "slot.type2"
      const theresTimelineWidget = !!this.tabSlots.find(
        (slot) => slot.type === SLOT_TYPES.TIMELINE
      );

      const theresDetectionEventWidget = !!this.tabSlots.find(
        (slot) => slot.type2 === SLOT_TYPES.DETECTION_EVENT
      );

      return {
        id: this.tabId,
        pos: this.widget.pos,
        layout: this.layout,
        isActive: this.tabId === this.activeTab,
        type: theresTimelineWidget ? SLOT_TYPES.TIMELINE : "default",
        type2: theresDetectionEventWidget ? SLOT_TYPES.DETECTION_EVENT : "default",
      };
    },
    ignoredSlot() {
      return (
        store.getters["round_command/isRoundCommandRunning"] &&
        store.getters["round_command/getTabId"] === this.tabId &&
        this.widget.pos == store.getters["round_command/getIgnoredSlot"] &&
        store.getters["round_command/getIgnoredSlot"] !== 0
      );
    },
  },
  watch: {
    fullScreenEnabled(newValue, oldValue) {
      if (newValue) EventBus.emit("entering-full-screen", this.slotItem);
      else EventBus.emit("exiting-full-screen", this.slotItem);
    },
    slotDataWillOpenATab() {
      this.isDragEnter = false;
    }
  },
  methods: {
    onSelectSlot(widget) {
      if (this.slotDataWillOpenATab) return;

      if (this.slotReordering) {
        if (widget.id !== this.reorderingPath.from.id) {
          store.dispatch("grid/slotReorderingPath", {
            to: { id: widget.id, pos: widget.pos },
          });
          store.dispatch("grid/setReorderingStatus", false);
          store.dispatch("grid/reorderSlots");
          //this.reordering();
        }
      } else {
        if (this.slotData && this.slotData.data && this.canSlotBeAdded()) {
          if (this.slotData.pos && this.slotData.pos === widget.pos) return;

          // Takes a empty slot like a base to filled with new data
          const slot = this.findItem(this.slots, widget.id);
          let updatedSlot = null;

          slot.type = this.slotData.type;
          slot.name = this.slotData.name;
          slot.data = this.slotData.data;
          slot.type2 = this.slotData.type;
          updatedSlot = Object.assign({}, slot);

          store.dispatch("grid/resetSlotSelection");

          if (updatedSlot) {
            store.commit("grid/unsetLayoutSlot", widget.pos);
            store.dispatch("grid/addItemToLayout", updatedSlot);
            // Reset local slot
            let localSlot = this.findItem(this.slots, widget.id);
            localSlot = Object.assign(localSlot, { type: "", name: "", data: "" });
          }
        }
      }
    },
    reordering() {
      const fragment = document.createDocumentFragment();

      const from = this.reorderingPath.from;
      const fromEl = document.getElementById(from.id);
      const to = this.reorderingPath.to;
      const toEl = document.getElementById(to.id);

      fragment.appendChild(...toEl.childNodes);
      toEl.innerHTML = "";
      toEl.appendChild(...fromEl.childNodes);
      fromEl.innerHTML = "";
      fromEl.appendChild(...fragment.childNodes);
    },
    calcHeight() {
      const slotHeight = this.layoutHeight;

      return {
        top: `${this.slotItem.y * slotHeight}px`,
        height: `${this.slotItem.h * slotHeight}px`,
      };
    },
    onCamRemoved(pos) {
      this.removeItemFromSlot(pos);
    },
    removeItemFromSlot(pos) {
      store.dispatch("grid/removeItemFromLayout", pos);
    },
    reorderingSlot(widget) {
      store.dispatch("grid/setReorderingStatus", true);
      store.dispatch("grid/slotReorderingPath", {
        from: { id: widget.id, pos: widget.pos },
      });
    },
    findItem(data, id) {
      return data.find((item) => item.id == id);
    },
    getSlotIfExists(slot) {
      return this.tabSlots.find((s) => slot.id === s.id) ?? slot;
    },
    canSlotBeAdded() {
      if (this.slotData.type === this.SLOT_TYPES.TIMELINE) {
        const slot = this.tabSlots.find((s) => s.type === this.SLOT_TYPES.TIMELINE);

        if (slot) {
          this.showMessage(
            this.$t("grid.slots.main.messages.widget_not_be_added"),
            this.$t("grid.slots.main.messages.timeline_widget_exists"),
            "danger"
          );
          store.dispatch("grid/resetSlotSelection");
          return false;
        }
      }

      if (this.slotData.type === this.SLOT_TYPES.DETECTION_EVENT) {
        const slot = this.tabSlots.find(
          (s) => s.type === this.SLOT_TYPES.DETECTION_EVENT
        );

        if (slot) {
          this.showMessage(
            this.$t("grid.slots.main.messages.widget_not_be_added"),
            this.$t("grid.slots.main.messages.detection_event_widget_exists"),
            "danger"
          );
          store.dispatch("grid/resetSlotSelection");
          return false;
        }
      }

      return true;
    },
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    resetSlotData() {
      this.slotItem.data.url = null;
      this.slotItem.data.load = false;
      if (this.slotItem.data.id) this.slotItem.data.save = true;
    },
    saveSlotData() {
      this.slotItem.data.load = false;
      this.slotItem.data.save = true;
    },
    deleteUrl(id) {
      store.dispatch("pages/delete", id).then((response) => {
        store.dispatch("pages/getAll");
        this.slotItem.data.id = null;
      });
    },
    goFullScreen() {
      if (!this.fullScreenEnabled) {
        this.$el.requestFullscreen();
        this.fullScreenEnabled = true;
      } else {
        this.fullScreenEnabled = false;
        document.exitFullscreen();
      }
    },
    onFullScreenChange() {
      if (!document.fullscreenElement) this.fullScreenEnabled = false;
    },
    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e, widget) {
      e.preventDefault();
      this.onSelectSlot(widget);
      this.isDragEnter = false;
    },
    onDragEnter(e) {
      e.preventDefault();
      this.isDragEnter = true;
    },
    onDragLeave(e) {
      e.preventDefault();
      this.isDragEnter = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slot-selection {
  &:hover {
    cursor: pointer;
    border: 2px solid #ea5455;

    & .backdrop {
      display: block !important;
    }
  }
}

.slot-selection-dnd {
  border: 2px solid #ea5455;
}

.remove-btn {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.5);
  z-index: 999;

  .icon {
    color: #fff;
    cursor: pointer;
  }
}

.show-remove-btn {
  .remove-btn {
    display: flex;
  }
}

.ignored-slot-active {
  border: 2px solid #ff0000;
}
.backdrop {
  background-color: rgba(107, 107, 107, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}
</style>
