<template>
  <b-modal
    id="scripts_modal"
    ref="scripts_modal"
    title="Scripts"
    @show="onShow"
    @hidden="resetDataModal"
    hide-header
    centered
    :ok-title="$t('commands.modal.btns.continue')"
    :ok-disabled="working || loadingStack"
    :cancel-title="$t('commands.modal.btns.cancel')"
    :cancel-disabled="working || loadingStack"
    no-close-on-esc
    no-close-on-backdrop
    @ok="onContinueClick"
    v-if="selectedScript"
    scrollable
  >
    <p class="w-100 lead border-bottom pb-50">
      {{ $t("commands.modal.title") }}: {{ selectedScript.name }}
    </p>

    <p>{{ $t("commands.modal.body") }}:</p>

    <ol v-if="stack.length">
      <li v-for="(cmd, index) in stack" :key="index">
        <template v-if="cmd.type === 'cmd'">
          <feather-icon icon="PlayCircleIcon" size="18" class="mr-25" />
          <span v-if="cmd.cmd !== 'custom'">
            {{ $t(`commands.list.${cmd.cmd_name}.name`) }}
          </span>
          <span v-else>
            {{ cmd.cmd_name }}
          </span>
          <div class="cmd-data pl-2 small" v-html="parseCmdData(cmd)"></div>
        </template>
        <template v-else>
          <feather-icon icon="PauseCircleIcon" size="18" class="mr-25" />
          {{ $t(`commands.list.${cmd.cmd_name}.name`) }} ({{ cmd.data }} seg.)
        </template>
      </li>
    </ol>
    <div class="py-2 text-center" v-else>
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>

    <hr />

    <template v-if="done">
      <p v-if="status" class="text-success">
        {{ $t("commands.modal.messages.success") }}
      </p>
      <p v-else class="text-danger">
        {{ $t("commands.modal.messages.error") }}
      </p>
    </template>
  </b-modal>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import store from "@/store";
import axios from "@axios";
import commandStackHelperMixin from "@/mixins/commandStackHelperMixin";

export default {
  mixins: [commandStackHelperMixin],
  components: {
    BSpinner,
  },
  data() {
    return {
      working: false,
      done: false,
      status: false,
      errorMsg: "",
      stack: [],
      loadingStack: true,
    };
  },
  computed: {
    selectedScript() {
      return store.state.command.selectedScript;
    },
  },
  methods: {
    onShow() {
      this.getCommandStack(this.selectedScript.id).then(({ data }) => {
        this.stack = data.data;
        this.loadingStack = false;
      });
    },
    async onContinueClick(event) {
      event.preventDefault();
      this.working = true;
      axios
        .post("v1/command/run-user-cmd", { id_command: this.selectedScript.id })
        .then(({ data }) => {
          this.status = true;
          setTimeout(() => this.$refs["scripts_modal"].hide(), 3000);
        })
        .catch((error) => {
          this.status = false;
          this.errorMsg = error.response.data.message;
          this.working = false;
        })
        .finally(() => {
          this.done = true;
        });
    },
    getCommandStack(id_command) {
      return axios.get("/v1/command/command-stack", { params: { id_command } });
    },
    resetDataModal() {
      this.working = false;
      this.status = false;
      this.done = false;
      this.errorMsg = "";
      this.stack = [];
      this.loadingStack = true;
      store.dispatch("command/unsetSelectedScript");
    },
  },
};
</script>

<style></style>
