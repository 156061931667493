<template></template>

<script>
import moment from "moment";
import { EventBus } from "@/libs/event-bus";

export default {
  props: {
    camera: {
      type: Object,
    },
    timelineCurrentTime: {
      type: String | Object,
    },
    recording: {
      type: Object,
    },
    lastMaxDate: {
      type: Object,
    },
    requestsHistory: {
      type: Array,
    },
    tabInfo: Object,
  },
  data() {
    return {
      firstLoad: true,
      syncInterval: null,
    };
  },
  created() {
    EventBus.on(`timeline:${this.groupId}:cam_timeupdate`, this.camTimeupdate);
    EventBus.on(`timeline:${this.groupId}:cam_loadstart`, this.camLoadstart);
    EventBus.on(`timeline:${this.groupId}:cam_play`, this.camPlay);
    EventBus.on(`timeline:${this.groupId}:cam_pause`, this.camPause);
    EventBus.on(`timeline:${this.groupId}:video_ended`, this.videoEnded);

    // EventBus.on(`timeline:${this.groupId}:camera_loading_error`, (evt) => {
    //   const isMainCamera = evt.detail.mainCamera;
    //   const cameraId = evt.detail.cameraId;
    //   const error = evt.detail.error;
    // });

    this.syncInterval = setInterval(() => {
      EventBus.emit(`timeline:${this.groupId}:sync_interval`, {
        currentTime: this.timelineCurrentTime.valueOf(),
      });
    }, 3000);
  },
  beforeDestroy() {
    EventBus.off(`timeline:${this.groupId}:cam_timeupdate`, this.camTimeupdate);
    EventBus.off(`timeline:${this.groupId}:cam_loadstart`, this.camLoadstart);
    EventBus.off(`timeline:${this.groupId}:cam_play`, this.camPlay);
    EventBus.off(`timeline:${this.groupId}:cam_pause`, this.camPause);
    EventBus.off(`timeline:${this.groupId}:video_ended`, this.videoEnded);

    clearInterval(this.syncInterval);
  },
  computed: {
    groupId() {
      return this.tabInfo.id;
    },
  },
  methods: {
    play() {
      EventBus.emit(`timeline:${this.groupId}:play`);
    },
    pause() {
      EventBus.emit(`timeline:${this.groupId}:pause`);
    },
    moveXSeconds(seconds, direction) {
      EventBus.emit(`timeline:${this.groupId}:move_x_seconds`, {
        seconds,
        direction,
      });
    },
    videoSpeed(times) {
      EventBus.emit(`timeline:${this.groupId}:speed`, { times });
    },
    setCurrentTime(time = null) {
      if (this.recording && this.recording.start && this.recording.end) {
        try {
          const currentTime = time
            ? moment(time).valueOf()
            : this.timelineCurrentTime.valueOf();

          EventBus.emit(`timeline:${this.groupId}:set_current_time`, { currentTime });
        } catch (error) {
          console.log("h_sct");
          console.log(error);
        }
      }
    },
    camTimeupdate(data) {
      if (this.camera && data.cameraId === this.camera.id) {
        this.$emit("timeupdate", data.elapsedTime);
      }
    },
    camLoadstart() {
      if (this.firstLoad) {
        try {
          EventBus.emit(`timeline:${this.groupId}:set_current_time`, {
            currentTime: this.lastMaxDate.clone().subtract(1, "minute").valueOf(),
          });
        } catch (error) {}

        this.firstLoad = false;
      }
    },
    camPlay() {
      this.$emit("video-status-change", "play");
    },
    camPause() {
      this.$emit("video-status-change", "pause");
    },
    videoEnded(data) {
      EventBus.emit(`timeline:${this.groupId}:timeline_video_ended`, {
        cameraId: data.cameraId,
      });
    },
  },
};
</script>
