<template>
  <div class="grid-layout__container">
    <preview-slot
      v-for="(widget, index) in slots"
      :key="index"
      :index="index"
      :widget="widget"
      :slots="slots"
      :style="{ ...gridItemStyles(widget) }"
    />
  </div>
</template>

<script>
import { onMounted, reactive, computed, toRefs } from "@vue/composition-api";
import store from "@/store";
import defaultLayout from "@/config/defaultLayout";
import useLayout from "@/composables/layouts";
import PreviewSlot from "./PreviewSlot";

export default {
  components: { PreviewSlot },
  setup() {
    const state = reactive({
      slots: [],
    });
    const currentTab = computed(() => store.getters["grid/currentTab"]);
    const layout = computed(() => {
      if (currentTab.value.layout !== "default") {
        const userLayouts = store.getters["layouts/getUserLayouts"];
        return userLayouts.find((l) => l.id === currentTab.value.layout) || {};
      }
      return defaultLayout;
    });

    const { gridItemStyles } = useLayout(currentTab.value.layout, 21);
    const grid = JSON.parse(JSON.stringify(layout.value.grid));

    onMounted(() => (state.slots = grid));

    return {
      gridItemStyles,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-layout__container {
  min-height: 175px;
}
</style>
