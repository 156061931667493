<template>
<div class="charts-sdk-container">
    <charts-widget :data="data" :tab-info="tabInfo"/>
</div>
</template>

<script>
import ChartsWidget from "../../widgets/ChartsWidget.vue";

export default {
  components: { ChartsWidget },
  props: {
    data: Object,
    tabInfo: Object,
  },
};
</script>

<style lang="scss" scoped>
.charts-sdk-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}
</style>
