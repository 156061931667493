<template></template>

<script>
import { watch } from "@vue/composition-api";
import { useMagicKeys } from "@vueuse/core";
import store from "@/store";

export default {
  setup() {
    // https://vueuse.org/core/useMagicKeys/

    const { alt, escape } = useMagicKeys();
    watch(alt, (pressed) => store.commit("grid/setShowRemoveBtn", pressed));
    watch(escape, () => store.dispatch("grid/resetSlotSelection"));

    document.onkeydown = function (evt) {
      // Keycode of alt is 18
      if (evt.keyCode == 18) {
        evt.preventDefault();
      }
    };
  },
};
</script>

<style></style>
